.p-l-24 {
    padding-left: 24px;
}

.p-r-24 {
    padding-right: 24px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-r-20 {
    padding-right: 20px;
}

.p-t-24 {
    padding-top: 24px;
}

.p-b-24 {
    padding-bottom: 24px;
}

.p-t-20 {
    padding-top: 20px;
}

.p-b-20 {
    padding-bottom: 20px;
}

.p-3-24 {
    padding: 24px;
}

.p-3-20 {
    padding: 20px;
}

.p-3-f {
    padding: 24px;
}

.c-h-p-20 {
    padding: 20px;
}

.p-l-20 {
    padding-left: 20px;
}


.m-b-12 {
    margin-bottom: 12px;
}

.m-b-24 {
    margin-bottom: 24px;
}

.m-t-24 {
    margin-top: 24px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-t-20 {
    margin-top: 20px;
}

.card-header-f {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
    line-height: 24px;
    color: #23252d;
}

.text-color-1 {
    color: #23252d;
}

.text-color-2 {
    color: #515561;
}

.text-color-3 {
    color: #6f7380;
}

.l-24 {
    line-height: 24px;
}

.key-switch {
    position: absolute;
    right: 24px;
    line-height: 18px;
    line-height: 12px;
    background: #F3F4F6;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    padding: 4px 8px;
    color: #1F2A37;
    font-size: 12px;
    font-weight: 500;
}

.form-mb-24 {
    margin-bottom: 18px;
}

.l-mb {
    margin-bottom: 5px;
}

.m-b-24 {
    margin-bottom: 20px;
}

.mb-16 {
    margin-bottom: 16px;
}

.h-40px-33 {
    height: 33px;
}

.p-3-10 {
    padding: 10px;
}

.p-12 {
    padding: 12px;
}

.m-r-32 {
    margin-right: 32px;
}

.m-b-32 {
    margin-bottom: 32px;
}

.m-t-32 {
    margin-top: 32px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-12 {
    margin-top: 12px;
}

.items-new-table.has-tfoot tfoot tr td {
    border-bottom: 0;
    border-right-color: transparent;
    border-left: 0 !important;
}

.right-modal-content-footer-dlg .p-dialog-header .pi-window-maximize:before {
    font-family: uicons-bold-straight !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f51D";
}

.right-modal-content-footer-dlg .p-dialog-header .pi-window-minimize:before {
    font-family: uicons-regular-straight !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f436";
}


.right-modal-content-footer-dlg.p-dialog-maximized {
    height: auto !important
}

.right-modal-content-footer-dlg.p-dialog-maximized .p-dialog-content {
    height: calc(100vh - 260px) !important;
}

.right-modal-content-footer-dlg.p-dialog-maximized .p-dialog-header {
    border-top-left-radius: 16px !important;
}

.right-modal-content-footer-dlg.p-dialog-maximized {
    margin-left: 24px !important;
}

.right-modal-content-footer-dlg.p-dialog-maximized .p-dialog-footer {
    border-bottom-left-radius: 16px !important;
}


.right-modal-content-footer-dlg.p-dialog-maximized {
    margin-top: -50px !important;
}

.p-dialog-maximized .p-dialog-content .vertical-btns-scroll {
    width: inherit;
}



.animation-box {

    transition: margin 0.15s ease, transform 1.5s ease;
}

.left {
    margin-left: 5px;
    transform: translateX(0px);
}

.right {
    margin-right: 5px;
    transform: translateX(0px);

}

.first-td-grey tbody tr td:first-child {
    background: #f7f8fa;
}

.bg-red-cells td {
    background: #FFE3E3 !important;
}

.alert-on-top-content {
    position: absolute;
    top: 50px;
    z-index: 2;
    width: auto;
}

.alert-on-top-content .alert {
    margin-right: 30px;
}

/* .rel-content .p-dialog-content {
    position: relative;
} */

.preview-color-picker .p-colorpicker-preview {
    width: 4px;
    height: 4px;
    padding: 7px !important;
}

.preview-color-picker.editor-row .p-colorpicker-preview {
    width: 4px;
    height: 4px;
    padding: 7px !important;
    border-radius: 3px;
}

.confirm-dlg-ml-0 .p-confirm-dialog-message {
    margin-left: 0 !important;

    padding: 8px !important;
    border-radius: 3px;

}

.mr-16 {
    margin-right: 16px;
}

.mr-12 {
    margin-right: 12px;
}

.pl-16 {
    padding-left: 16px;
}

.pr-16 {
    padding-right: 16px;
}

.ps-32 {
    padding-left: 32px;
}

.mt-34 {
    margin-top: 34px;
}

.mt-54 {
    margin-top: 54px;
}


.mt-82 {
    margin-top: 82px;
}

.ml-16 {
    margin-left: 16px;
}

.ml-12 {
    margin-left: 12px;
}

.ps-32-imp {
    padding-left: 32px !important;
}

.p-8-16 {
    padding: 8px 16px
}

.m-t-56 {
    margin-top: 56px;
}

.m-t-52 {
    margin-top: 52px;
}

.m-t-40 {
    margin-top: 40px;
}

.mr-8 {
    margin-right: 8px;
}

.pl-40 {
    padding-left: 40px;
}

.normal-table-row-active td {
    background: #ebf0ff !important;
    background-color: #ebf0ff !important;
}

.normal-table-row-active:focus,
.normal-table-row-active:focus-visible {
    background-color: #EBF0FF !important;
    outline-style: solid;
    outline-width: 3px;
    outline-color: #3060f08e;
    outline-offset: -2px;
    border-radius: 3px;

}

.normal-hover-effect tbody tr:hover td {
    background: #ebf0ff !important;
    background-color: #ebf0ff !important;
}

.p-8-4 {
    padding: 4px 8px;

}

.year-active {
    background: #EBF0FF;
    color: #305FF0;
    border-radius: 8px
}

.l-h-20 {
    line-height: 20px;
}

.pb-5-rem {
    margin-bottom: 5rem !important;
}

.dashed-card-focus:focus {
    border-color: transparent;
}

.page-layout-btn {
    position: absolute;
    top: -50px;
    right: 26px
}

.form-26-row-active {
    background: #ebf0ff !important;
    background-color: #ebf0ff !important;
}

.switch-txn-dlg .p-dialog-header .p-dialog-header-icon {
    right: 20px;
}

.switch-txn-dlg .series-cards-blue .col-6:first-child {
    border-right: 1px solid #d3d4d6 !important;
}

.pl-10-left {
    padding-left: 10px;
}

.gross-profit-imp {
    color: #0e9f6e !important;
}

.ag-aria-description-container {
    display: none;
}

.filing-3b-row-active tr td {
    background-color: transparent;
}

.dt-inp-opc input.form-control:read-only {
    opacity: 1 !important;
}

.print-float-btn-filter {
    position: absolute;
    top: 22px;
    right: 85px;
}

.header-dlg-dp-btns button:hover {
    background: #EBF0FF;
}
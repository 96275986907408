@import url(style-figma.css);
@import "@flaticon/flaticon-uicons/css/all/all";
/* @import url('https://fonts.cdnfonts.com/css/avenir'); */


@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/Avenir/Avenir\ Regular/Avenir Regular.ttf') format('truetype');

}

body {
  /* font-family: 'Noto Sans', sans-serif; */
  font-family: 'Avenir', sans-serif;
  color: #23252D;
  margin: 0;
  padding: 0;
  background: #f6f8fc;
  overflow: hidden;
}

.tiptap-color-dialog,
.tiptap-link-dialog {
  z-index: 1050;
  /* or a value high enough to appear above the current modal */
}

.content-inside-scroll {
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 5rem;
}

a {
  text-decoration: none;
}

.btn-link {
  text-decoration: none;
}

.p-inputtext {
  font-family: 'Avenir', sans-serif;
}

.p-component {
  font-family: 'Avenir', sans-serif;
}

.fav-logo {
  display: none;
}

.sidebar-minimized .fav-logo {
  display: block;
}

.gradient-login-color {
  background-color: #e8e8e8;
  background-image: linear-gradient(90deg,
      #305FF0 43%,
      #305FF0 43%,
      #e8e8e8 0,
      #e8e8e8 0);
}

.gradient-module {
  background-color: #e8e8e8;
  background-image: linear-gradient(90deg,
      #eaf1fb 43%,
      #eaf1fb 43%,
      #eaf1fb 0,
      #eaf1fb 0);
  position: absolute;
  width: 100%;
  height: 100%;
}

.c-app {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.no-card-bg {
  background: transparent;
}

.no-card-bg-cl {
  background-color: transparent;
}

.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

.f-600 {
  font-weight: 600;
}

.f-700 {
  font-weight: 700;
}

.f-800 {
  font-weight: 700;
}

.f-900 {
  font-weight: 700;
}

.font-size-12 {
  font-size: 12px;
}

.f-12-imp {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.b-r-20 {
  border-radius: 20px;
}

.b-r-15 {
  border-radius: 15px;
}

.b-r-10 {
  border-radius: 10px;
}

.b-r-3 {
  border-radius: 3px;
}

.form-control {
  font-size: 14px;
  border: 1px solid #D3D4D6;
  color: #23252D;
  background: #fff;
  border-radius: 8px;
  padding: 5px 10px;
  line-height: 20px;
}

.h-40px {
  height: 36px;
}

.h-45 {
  height: 45px;
}

.btn-primary {
  background-color: #305FF0;
  border-color: #305FF0;
}

.btn-danger {
  background-color: #F05252;
  border-color: #F05252;
}

@media (hover: hover),
not all {
  .btn-primary:hover {
    background-color: #305FF0;
    border-color: #305FF0;
  }
}

.social-borders {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  width: 32px;
  height: 32px;
}

.social-borders img {
  padding: 5px;
}

.login-bottom-image {
  position: absolute;
  bottom: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-9 {
  z-index: 9999;
}

.p-rel {
  position: relative;
}

.color-7 {
  color: #777;
}

.inside-icon {
  position: absolute;
  top: 10px;
  right: 15px;
}

#sidebar-nav {
  width: 160px;
}

.navbar-bg {
  background: #356c6c;
}

.finsights-nav .navbar-nav li a {
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.finsights-nav .navbar-nav li a i {
  font-size: 24px;
}

.breadcrumb-bg-semi {
  background: #305FF0;
  height: 20vh;
  display: none;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #fff;
}

/* .overlap-card-margin {
  margin-top: -5%;
} */
.vertical-stepper .nav-link {
  height: 10rem;
  max-height: 10rem;
  min-height: 10rem;
  position: relative;
  display: flex;
}

.vertical-stepper .nav-link.active,
.vertical-stepper .show>.nav-link {
  background-color: transparent;
}

.vertical-stepper .nav-link i {
  width: 40px;
  font-size: 26px;
  padding: 7px;
  line-height: 1;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.vertical-stepper .nav-link.active i {
  background: #305FF0;
}

.vertical-stepper .nav-link.active i:before {
  color: #fff;
}

.vertical-stepper .nav-link::before {
  color: #007bff;
}

.vertical-stepper .nav-link::before {
  content: '';
  display: block;
  position: absolute;
  top: 78%;
  left: -40px;
  width: calc(5rem + 6rem - 1.5rem);
  height: 1px;
  transform: rotate(90deg);
  background: #305FF0;
}

.vertical-stepper .nav-link:last-child:before {
  display: none;
}

/* .og-color {
  color: #305FF0;
} */
/* .finsight-toggle .p-inputswitch .p-inputswitch-slider {

  box-shadow: none;
} */

.finsight-toggle .p-inputswitch {
  width: 50px;
  height: 25px;
}

.finsight-toggle .p-inputswitch .p-inputswitch-slider:before {
  width: 18px;
  height: 18px;
  left: 3px;
  margin-top: -9px;
}

.finsight-toggle .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #7798ff;
}

.finsight-toggle .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.525rem);
}

.finsight-toggle .p-inputswitch .p-inputswitch-slider:before {
  background: #fff;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: #305FF0;
  border-color: #305FF0;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #305FF0;
  border-color: #305FF0;
}

.margin-left-6p {
  margin-left: 9%;
}

.search-bar .input-group-text {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
  border-right: 0;
  background: transparent;
  padding-right: 0;
}

.search-bar .form-control {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
  /* border-left: 0; */
}

.password-input-with-eye {
  font-size: 14px;
  line-height: 20px;
  border: none;
  outline: none;
  width: 100%;
  opacity: 0.5;
}

.password-input-with-closed-eye {
  font-size: 14px;
  line-height: 20px;
  border: none;
  outline: none;
  width: 100%;
}

.eye-btn {
  border: none;
  outline: none;
  font-size: 15px;
  background: transparent;

}

.custom-search-botton {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border-radius: 3px !important;
  border: 0;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 10px;
  font-size: 14px;
  width: 20%;
  /* border-radius: 100px; */
  z-index: 99;
}

.custom-search-botton-md {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 10px;
  font-size: 14px;
  width: 30%;
  border-radius: 100px;
  z-index: 99999;
}

.b-r-t-25 {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}

.select-inp .p-dropdown {
  width: 100%;
  /* border-radius: 50rem; */
  border: 1px solid #ced4da;
  background: #F7F8FA;
  color: #444746;
  height: 32px;
  border-radius: 0.1525rem;
}

.select-inp .p-dropdown .p-inputtext {
  font-size: 14px;
  line-height: 0.5;
  color: #444746;
}

.select-inp .p-dropdown .p-dropdown-trigger {
  color: #444746;
  width: 32px;
  height: 32px;
}

.select-inp .p-dropdown .p-dropdown-clear-icon {
  color: #444746;
  right: 3rem;
  font-size: 14px;
  line-height: 1.25;
}

.select-inp .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  font-size: 14px;
}

.select-inp-header .p-dropdown {
  width: 100%;
  border-radius: 50px;
  border: 1px solid #ced4da;
  color: #444746;
  height: 45px;
}

.select-inp-header .p-dropdown .p-inputtext {
  font-size: 14px;
  line-height: 1.25;
  color: #444746;
}

.select-inp-header .p-dropdown .p-dropdown-trigger {
  color: #444746;
  width: 10px;
  height: 22px;
}

.select-inp-header .p-dropdown .p-dropdown-clear-icon {
  color: #444746;
  right: 3rem;
  font-size: 14px;
  line-height: 1.25;
}

.select-inp-header .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  font-size: 14px;
}

.b-r-15 {
  border-radius: 15px;
}

.b-r-6 {
  border-radius: 6px;
}

.white-plus::before {
  color: #fff !important;
}

.img-width {
  width: 145px;
  max-height: initial;
  border-radius: 5px;
  border: 1px solid #eee;
  background: #d9d9d9;
  border-radius: 10px;
}

.camera-float {
  width: 20px;
  height: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 50rem;
  font-size: 24px;
  background: #fff;
  padding: 5px;
}

.profile-camera {
  position: absolute;
  bottom: -10px;
  right: -10px;
}

.tabs-under-label {
  position: absolute;
  top: 35%;
  color: #777;
  font-size: 11px;
  width: 100%;
  left: 0%;
}

.gst-c-shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.gst-background-card {
  background: #eeeded;
}

.gst-primary-status {
  background: #305FF0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
}

.card-columns {
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 1.25rem;
  column-gap: 1.25rem;
  orphans: 1;
  widows: 1;
}

.card-columns .card {
  display: inline-block;
  width: 100%;
  position: inherit;
  margin-bottom: 0;
  padding: 0;
}

.vertical-elipsis-btn {
  position: absolute;
  right: 0;
  top: 0;
  background: #d9d9d9;
  border-color: #d9d9d9;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-elipsis-btn {
  position: absolute;
  right: 0;
  top: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.plus-btn {
  background: gba(57, 191, 181, 0.2);
}

.add-gstin-bg {
  background: rgba(235, 237, 239, 0.3);
  border: 1px solid #d1d1d6;
}

.b-t-b-r {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-elipsis .dropdown-toggle::after {
  display: none;
}

.bg-edit {
  background: #356C6C;
  border: 1px solid #05a0a0;
}

.color-4 {
  color: #444444;
}

.color-9 {
  color: #929292;
}

.overflow-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

}

.items-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;

}

.items-elipsis-250 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 240px;

}

.header-dp-select .p-dropdown {
  border: 1px solid #dadce0;
  background: #fff;
  padding: 6px 12px;
  width: 250px;
  max-width: 250px;
  height: 36px;
  border: 1px solid #D3D4D6;
  border-radius: 8px;
}

.header-dp-select .p-dropdown .p-inputtext {
  color: #444746;
  padding: inherit;
  padding-top: 2px;
  padding-left: 0px;
}

.header-dp-select .p-dropdown:not(.p-disabled):hover {
  border: 1px solid #dadce0;
}

.header-dp-select .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  color: #444746;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 10px;
  transition: all 200ms;
  opacity: 0.5;
}

.form-control:focus+.form-control-placeholder,
.form-control:valid+.form-control-placeholder {
  font-size: 75%;
  transform: translate3d(10px, -50%, 0);
  background: #fff;
  opacity: 1;
}

.more-details-toggle-btn:focus {
  font-weight: bolder;
  text-decoration: underline;
}

.p-float-label input:focus~label,
.p-float-label input:-webkit-autofill~label,
.p-float-label input.p-filled~label,
.p-float-label textarea:focus~label,
.p-float-label textarea.p-filled~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label {
  top: 0.15rem;
  background: #fff;
  font-size: 12px;
}

/* .gap-3 {
  gap: 30px !important
} */

.p-input-icon-left>i,
.p-input-icon-right>i,
.p-input-icon-left>svg,
.p-input-icon-right>svg,
.p-input-icon-left>.p-input-prefix,
.p-input-icon-right>.p-input-suffix {
  z-index: 1;
}

.c-pointer {
  cursor: pointer;
}

.og-background {
  background: #305FF0;
}

.company-icon-before:before {
  color: #fff;
  font-size: 40px;
}

.company-icon-white-before:before {
  color: #777777;
  font-size: 40px;
}

.text-warning {
  color: #F3893D !important;
}

.btn-outline-warning {
  border-color: #F3893D;
  color: #F3893D;
}

.btn-outline-primary {
  border-color: #305FF0;
  color: #305FF0;
}

.btn-outline-primary:hover {
  background: #EBF0FF !important;
  border-color: #305FF0 !important;
  color: #305FF0 !important;
}

.btn-outline-secondary:hover {
  background: #EBF0FF !important;
  border-color: #d3d4d6 !important;
  color: #23252D !important;
}

.btn-outline-secondary:active,
.btn-outline-secondary:focus-visible,
.btn-outline-secondary:focus {
  background: #EBF0FF !important;
  border-color: transparent !important;
  color: #305FF0 !important;
  box-shadow: 0 0 0 2px #3060f08e !important;

}



.btn-outline-primary:active,
.btn-outline-primary:focus-visible,
.btn-outline-primary:focus {
  background: #EBF0FF !important;
  border-color: transparent !important;
  color: #305FF0 !important;
  box-shadow: 0 0 0 2px #3060f08e !important;

}

.btn-outline-primary:active,
.btn-outline-primary:focus-visible {
  background: #EBF0FF !important;
  border-color: transparent !important;
  color: #305FF0 !important;
}

.close-white:before {
  color: #fff;
}

.btn-primary:hover {
  background: #305FF0;
  border-color: #305FF0;
}

.icon-rotate-h {
  transform: rotate(270deg);
}

.radius-btns {
  border: 1px solid #D9D9D9;
  border-radius: 8px 0px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}

.height-38px {
  height: 38px;
}

.f-42px {
  font-size: 42px;
}

.tree-semi-border-td-th .inside-table-data .p-datatable.inside-table-th-td thead th {
  border: 1px solid transparent !important;
  background: #f3f3f3 !important;

}

.tree-semi-border-td-th .p-datatable-thead tr th {

  background: #fff;

}

/* .p-datatable .p-datatable-tbody > tr:nth-child(2n+1)
{
  background: #fbfbfb;
} */



.tree-semi-border-td-th.inside-payables .p-datatable-thead tr th {
  font-size: 10px !important;
  text-transform: inherit;
}

.tree-semi-border-td-th .p-datatable-thead {

  border-top: 0px solid #f0f2f5 !important;
}

.tree-semi-border-td-th .p-datatable-tbody tr td {

  border-bottom: 1px solid #f0f2f5 !important;
  border-top: 0 !important;
}

.tree-semi-border-td-th .inside-table-data th {
  border: 0 !important;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: #f1f1f1;
  box-shadow: none;
  color: #444;
  box-shadow: none;
  outline: none;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: #f1f1f1;
  box-shadow: none;
}

.p-datatable .p-sortable-column:focus {
  outline: none;
}

.tree-semi-border-td-th.inside-payables span.pi-chevron-down:before {
  font-family: 'Font Awesome 5 Free';
  content: "\f141";
  padding-right: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #5FABCA;
}

.tree-semi-border-td-th.inside-payables span.pi-chevron-right:before {
  font-family: 'Font Awesome 5 Free';
  content: "\f141";
  padding-right: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #aaa;
}

.preview-dlg .p-dialog-header {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.preview-dlg .p-dialog-footer {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.p-dialog.preview-dlg {
  height: 100%;
  max-height: 100%;
  margin: 0;
}

.p-slider .p-slider-range {
  background: #305FF0;
}

.p-slider .p-slider-handle {
  border: 2px solid #305FF0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  font-weight: 600;
  background: transparent;
  color: #23252d;
}

.p-tabview .p-tabview-panels {
  padding-left: 0;
  padding-right: 0;
}

.sel-text {
  right: 3%;
  color: #777;
}

.text-dec-none {
  text-decoration: none;
}

.dropdown-table thead {
  display: none !important;
}

.dropdown-table tbody tr td {
  padding: 5px !important;
  overflow-x: hidden;
  cursor: pointer;
}

.p-paginator {
  background: transparent;
  border: 0 !important;
  margin-top: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
}

.p-paginator .p-paginator-current {
  font-size: 12px;
  font-weight: 400;
  color: #777777;
  min-width: 2rem;
  height: 2rem;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  min-width: 2rem;
  height: 2rem;
}

.p-paginator .p-dropdown {
  border: 1px solid #f3f3f3;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #e8eaeb !important;
  border-color: #ffffff !important;
  color: #305FF0;
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  font-size: 12px;
  font-weight: 500;
  color: #555;
  border-radius: 50px;
}

.p-paginator .p-paginator-current {
  margin-left: auto;
}

.p-paginator .p-dropdown {
  height: 1.75rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
  font-size: 12px;
  font-weight: 500;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}

.p-paginator .p-inputtext {
  padding: 0.219rem 0.429rem;
  color: #555;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.p-dropdown.p-component.p-inputwrapper .p-dropdown-trigger-icon.pi.pi-chevron-down.p-clickable {
  font-size: 12px;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: #C8C9CB;
}

.c-pointer {
  cursor: pointer;
}

.p-dialog .p-dialog-header {
  padding-left: 24px;
  padding-top: 20px;
  padding-right: 24px;
  padding-bottom: 20px;

}

.p-dialog .p-dialog-footer {
  padding-left: 24px;
  padding-top: 20px !important;
  padding-right: 24px;
  padding-bottom: 20px;
}

.search-table-records-icons .p-dialog-header {
  position: absolute;
  right: 0%;
  z-index: 999;
  padding: 5px;
}

.search-table-records-icons .p-dialog-content {
  padding-top: 2rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.border-d-right {
  border-right: 1px solid #d7d7d7;
}

.text-primary-color {
  color: #305FF0 !important;
}

.text-primary {
  color: #305FF0 !important;
}

.dialog-col-spe:after {
  content: '';
  width: 0;
  height: 82%;
  position: absolute;
  border-right: 1px solid #d7d7d7;
  top: 13%;
  left: 34%;
}

#wrapper-nav {
  overflow-x: hidden;
}

#sidebar-wrapper-nav {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper-nav .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper-nav .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

.appended-class.sb-sidenav-toggled #wrapper-nav #sidebar-wrapper-nav {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper-nav {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  .appended-class.sb-sidenav-toggled #wrapper-nav #sidebar-wrapper-nav {
    margin-left: -15rem;
  }
}

.navigation-menu .list-group-item {
  border-bottom: 0;
  font-size: 14px;
}


/*--------------*/



.fixed-top,
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav,
.sb-nav-fixed .sb-topnav {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: inherit;


}

.overall-body {
  border-top: 1px solid #e8eaed;
}

.side-bar {
  border-top: 1px solid #e8eaed;
  ;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#layoutAuthentication {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#layoutAuthentication #layoutAuthentication_content {
  min-width: 0;
  flex-grow: 1;
}

#layoutAuthentication #layoutAuthentication_footer {
  min-width: 0;
}

#layoutSidenav {
  display: flex;
}

#layoutSidenav #layoutSidenav_nav {
  flex-basis: 225px;
  flex-shrink: 0;
  transition: transform 0.15s ease-in-out;
  z-index: 1038;
  transform: translateX(-225px);
}

#layoutSidenav #layoutSidenav_content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  flex-grow: 1;
  /* min-height: calc(100vh - 0px); */
  /* margin-left: -6rem; */
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  transform: translateX(0);
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1037;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

@media (min-width: 992px) {
  #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
  }

  #layoutSidenav #layoutSidenav_content {
    margin-left: 0;
    transition: margin 0.15s ease-in-out;
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX(-225px);
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
    margin-left: -6rem;
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    display: none;
  }
}

.sb-nav-fixed .sb-topnav {
  z-index: inherit;
  box-shadow: 0px 15px 10px -15px rgb(0 0 0 / 9%);
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
  /* width: 225px; */
  width: 16rem;
  height: 100vh;
  z-index: 1038;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
  padding-top: 56px;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
  overflow-y: auto;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
  padding-left: 0rem;
  transition: all 0.5s ease;
  top: 0px;
}

.sb-nav-fixed #layoutSidenav.sidebar-minimized #layoutSidenav_content {
  padding-left: 6rem;
  transition: all 0.5s ease;
}

#layoutError {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#layoutError #layoutError_content {
  min-width: 0;
  flex-grow: 1;
}

#layoutError #layoutError_footer {
  min-width: 0;
}

.img-error {
  max-width: 20rem;
}

/* .nav .nav-link .sb-nav-link-icon,
.sb-sidenav-menu .nav-link .sb-nav-link-icon {
  margin-right: 0.5rem;
} */

.sb-topnav {
  padding: 16px 24px;
  height: 68px;
  z-index: inherit;
  align-items: center;
  background: #fff;
}

.sb-topnav-40 {
  padding-left: 0;
  height: 40px;
  z-index: 1039;
  align-items: center;
}

.sb-topnav .navbar-brand {
  /* width: 225px; */
  width: 19rem;
  margin: 0;
  text-align: center;
}

.main-menu-btn {
  background: #eaf1fb !important;
  color: #444 !important;
  border: 1px solid #eaf1fb !important;
  padding: 12px;
}

.main-menu-btn:hover {
  background: #eaf1fb;
  color: #444;
  border: 1px solid #eaf1fb;
}


.sb-topnav.navbar-dark #sidebarToggle {
  color: rgba(255, 255, 255, 0.5);
}

.sb-topnav.navbar-light #sidebarToggle {
  color: #212529;
}

.sb-sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
}

.sb-sidenav .sb-sidenav-menu {
  flex-grow: 1;
}

.sb-sidenav .sb-sidenav-menu .nav {
  flex-direction: column;
  flex-wrap: nowrap;
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading {
  padding: 1.75rem 1rem 0.75rem;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link {
  /* display: flex; */
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative;
  font-size: 14px;
  text-align: left;
}

/* .sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon {
  font-size: 0.9rem;
  padding: 5px;
} */

.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-sidenav-collapse-arrow {
  display: inline-block;
  margin-left: auto;
  transition: transform 0.15s ease;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link.collapsed .sb-sidenav-collapse-arrow {
  transform: rotate(-90deg);
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
  margin-left: 1.5rem;
  flex-direction: column;
}

.sb-sidenav .sb-sidenav-footer {
  padding: 0.75rem;
  flex-shrink: 0;
}

.sb-sidenav-dark {
  background-color: #ffffff;
  color: rgba(255, 255, 255, 0.5);
}

.sb-sidenav-dark .sb-sidenav-menu .sb-sidenav-menu-heading {
  color: rgba(255, 255, 255, 0.25);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link {
  color: #444746;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: #444746;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
  color: #444444;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover {
  color: #444444;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link.active {
  color: #444444;
  background: #D3D4D6;
  border-radius: 25px;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link i {
  font-size: 15px;
  line-height: 1;
  width: 16px;
  margin-right: 1rem;
  text-align: center;
  color: #484848;
}

.logo-shade-line {
  /* border-top: 2px solid #e8eaed; */

  background-image: linear-gradient(90deg, rgba(246, 248, 252, 0), #e6e6e6, rgba(246, 248, 252, 0));
  opacity: .25;
  box-sizing: content-box;
  height: 1px;
  margin: 1rem 0;
  color: inherit;
  opacity: 1;
  border: 0;
  overflow: visible;

}

.side-bar .features-list .features-item.active {
  color: #23252D;
  background: #EBF0FF;
  border-radius: 5px;
  font-weight: 500;
}

/* .sb-sidenav-dark .sb-sidenav-menu .nav-link:hover .sb-nav-link-icon {
  color: #444444;
  background: #dadce0;
  border-radius: 22px;
} */

.sb-sidenav-dark .sb-sidenav-footer {
  background-color: #343a40;
}

.sb-sidenav-light {
  background-color: #f8f9fa;
  color: #212529;
}

.sb-sidenav-light .sb-sidenav-menu .sb-sidenav-menu-heading {
  color: #adb5bd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link {
  color: #212529;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: #adb5bd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
  color: #adb5bd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link:hover {
  color: #0d6efd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link.active {
  color: #0d6efd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
  color: #0d6efd;
}

.sb-sidenav-light .sb-sidenav-footer {
  background-color: #e9ecef;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.pl-6rem {
  padding-left: 0.65rem;
}

.pr-6rem {
  padding-right: 1.45rem;
}

.light-og-color {
  background: #eaf1fb;
}

.search-bar-customm {
  border: 1px solid #E2E3E4;
  border-radius: 8px;
  height: 36px;
  color: #6F7380;
  background: #f7f8fa;
}


.search-bar-customm:hover {
  border-color: #D3D4D6 !important;
}

.h-36px {
  height: 36px;
}

.search-bar-customm:focus {
  border-color: #D3D4D6 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

.search-customm-icon .pi-search {
  width: 25px;
  /* border-left: 1px solid #D3D4D6; */
  padding-left: 5px;
}

ul.navbar-nav .icons-btn {
  background: transparent;
  border: 1px solid transparent;
  color: #5f6368;
  font-size: 18px;
  line-height: 24px;
  width: 36px;
  height: 36px;
  padding-left: 9px;
  padding-top: 9px;
}

ul.navbar-nav .icons-btn:hover {
  background: #ebf0ff;
  border-radius: 50rem;
}

ul.navbar-nav .icons-btn.user-icon-s {
  border: 1px solid #D3D4D6 !important;
}

.bg-primary {
  background: #305FF0;
}

@media all and (min-width: 992px) {

  .sidebar li {
    position: relative;
  }

  .sidebar li .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
    min-width: 240px;
  }

  [dir=rtl] .sidebar li .submenu {
    right: 100%;
    left: auto;
  }

  .sidebar li:hover>.submenu {
    display: block;
  }

}

@media (max-width: 991px) {

  .sidebar .submenu,
  .sidebar .dropdown-menu {
    position: static !important;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: .5rem;
  }

}

.sidebar-overlay {
  z-index: 1039 !important;
  width: 20vw;
  top: 4vw !important;
  left: 6.5vw !important;
}

.sidebar-tooltip .p-tooltip-text {
  background: #fff;
  padding: 0;
  width: 15rem;
  -webkit-box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
  box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
}

.sidebar-tooltip-sales {
  top: 56px !important;
}

.sidebar-tooltip-sales.p-tooltip-right .p-tooltip-arrow {
  top: 5%;
}

.sidebar-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #fff;
}

.bottom-color {
  border-bottom: 1px solid #e8eaed;
}

.header-card-top-color {
  border-top: 1px solid #e8eaed;
}

.side-bar li .submenu.dropdown-menu {
  padding-top: 2px;
  padding-bottom: 2px;
}

.list-group-flush.sidebar-menu-ul>.list-group-item {
  border-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  padding: 5px !important;
}

.list-group-flush.sidebar-menu-ul>.list-group-item a {
  margin-bottom: 0;
  padding: 0.5rem 0.5rem;
}

.list-group-flush.sidebar-menu-ul>.list-group-item i {
  color: #555;
}

.list-group-flush.sidebar-menu-ul>.list-group-item a:hover {
  background: #eaf1fb;
}

.list-group-flush.sidebar-menu-ul>.list-group-item:hover {
  background: transparent;
}



.popover-head {
  background: #eaf1fb;
  padding: 5px;
  border-radius: 25px;
  text-align: center;
}

.sub-header-text-color {
  color: #5f6368;
}

.btn-outline-secondary {
  border: 1px solid #747775;
}

.p-l-r-20 {
  padding-left: 25px;
  padding-right: 25px;
}

.body-text-color {
  color: #444746;
}

.org-card-body {
  /* max-height: 34vw; */
  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-view-org {
  /* max-height: 34vw; */
  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-temp {
  /* max-height: 34vw; */
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-view-user-role-body {
  /* max-height: 34vw; */
  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}

.gst-auth-card-body {
  /* max-height: 34vw; */
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-ledger {
  /* max-height: 34vw; */
  height: calc(100vh - 270px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-ledger-view {
  /* max-height: 34vw; */
  height: calc(100vh - 280px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-tcs,
.org-card-body-tds {
  /* max-height: 34vw; */
  height: calc(100vh - 255px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-stat {
  /* max-height: 34vw; */
  height: calc(100vh - 255px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-gst {
  height: calc(100vh - 255px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-inv {
  height: calc(100vh - 255px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-currency {
  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-gst-classify {
  /* max-height: 34vw; */
  height: calc(100vh - 255px);
  overflow-y: auto;
  overflow-x: hidden;
}

.border-left-sep {
  border-left: 1px solid #e8eaed;
}

.p-tabview.org-tabs .p-tabview-nav li .p-tabview-nav-link {
  font-size: 14px;
  font-weight: 500;
  padding: 0.75rem;
  margin-top: -1px;
  background: transparent;
  box-shadow: none;
}

.p-tabview.org-tabs .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview.org-tabs .p-tabview-nav {

  border: 1px solid #e8eaed;
  border-width: 0 0 1px 0;
  padding-left: 15px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: transparent;
  border-width: 0;
}

.p-tabview.org-tabs .p-tabview-nav li.p-highlight .p-tabview-nav-link span {
  position: relative;
}

.p-tabview.org-tabs .p-tabview-nav li.p-highlight .p-tabview-nav-link span:after {
  position: absolute;
  content: "";
  bottom: -86%;
  left: 0px;
  width: 100%;
  height: 3px;
  background: #305FF0;
  transform: scaleX(1);
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.p-tabview-selected {
  border-width: 10px;
}

.p-tabview.org-tabs .p-tabview-nav .p-tabview-ink-bar {
  background-color: transparent;
}

.p-tabview.org-tabs .p-tabview-panels {
  padding: 0;
}

.org-view-card-body {

  height: calc(100vh - 235px);
  overflow-y: auto;
  overflow-x: hidden;
}

.groups-list-card-body {

  height: calc(100vh - 145px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-view-gst-card-body {

  height: calc(100vh - 235px);
  overflow-y: auto;
  overflow-x: hidden;
}

.inv-card-body {

  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}

.inv-card-body-stck {

  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.inv-card-body-sales {

  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}

.inv-card-body-stock-item {

  height: calc(100vh - 251px);
  overflow-y: auto;
  overflow-x: hidden;
}


.inv-card-body-stock-item-no-footer {

  height: calc(100vh - 169px);
  overflow-y: auto;
  overflow-x: hidden;
}


.inv-card-body-contra {

  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.inv-card-body-purchase {

  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.inv-card-body-journal {

  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-gd {

  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-grp {

  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-grp-new {

  height: calc(100vh - 255px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-grp-view {

  height: calc(100vh - 280px);
  overflow-y: auto;
  overflow-x: hidden;
}

.stockitems-scroll-height .p-datatable-wrapper {

  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.gstr-1-scroll-height .p-datatable-wrapper {

  height: calc(100vh - 300px);
  overflow-y: auto;

}

.gstr-2a-scroll-height .p-datatable-wrapper {

  height: 100vh;
  overflow-y: auto;

}

.gstr-download-scroll-height .p-datatable-wrapper {
  height: calc(100vh - 260px);
  overflow-y: auto;
}

.b-t-r-l-6 {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.primary-address {
  border: 2px solid #305FF0;
  background: #fff;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.address-border {
  border: 1px solid #eaf1fb;
}

.box-top-shadow {
  box-shadow: 0 -6px 10px -10px #d0d0d0;
  z-index: 11;
  border-top: 1px solid #e8eaed48;
}

.stockitems-scroll-height .p-paginator {
  box-shadow: 0 -6px 10px -10px #d0d0d0;
  z-index: 11;
  border-top: 1px solid #e8eaed48;
}

.p-disabled,
.p-disabled * {
  background-color: #f6f8fc;
}

.form-control:disabled {
  background-color: #f6f8fc;
  opacity: 0.5;
  cursor: no-drop;
}

input.form-control:read-only {
  opacity: 0.5;
  cursor: no-drop;
}

.upload-hide {
  position: relative;
  overflow: hidden;
}

.upload-hide input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.v-hidden {
  visibility: hidden;
}

.finsights-data-table .p-datatable .p-datatable-tbody>tr>td {
  padding: 16px 24px;
  /* text-transform: capitalize; */
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  border: 1px solid #d3d4d69c;

  border-width: 0 0 1px 0;
}

.finsights-data-table .p-datatable .p-datatable-thead>tr>th {
  padding: 12px 24px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  background: #F9FAFB;
  color: #515561;
  border: 1px solid #d3d4d6a8;
  border-width: 0 0 1px 0;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: #f6f8fc;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 8px;
  color: #6F7380;
  width: 10px;
}

.p-dropdown-panel.units-dp ul li {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.p-dropdown-panel.units-dp ul li:not(:first-child) table thead {
  display: none;
}

.p-dropdown-panel.units-dp .p-dropdown-items {
  padding: 0 !important;
}

.p-dropdown-panel.units-dp table td {
  border: 0;
  font-size: 12px;
}

.p-dropdown-panel.units-dp table th {
  border: 0;
  font-size: 12px;
  font-weight: 500;
}

.p-dropdown-panel.units-dp table thead,
.p-dropdown-panel.units-dp table tr {
  border: 0;
}

.normal-table-layout thead tr th {
  font-size: 14px;
  font-weight: 500;
  border-color: #e8eaed;
  border-top: 1px solid #e8eaed;
  border-bottom: 1px solid #e8eaed;
  border-left: 1px solid #e8eaed;
}

.normal-table-layout thead tr th:last-child {
  border-right: 1px solid #e8eaed;
}

.normal-table-layout tbody tr td:last-child {
  border-right: 1px solid #e8eaed;
}

.normal-table-layout tbody tr td {
  font-size: 14px;
  font-weight: 400;
  border-color: #e8eaed;
  border-bottom: 1px solid #e8eaed;
  border-left: 1px solid #e8eaed;

}

.normal-table-layout tbody tr {
  border: 0;
}

.table>:not(caption)>*>* {
  border-bottom-width: inherit;
}

.p-tabview.inv-tabs .p-tabview-nav li:first-child {
  margin-left: 5px;
}

.p-tabview.inv-tabs .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #eaf1fb;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: transparent;
}

.v-align-middle {
  vertical-align: middle;
}

.light-thead-bg {
  background: #f6f8fc;
}

.p-dialog .p-dialog-header {
  border-bottom: 1px solid #e8eaed;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.p-dialog .p-dialog-content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.p-dialog .p-dialog-footer {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding-top: 1.5rem;
  box-shadow: 0 -6px 10px -10px #d0d0d0;
  z-index: 11;
  border-top: 1px solid #e8eaed;
}

.p-dialog {
  border-radius: 16px;
  box-shadow: 0px 4px 8px 1px rgb(0 0 0 / 6%);
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 18px;
  color: #23252D;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.hr-b-top {
  border-top: 1px solid #e8eaed;
  opacity: 1;
}

.sidebar-tooltip.inv-tooltip.p-tooltip-right {
  top: inherit !important;
  bottom: 5px !important;
}

.sidebar-tooltip.inv-tooltip.p-tooltip-right .p-tooltip-arrow {
  top: inherit !important;
  bottom: 10px !important;
}

.w-h-btn {
  width: 30px;
  height: 30px;
}

.p-dropdown {
  box-shadow: none;
  transition: none;
}

.border-hightlight-color {
  border-bottom: 3px solid #305FF0;
  border-radius: 0;
}

.text-dark-6 {
  color: #6c757d;
}

.igst-table thead th {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 12px;
  border: 1px solid #D3D4D6;
  border-bottom: 0;
  background: #f9f9f9;
}

.igst-table tbody td {
  border: 1px solid #D3D4D6;
  padding: 10px 12px;
  font-size: 14px;
  vertical-align: top;
}

.setings-sub-menu a {
  border: 0;
  font-size: 14px;
}

.setings-sub-menu a:hover {
  background: transparent;
}

.per-prog .p-progressbar {
  height: 5PX;
}

.auth-button-plus i {
  background: #D3D4D6;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
}

.address-border-dashed {
  border: 1px dashed #e8eaed;
}

.updt-link {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.groups-prop {
  background: #eaf1fb;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  right: 2px !important;
  top: 11px !important;
  cursor: pointer;
}

.overflow-drop-down ul li {
  font-size: 14px;
}

.inline-radio-width .p-radiobutton {
  width: 23px;
  height: 23px;
}

.width-18 {
  width: 18%;
}

.classify-table th {
  border-top: 1px solid #e8eaed;
  border-left: 1px solid #e8eaed;
  font-size: 14px;
  font-weight: 500;
}

.classify-table tr:last-child th,
.classify-table tr:last-child td {
  border-bottom: 1px solid #e8eaed;
}

.classify-table,
.classify-table tr td:last-child {
  border-right: 1px solid #e8eaed;
}

.classify-table td {
  border-top: 1px solid #e8eaed;
  border-left: 1px solid #e8eaed;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#megamenu .dropdown-menu.show {
  -webkit-animation: fadeIn 0.3s alternate;
  animation: fadeIn 0.3s alternate;
}

#megamenu .nav-item.dropdown.dropdown-mega {
  position: static;
}

#megamenu .nav-item.dropdown.dropdown-mega .dropdown-menu {
  width: 50%;
  top: auto;
  right: 1%;
  left: auto;
  margin-top: 14px;
  border: 1px solid #eaf1fb;
}

#megamenu #mega-submenu .dropdown-toggle::after {
  display: none;
}

.sub-megamenu-list li {
  border: 0;
  padding-left: 0;
  background: transparent;
}

.error-message {
  font-size: 10px;
  color: red;
}

.fin-cal-inp .p-calendar {
  width: 100%;
}

.fin-cal-inp .p-inputtext {
  font-size: 14px;
  border: 1px solid #ced4da;
  background: #F7F8FA;
  color: #444746;
  height: 32px;
  width: 100%;
  border-radius: 0.1525rem;
  box-shadow: none;
}

.light-theme-icon {
  border: 1px solid #dadce0;
  width: 45px;
  height: 45px;
  background: #fff;
}

.p-inputtext {
  transition: none;
}

.p-paginator .p-disabled,
.p-paginator .p-disabled * {
  background-color: transparent !important;
}

.upload-bg-profile {
  background: #f9f9f9;
  border-radius: 10px;
  border: 3px dashed #f1f1f1;
}

table th,
table td {
  vertical-align: middle;
}

.finsights-module-icon i {

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}

.finsights-module-icon-sec i {

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}

.bg-warning-opc {
  background-color: #ffc10796;
}

.bg-danger-op {
  background-color: #dc35458f;
}

.bg-primary-op {
  background-color: #305FF0;
}

.bg-success-op {
  background-color: #06b9a3;
}

.bg-dark-op {
  background-color: #212529b0 !important;
}

/* .w-125
{
  width: 125px;
} */
.finsights-module-hr {
  border-top: 2px dashed #cbd0d7;
}

.font-size-10 {
  font-size: 10px;
}

.hr-module-color {
  border-top: 1px solid #f1f1f1;
  opacity: 1;
}

.hr-module-l-r-color {
  border-right: 1px solid #f1f1f1;
}

.blocks-nav i {
  font-size: 18px;
  color: #777;
}

.shortcut-card {
  border: 2px solid #eaf1fb;
}

.more-icon {
  width: 45px;
  height: 45px;
  background: #305FF0;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}

.more-icon-one {
  width: 45px;
  height: 45px;
  background: #eaf1fb;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
}

.middle-icons .more-icon-one {
  width: 45px;
  height: 45px;
  background: transparent;
  border: 2px solid #eaf1fb;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
}

.middle-icons .more-icon {
  width: 45px;
  height: 45px;
  background: transparent;
  border: 2px solid #4b9cd8;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: #4b9cd8;
}

.group-cards .card {
  border-width: 0px;
}

.footer-module {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #fff;
  text-align: center;
}

.finsights-module-card-body {
  /* max-height: 34vw; */
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}

.middle-btns {
  border: 2px solid #eaf1fb;
  text-decoration: none;
  font-weight: 500;
  padding: 10px;
  border-radius: 0.375rem;
  color: #444;
}

.middle-btns:hover {
  color: #444;
}

.view-more-middle {
  background: #4b9cd8;
  border: 2px solid #4b9cd8;
  color: #fff;
}

.view-more-middle i {
  position: absolute;
  right: 10px;
  top: 15px;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.row.display-flex>[class*='col-'] {
  display: flex;
  flex-direction: column;
}

.sidemenu-voucher-list li {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  font-size: 14px;
  padding: 12px 24px 12px 24px;
  border-top-width: 2px !important;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px !important;

}

.sidemenu-voucher-list li:hover {
  background: #F7F8FA;
  border-color: transparent;
}

.sidemenu-voucher-list li:focused {
  background: #F7F8FA;
  border-color: transparent;
  color: #23252d;
}

.sidemenu-voucher-list li.list-group-item:first-child {
  border-radius: 0;
}

.sidemenu-voucher-list li.list-group-item:last-child {
  border-radius: 0;
}

.inside-tax-ledgers-table thead tr th:first-child {
  border-left: 0;
}

.inside-tax-ledgers-table thead tr th:last-child {
  border-right: 0;
}

.inside-tax-ledgers-table tbody tr td:first-child {
  border-left: 0;
}

.inside-tax-ledgers-table tbody tr td:last-child {
  border-right: 0;
}

.inside-tax-ledgers-table tbody tr:last-child td {
  border-bottom: 0;
}

.bg-light-footer {
  background-color: transparent !important;
}

.font-size-16-imp {
  font-size: 16px !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  box-shadow: none;
  transition: none;
  outline: none;
  width: 1.5rem;
  height: 1.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-close-icon {
  font-size: 14px;
}

.party-inputs {
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.party-inps-border {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid #ced4da;
}

.select-inp.party-select .p-dropdown {
  border: 0;
}

.normal-table-layout-zero thead tr th {
  font-size: 14px;
  font-weight: 500;
  border-color: #e8eaed;
  border-top: 1px solid #e8eaed;
  border-bottom: 1px solid #e8eaed;
  border-left: 0px solid #e8eaed;
  border-right: 0;
}

.normal-table-layout-zero thead tr th:last-child {
  border-right: 0px solid #e8eaed;
}

.normal-table-layout-zero tbody tr td:last-child {
  border-right: 0px solid #e8eaed;
}

.normal-table-layout-zero tbody tr td {
  font-size: 14px;
  font-weight: 400;
  border-color: #e8eaed;
  border-bottom: 0px solid #e8eaed;
  border-left: 0px solid #e8eaed;
  border-right: 0;

}

.normal-table-layout-zero tbody tr {
  border: 0;
}

.hide-btn-angle {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.flaot-btn {
  position: absolute;
  top: 45%;
  right: 10px;
}

.total-subtotal-bg {
  background-color: #f6f8fc !important;
}

/*-- style --*/
:root {
  --white-color: #fafafb;
  --background-color: #17171e;
  --grey-color: #b5b5be;
  --dark-grey-color: #292932;
}

.lead {
  font-size: 1.5rem;
  font-weight: 300;
}

.side-bar {
  width: 16rem;
  height: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  transition: all 0.5s ease;
  top: 0;
}

.logo-name-wrapper {
  position: relative;
  margin-bottom: 2.1rem;
  display: flex;
  font-size: 1.2rem;
}

.logo-name {
  display: flex;
  align-items: center;
}

.logo-name__name {
  margin-left: 0.9rem;
  white-space: nowrap;
}

.logo-name__button {
  position: absolute;
  top: 45%;
  right: -90px;
  font-size: 14px;
  transform: translateY(-45%) rotate(180deg);
  background-color: transparent;
  border: none;
  cursor: pointer;

}


.logo-name__button .left-bracket {
  transform: translateY(-5%) rotate(180deg);
}

.logo-name__icon {
  font-size: 18px;

}

.message {
  background-color: var(--dark-grey-color);
  display: flex;
  align-items: center;
  padding: 1.55rem 0 1.55rem 1.2rem;
  border-radius: 0.4rem;
  margin-bottom: 3.6rem;
  cursor: pointer;
  position: relative;
}

.message-icon {
  font-size: 2rem;
  transform: translateX(3rem);
  transition: all 0.5s ease;
}

.message-text {
  margin-left: 1.1rem;
  font-size: 1.4rem;
  white-space: nowrap;
  transform: translateX(3rem);
  transition: all 0.5s ease;
}

.features-list {
  padding-bottom: 1rem;
  list-style: none;
}

.features-item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.65rem 0.9rem;
  border-radius: 5px;
  margin-bottom: 18px;
  color: #23252D;
  font-size: 14px;
  cursor: pointer;
  transition: 0.5s ease;
}


/* .features-item::before {
  transition: 0.5s ease;
  content: '';
  position: absolute;
  left: -1.2rem;
  height: 100%;
  border-left: 3px solid var(--white-color);
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
} */

/* .features-item.active:before {
  content: "";
  position: absolute;
  right: -16px;
  width: 0.25rem;
  height: 2.275rem;
  border-radius: 0.375rem 0 0 0.375rem;
}

.features-item.active:before {
  background: #305FF0;
} */

.features-item:hover {
  color: #444746;
  transition: 0.5s ease;
}

.features-item:hover::before {
  opacity: 1;
  visibility: unset;
}

.features-item-icon {
  font-size: 2rem;
}

.features-item-text {
  margin-left: 15.33px;
  transition: opacity 0.6s ease;
  opacity: 1;
}

.inbox-number {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: #1e75ff;
  padding: 0.5rem 0.9rem;
  border-radius: 0.4rem;
  color: var(--white-color);
  opacity: 1;
  transition: 0.6s linear;
}

.inbox-icon {
  position: relative;
}

.inbox-icon .status {
  position: absolute;
  top: 0;
  right: -1px;
  background-color: #1e75ff;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  border: 2px solid var(--white-color);
  opacity: 0;
  transition: 0.6s linear;
}

.category-list {
  padding-top: 2.1rem;
  padding-bottom: 0.9rem;
  color: var(--grey-color);
  list-style: none;
  font-size: 1.2rem;
  font-weight: 500;
  border-bottom: 1px solid #292932;
}

.category-header {
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1.8rem;
  color: var(--white-color);
  margin-bottom: 1.3rem;
  white-space: nowrap;
}

.category-item {
  position: relative;
  padding: 0.9rem 1.1rem;
  line-height: 1.8rem;
  cursor: pointer;
  white-space: nowrap;
}

.category-item-status {
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  border: none;
  background-color: white;
  display: inline-block;
}

.category-item-text {
  margin-left: 1rem;
  transition: opacity 0.6s ease;
  opacity: 1;
}

.category-item-number {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.chat-list {
  padding: 2.1rem 0;
  font-size: 1.2rem;
}

.chat-header {
  text-transform: uppercase;
  line-height: 1.8rem;
  color: var(--white-color);
  margin-bottom: 1.3rem;
  white-space: nowrap;
}

.chat-new-btn {
  color: #1e75ff;
  background-color: transparent;
  outline: none;
  border: none;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  padding-bottom: 2.1rem;
  padding-left: 0.1rem;
  cursor: pointer;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.chat-new-btn-text {
  white-space: nowrap;
  transition: opacity 0.6s ease;
  opacity: 1;
}

.chat-icon {
  font-size: 2.1rem;
  margin-right: 1.2rem;
}

.chat-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 2.1rem;
  color: var(--grey-color);
  cursor: pointer;
}

.chat-item-avatar-wrapper {
  position: relative;
}

.chat-item-avatar-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  right: -3px;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: #79d861;
  opacity: 0;
  transition: 0.6s linear;
}

.chat-item-avatar-wrapper.has-message::before {
  background-color: #1e75ff;
  border: 2px solid var(--white-color);
  height: 6px;
  width: 6px;
}

.chat-item-avatar {
  object-fit: cover;
  object-position: top;
  height: 2.1rem;
  width: 2.1rem;
  border-radius: 50%;
  border: 1px solid var(--white-color);
}

/* background color in inline style */
.chat-item-status {
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  border: none;
  background-color: white;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin-right: 5px;
  opacity: 1;
  transition: 0.6s linear;
}

.chat-item-number {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: #1e75ff;
  padding: 0.5rem 0.9rem;
  border-radius: 0.4rem;
  color: var(--white-color);
  opacity: 1;
  transition: 0.6s linear;
}

.chat-item-name {
  white-space: nowrap;
  transition: opacity 0.6s ease;
  opacity: 1;
  margin-left: 1.4rem;
}

.tooltip {
  position: absolute;
  top: -2.1rem;
  left: 6.4rem;
  background-color: white;
  color: var(--background-color);
  border-radius: 0.4rem;
  padding: 0.5rem 0.9rem;
  font-size: 1.4rem;
  transition: 0s;
  opacity: 0;
  display: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.side-bar.collapse .message:hover .tooltip,
.features-item:hover .tooltip,
.category-item:hover .tooltip,
.chat-new-btn:hover .tooltip {
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  opacity: 1;
}

.side-bar.collapse {
  width: 6.4rem;
}

.side-bar.collapse .tooltip {
  display: block;
}

.side-bar.collapse .logo-name,
.side-bar.collapse .category-header,
.side-bar.collapse .chat-header,
.side-bar.collapse .chat-new-btn-text,

.side-bar.collapse .inbox-number,
.side-bar.collapse .category-item-text,
.side-bar.collapse .category-item-number,
.side-bar.collapse .message-text,
.side-bar.collapse .chat-item-name,
.side-bar.collapse .chat-item-number,
.side-bar.collapse .chat-item-status {
  opacity: 0;
  pointer-events: none;
}

.sidebar-minimized .side-bar.collapse .features-item-text {
  opacity: 0;
  pointer-events: none;
  width: 0;
  margin-left: 0;
  display: none;
}

.sidebar-minimized .side-bar.collapse .features-item .dp-arrow-icon-float {
  position: absolute;
  right: 10px;
  top: 13px;
}

.sidebar-minimized .features-list .features-item {
  justify-content: center;
}

.sidebar-minimized .features-list .features-item i {
  margin-right: 0 !important;
}

.sidebar-minimized .admin-block {
  justify-content: center;
}

.sidebar-minimized .admin-block .avtar-margin {
  margin-right: 0 !important;
}

.side-bar.collapse .message-text {
  opacity: 0;
  pointer-events: none;
  transform: translateX(0);
}

.side-bar.collapse .message-icon {
  transform: translateX(0);
}

.side-bar.collapse .logo-name__button {
  right: 8.5rem;
}

.side-bar.collapse .inbox-icon .status,
.side-bar.collapse .chat-item-avatar-wrapper::before {
  opacity: 1;
}

.side-bar.collapse:not(.show) {
  display: block;
}

.side-bar .logo-name__icon.collapse:not(.show) {
  display: block;
}

.features-list .features-item .sb-nav-link-icon {
  width: 15px;
  text-align: center;
}

.sidebar-heading-padding {
  padding: 0.5rem 0rem;
}

.home-icon-btn {
  width: 45px;
  height: 45px;
  border: 2px solid #000;
  border-radius: 50px;
  line-height: 42px;
  text-align: center;
}

.menu-btn-bar {
  border: 2px solid #eaf1fb;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  background: #eaf1fb;
}

.sidebar-minimized .admin-info {
  display: none;
}

.sidebar-minimized .module-heading-color {
  display: none;
}

.sidebar-minimized .side-bar.collapse .logo-name__button {
  left: 15%;
}

.sidebar-minimized .menu-bar-justify {
  justify-content: center !important;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

  .sidebar li {
    position: relative;
  }

  .sidebar li .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
    min-width: 240px;
  }

  [dir=rtl] .sidebar li .submenu {
    right: 100%;
    left: auto;
  }

  .sidebar li:hover {
    background: var(--bs-light);
  }

  .sidebar li:hover>.submenu {
    display: block;
  }

}

/* ============ desktop view .end// ============ */


/* ============ small devices ============ */
@media (max-width: 991px) {

  .sidebar .submenu,
  .sidebar .dropdown-menu {
    position: static !important;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: .5rem;
  }

}

/* ============ small devices .end// ============ */


.sidebar .nav-link {
  font-weight: 500;
  color: var(--bs-dark);
}

.sidebar .nav-link:hover {
  background: var(--bs-light);
  color: var(--bs-primary);
}

.dp-arrow-icon-float {
  position: absolute;
  right: 10px;
}

.ang-icon i {
  position: absolute;
  top: 16px;
  right: 15px;
}

.overflow-content-dlg .p-dialog-content {
  overflow-y: inherit;
}

.accordion-icon-animate button.btn.btn-link[aria-expanded="true"] i {
  transform: rotate(90deg);
}

.accordion-icon-animate button.btn.btn-link i {
  transform: rotate(0deg);
  transition: 0.5s;
}

.accordion-icon-animate-flat button.btn.btn-link[aria-expanded="true"] i {
  transform: rotate(90deg);
  display: inline-block;
  margin-right: 5px;
}

.accordion-icon-animate-flat button.btn.btn-link i {
  transform: rotate(0deg);
  transition: 0.5s;
  display: inline-block;

}

/*-- buttons --*/
.btn-primary-grey {
  background: #C0D0FF;
  background-color: #C0D0FF;
  border: 0;
  color: #444;
}

.sidebar-minimized .hidden-back-text {
  display: none;
}

.sidebar-minimized .back-menu-text-hide {
  display: none;
}

.l-h-35 {
  line-height: 35px;
}

.avatar-rounded-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #ffffff42;
}

.p-dialog-mask {
  background: #00000080 !important;
}

.bg-primary-50 {
  background: #ebf0ff;
}

.b-r-8 {
  border-radius: 8px;
}

.sidebar li.dp-custom .submenu {
  top: -10vw;
}

.sidebar li.dp-custom-last .submenu {
  top: -9vw;
}

.back-hr-line {
  border-color: #e8eaed;
  opacity: 1;
}

.f-header-padding {
  padding: 24px;
}

label {
  color: #515561;
  font-size: 14px !important;
}

.mb-3-f {
  margin-bottom: 24px;
}

.left-ul .li-right-margin {
  margin-right: 24px;
}

.p-3-sidenav {
  padding: 24px;
  padding-left: 20px;
  padding-right: 20px;
}

.mb-3 {
  margin-bottom: 24px !important;
}

/* 
.card-header {
  padding: 20px !important;
  line-height: 1;
  border-bottom: 1px solid #D3D4D6 !important;
} */

.p-3 {
  padding: 24px !important;
}

.logo-padding-height {
  height: 68px;
}

.ps-3 {
  padding-left: 24px !important;
}

.pe-3 {
  padding-right: 24px !important;
}

.pb-3 {
  padding-bottom: 24px !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

}

.upload-btn-wrapper .btn {
  border-width: 2px;

}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.igst-table tfoot td {
  border: 1px solid #D3D4D6;
}

.op-bal-table tr th {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #D3D4D6 !important;
}

.op-bal-table tbody tr:last-child td {
  border-bottom: 1px solid #D3D4D6 !important;
}



.op-bal-table tr td {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-top: 0;
}

.bg-higlight-active {
  background: #EBF0FF !important;

}

.gst-config-dlg .p-dialog-content {
  padding: 0;
}

.overflow-cnt .p-dialog-content {
  overflow-x: hidden;
}

.under-lined {

  padding-bottom: 0px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  width: fit-content;
}

.bg-warning-fade {
  background: #fcf6ae;
}

.logo-w,
.hidden {
  display: none;
}

#dark-theme .logo-d {
  display: none;
}

#dark-theme .logo-w {
  display: block;
}

.stat-list button i {
  position: absolute;
  right: 24px;
  top: 20px;
}

.h-50 {
  height: 50px !important;
}

.mb-3-1rem {
  margin-bottom: 1rem;
}

.p-3-1rem {
  padding: 1rem;
}

.pb-3-1rem {
  padding-bottom: 1rem;
}

.pe-3-1rem {
  padding-right: 1rem;
}

.ps-3-1rem {
  padding-left: 1rem;
}

.w-1-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.text-less-inp {
  border: none;
  background-color: none;
  outline: 0;
  font-size: 20px;
  height: 40px;
  font-weight: 600;
  width: 100%;
}

.text-less-inp:focus {
  border: none;
  background-color: none;
  outline: 0;
}

.cost-center-table thead th {
  font-size: 14px;
  font-weight: 500;
  padding: 15px 15px;
  border: 1px solid #d3d4d6a8;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
  background: #f9f9f9;
}

.cost-center-table thead th:first-child {
  border-top-left-radius: 10px;
}

.cost-center-table thead th:last-child {
  border-top-right-radius: 10px;
}

.cost-center-table tbody td {
  border: 1px solid #d3d4d6a8;
  padding: 10px 15px;
  border-left: 0;
  border-right: 0;
  font-size: 14px;
}


.tax-rate-table thead tr th {
  border-left: 0;
  /* border-right: 0; */
}

.tax-rate-table thead tr th {
  border-top: 0;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  background: #f7f8fa;
  border-bottom: 1px solid #D3D4D6;
  border-right: 1px solid #D3D4D6;
}

.tax-rate-table thead tr th:first-child {
  border-left: 0;
}

.tax-rate-table thead tr th:last-child {
  border-right: 0;
}

.tax-rate-table tbody tr td:first-child {
  border-left: 0;
}

.tax-rate-table tbody tr td:last-child {
  border-right: 0;
}

.tax-rate-table tbody tr:first-child td {
  border-top: 0;
}

.tax-rate-table tbody tr td {
  border-color: #e8eaed;
  font-size: 14px;
  padding: 2px;
  border-bottom: 1px solid #D3D4D6;
  border-top: 1px solid #D3D4D6;
  border-right: 1px solid #D3D4D6;
}

.tax-rate-table tbody tr td {
  border-bottom: 0px;
}

.tax-rate-table td .form-control {
  background: transparent;
  border: 1px solid #fff0 !important;
  border-radius: 1px;
}

.tax-rate-table td .form-control:focus-within {
  box-shadow: 0 0 0 2px #3060f08e !important;
}



.background-table-cell-focus:focus {
  /* box-shadow: rgb(35 131 226 / 57%) 0px 0px 0px 1px inset, rgb(35 131 226 / 35%) 0px 0px 0px 2px; */
  background-color: #0055ff20 !important;
  outline: 0;
  border-radius: 3px;

  border: 1px solid #0055ff20;
}

.l-h-1 {
  line-height: 1;
}

.gst-app-table .p-datatable .p-datatable-thead tr th {
  padding: 10px 24px !important;
}

.gst-app-table .p-datatable .p-datatable-tbody tr td {
  padding: 10px 24px !important;
}

.gst-sidenav .features-item {
  margin-bottom: 10px;
}

.gst-sup {
  font-size: 8px;
  font-weight: 600;
  position: absolute;
  top: 6px;
  padding: 1px;
  color: #000000;
  left: 26px;
  /* background: #fff; */
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  /* border: 1px solid #212529; */
  line-height: 11px;
  display: none;
}

.l-75 {
  line-height: 1.75;
}

.series-cards-blue .card {
  border-left: 3px solid #305FF0 !important;
  border-radius: 3px;
}

.inside-nested-table thead tr th {
  border-top: 0;
}

.inside-nested-table tbody tr td:first-child {
  border-left: 0;
}

.inside-nested-table tbody tr td:last-child {
  border-right: 0;
}

.inside-nested-table thead tr th:first-child {
  border-left: 0;
}

.inside-nested-table thead tr th:last-child {
  border-right: 0;
}

.not-caption>:not(caption)>*>* {
  padding: 0;
}

.gst-3b-table tbody td {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #d3d4d6a8;
}

.gst-3b-table tbody tr td:first-child {
  background: #fed8b140;
}

.gst-3b-table-odd tbody tr td:nth-child(odd) {
  background: #fff;
}

.gst-3b-table-sec tbody td {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #d3d4d6a8;
}

.gst-3b-table-sec tbody tr td:first-child {
  background: #fff;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}


/* vertical spacing between columns */


.row-flex .content {
  height: 100%;

}

.hr-color-3b {
  border: 0;
  border-top: 1px solid #d3d4d6a8;
  opacity: 1;
}

.toggle-elipsis {
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-dp-3b {
  width: 350px;
}

.custom-dp-3b li a {
  width: 350px;
  white-space: normal;
  overflow: auto;
  font-size: 14px;
}

/* .right-border-card {
  border-right: 1px solid #d3d4d6a8 !important;
} */

.wrap-table-3b th {
  white-space: nowrap;
}

.wrap-table-3b td {
  white-space: nowrap;
}

.submenu-li a {
  font-size: 14px;
}

.submenu-li {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding: 0;
}

.sidebar-menu-ul-li li a {
  margin-bottom: 0;
}

.sidebar-menu-ul-li li {
  border-bottom: 0;
}

.summary-border-less thead th {
  border-left: 0;
  border-right: 0;
}

.summary-border-less tbody td {
  border-left: 0;
  border-right: 0;
}

.highlight-acc .accordion-collapse.collapse.show td {
  background: #fed8b140;
}

.accordion [aria-expanded=true] .fi:before,
.accordion [aria-expanded=true] .fi:before {
  transform: rotate(90deg);
  display: inline-block;
  margin-right: 5px;
}

.card-light-bg {
  background: #f7f9fc;
}

.cost-center-table .card-light-bg-clr {
  background-color: #f7f9fc;
}

.ag-dt-table-gstr-1 {
  height: calc(100vh - 110px);
}

.ag-table-style .ag-root-wrapper {
  border: 0;

}

.ag-table-style .ag-column-drop-horizontal {
  background: #fff;
  border-bottom: 1px solid #d3d4d6a8;
}

.ag-table-style .ag-header {
  background-color: #F9FAFB;
  border-bottom: 1px solid #d3d4d6a8;
}

.ag-table-style .ag-header-row:not(:first-child) .ag-header-cell,
.ag-table-style .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
  border-top: 1px solid #d3d4d6a8 !important;
}

.ag-table-style .ag-rtl .ag-side-bar-left .ag-tool-panel-wrapper,
.ag-table-style .ag-ltr .ag-side-bar-right .ag-tool-panel-wrapper {
  border-right: 1px solid #d3d4d6a8;
}

.ag-table-style .ag-rtl .ag-side-bar-left,
.ag-table-style .ag-ltr .ag-side-bar-right {
  border-left: 1px solid #d3d4d6a8;
}

.ag-table-style .ag-input-field-input.ag-checkbox-input {
  background: #305FF0;
}

.ag-table-style .ag-header-row {

  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 12px;
  color: #6B7280 !important;
}

.ag-table-style .ag-cell {
  font-size: 14px;
  font-weight: 500;
  color: #515561;
  line-height: 50px;
}

.ag-table-style .ag-row-odd {
  background-color: #fff;
}

.ag-table-style .ag-header-container .ag-header-row.ag-header-row-column-filter {
  background-color: #fff;
}

.ag-table-style .ag-floating-filter-body .ag-input-field-input.ag-text-field-input {
  border: 1px solid #D3D4D6;
  color: #6F7380;
  background: #fff;
}

.ag-table-style .ag-column-panel-column-select {
  border-top: 1px solid #d3d4d6a8;
  border-bottom: 1px solid #d3d4d6a8;
}

.ag-table-style .ag-column-select-header {
  border-bottom: 1px solid #d3d4d6a8;
}

.ag-table-style .ag-column-drop-vertical {
  border-bottom: 1px solid #d3d4d6a8;
}

.ag-table-style .ag-tool-panel-wrapper {
  background: #fff;
}

.ag-table-style .ag-pivot-mode-panel {
  background: #f7f8fa;
}

.ag-table-style .ag-pinned-right-header {
  border-left: 1px solid #d3d4d6a8;
}

.ag-table-style .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
  border-left: 1px solid #d3d4d6a8;
}

.ag-table-style .ag-paging-panel {
  border-top-color: #d3d4d6a8;
}

.ag-table-style .ag-header-cell.ag-floating-filter:hover {
  background-color: #fff;
}

.ag-table-style .ag-header-cell.ag-floating-filter {
  background-color: #fff;
}


.table-border-less-inp td .select-inp .p-dropdown {
  /* border: transparent; */
  background: transparent;
}

/* .table-border-less-inp td .select-inp .p-dropdown .p-dropdown-label {
  padding-left: 0;
} */

.table-border-less-inp td .form-control {
  /* border: transparent; */
  background: transparent;
}

.table-border-less-inp-cc td .select-inp .p-dropdown {
  /* border: transparent; */
  background: transparent;
}


.table-border-less-inp-cc td .form-control {
  /* border: transparent; */
  background: transparent;
}

.table-border-less-inp thead tr th {
  border-right: 0;
  border-left: 0;
}

.table-border-less-inp tbody tr td {
  border-right: 0;
  border-left: 0;
}

.table-border-less-inp thead tr th:first-child {
  border-left: 1px solid #D3D4D6;
}

.table-border-less-inp thead tr th:last-child {
  border-right: 1px solid #D3D4D6;
}

.table-border-less-inp tbody tr td:first-child {
  border-left: 1px solid #D3D4D6;
}

.table-border-less-inp tbody tr td:last-child {
  border-right: 1px solid #D3D4D6;
}

.table-border-less-inp-cc tbody tr:last-child td {
  border-bottom: 0;
}

.table-border-less-inp-cc tbody tr.accordion-collapse td {
  border-bottom: 0 !important;
  border-color: transparent;
}

.gstin-view-dlg .p-dialog-content {
  background: #f7f8fa;
}

.pills-badge .badge {
  padding: 7px;
  border-radius: 5px;
  font-size: 12px;
}

.delete-icon-img {
  background: #f7f8fa;
  border-radius: 50%;
  display: inline-block;
  line-height: 120px;
  text-align: center;
  vertical-align: middle;
  width: 120px;
  height: 120px;
}

.delete-icon-img img {
  vertical-align: middle;
}

.delete-icon-img-small {
  background: #f7f8fa;
  border-radius: 50%;
  display: inline-block;
  line-height: 70px;
  text-align: center;
  vertical-align: middle;
  width: 70px;
  height: 70px;
}

.delete-icon-img-small img {
  vertical-align: middle;
}

.filter-search-inp {
  height: 38px;
  border-radius: 8px;
}

.vertical-table th {
  border: 1px solid #d3d4d6a8;
  padding: 10px;
  background: #F9FAFB;
}

.vertical-table td {
  border: 1px solid #d3d4d6a8;
  padding: 10px;
}

.form-control:focus-within {
  box-shadow: 0 0 0 1px #3060f08e !important;
}

.inside-bg-inp .form-control {
  background: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.figma-bg {
  background-color: #f7f8fa !important;
}

.tp-inp textarea {
  border-color: transparent;
}

.tp-input input {
  border-color: transparent;
}

.h-35-inp input {
  height: 35px;
}

.h-38-inp .select-row-revese .MuiInputBase-fullWidth {
  height: 38px;
}

.h-38-inp .form-control {
  height: 38px;
}

.br-inp .form-control {
  border: 1px solid #d3d4d6;
}

.accordion [aria-expanded=true] i.fi-br-envelope:before,
.accordion [aria-expanded=true] i.fi-br-envelope:before {
  transform: rotate(0deg);
  display: inline-block;
  margin-right: 0px;
}

.kNavigation-focus:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.up-3px-border {
  border: 3px dashed #e9e9e9;
}

.pics-list li {
  border: 0;
  font-size: 14px;
  font-weight: 500;
  padding-left: 0;
  padding-top: 2px;
  padding-bottom: 2px;
}

.paper-rotate::before {
  transform: rotate(35deg);
  display: inline-block;
}

.cost-center-inp input {
  height: 40px;
  border-radius: 8px;
}

.ag-grid-tree-table .ag-root-wrapper {
  border-left: 0;
  border-right: 0;
}

.ag-grid-tree-table .ag-header-cell-label {
  font-weight: 600;
  font-size: 14px;
}

.table.inside-caption>:not(caption)>*>* {
  padding: 0;
}

.right-10-minus {
  right: -10% !important;
  left: inherit !important;
  margin-top: 10px !important;
  width: 300px;
  box-shadow: 0 24px 54px rgba(0, 0, 0, .15), 0 4.5px 13.5px rgba(0, 0, 0, .08);
  border: 0;
  border-radius: 10px;
  padding: 0px
}

.no-toggle-icon .dropdown-toggle::after {
  border-color: transparent;
  display: none;
}

.avatar-rounded-img-lg {
  width: 50px;
  height: 50px;
}

.submenu-list-items {
  list-style: none;
  padding-left: 0;
}

.submenu-list-items li a {
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
}

.hr-submenu-divider {
  border-top: 2px solid #e8eaed;
}

.footer-temp-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.h-45-login-inp {
  height: 45px;
  border-radius: 3px;
}

.h-50-signup-inp {
  height: 50px;
  border-radius: 3px;
}

.slider-caption .carousel-caption {
  position: relative;
  left: 0;

}

.slider-caption .carousel-indicators [data-bs-target] {
  background-color: #007bff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.border-bottom-light-c {
  border-bottom: 2px solid #f3f3f3;
}

.border-bottom-light-c {
  border-bottom: 2px solid #f3f3f3;
}

.right-login-sep {
  border-right: 3px solid #e8eaed;
}



.nav-route {
  position: absolute;
  right: 1%;
  top: 2%;
}

/*--- checkbox ---*/
.section.section--tuuli {
  float: right;
}

.toggle-button {
  position: relative;
  display: inline-block;
  color: #fff;
}

.toggle-button label {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  color: #666;
  cursor: pointer;
  text-align: left;
}

.toggle-button input {
  display: none;
}

.toggle-button__icon {
  cursor: pointer;
  pointer-events: none;
}

.toggle-button__icon:before,
.toggle-button__icon:after {
  content: "";
  position: absolute;
  top: 45%;
  left: 35%;
  transition: 0.2s ease-out;
}


.toggle-button--tuuli label {
  height: 25px;
  line-height: 25px;
  transition: all 0.2s;
  border-radius: 2rem;
}

.toggle-button--tuuli label:before,
.toggle-button--tuuli label:after {
  position: absolute;
  right: 1rem;
  transition: all 0.2s .1s ease-out;
}

.toggle-button--tuuli input[type=checkbox]+label {
  width: 25px;
  background: #fff;
}

.toggle-button--tuuli input[type=checkbox]+label:before {
  opacity: 0;
  transform: translate(0, 20px);
}

.toggle-button--tuuli input[type=checkbox]+label:after {
  opacity: 1;
  transform: translate(0, 0);
}

.toggle-button--tuuli input[type=checkbox]:checked~label {
  width: 25px;
  background: #fff;

}

.toggle-button--tuuli input[type=checkbox]:checked~.toggle-button__icon:before {
  transform: translate(-30%, 100%) rotate(45deg) scale(0.6, 1);
  background: #61B136;

}

.toggle-button--tuuli input[type=checkbox]:checked~.toggle-button__icon:after {
  transform: translate(20%) rotate(-45deg);
  background: #61B136;
}

.toggle-button--tuuli .toggle-button__icon {
  position: absolute;
  height: 25px;
  width: 25px;
  top: 0;
  left: 0;
}

.toggle-button--tuuli .toggle-button__icon:before,
.toggle-button--tuuli .toggle-button__icon:after {
  width: 15px;
  height: 3px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  margin-left: -15%;
  top: 45%;
}

.toggle-button--tuuli .toggle-button__icon:before {
  transform: rotate(45deg);
  background: #f00;
}

.toggle-button--tuuli .toggle-button__icon:after {
  transform: rotate(-45deg);
  background: #f00;
}

.toggle-button--tuli label {
  line-height: 20px;
  text-indent: 30px;
}

.toggle-button--tuli input[type=checkbox]:checked~.toggle-button__icon {
  background: #fff;
}

.toggle-button--tuli input[type=checkbox]:checked~.toggle-button__icon:before,
.toggle-button--tuli input[type=checkbox]:checked~.toggle-button__icon:after {
  opacity: 1;
}

.toggle-button--tuli .toggle-button__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  transition: all 0.2s;
  border: 2px solid #444;
  border-radius: 1px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.toggle-button--tuli .toggle-button__icon:before,
.toggle-button--tuli .toggle-button__icon:after {
  top: 5px;
  left: 2px;
  width: 12px;
  height: 2px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  top: 35%;
  background: #61B136;
  opacity: 0;
  transform-origin: left center;
}

.toggle-button--tuli .toggle-button__icon:before {
  transform: translate(0, 0) rotate(45deg) scale(0.6, 1);
}

.toggle-button--tuli .toggle-button__icon:after {
  transform: translate(4px, 6px) rotate(-45deg);
}

.toggle-button--tuli:hover input[type=checkbox]:not(:checked)~.toggle-button__icon {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

/*--- checkbox ---*/
.p-menu .p-submenu-header {
  color: #212529;
  font-weight: 600;
}

.p-menu .p-menuitem-link {
  font-size: 14px;
  color: #212529;
}

nav .dropdown-item.active,
nav .dropdown-item:active {
  color: #23252D;
  background: #EBF0FF;
  border-radius: 5px px;
  font-weight: 500;
}

.light-btns .btn-light {
  background: #f7f8fa;
  border: 1px solid #D3D4D6;
}

.justify-vertical-icon:before {
  transform: rotate(90deg);
  display: inline-block;
}

.ag-grid-tree-table .ag-header {
  background-color: #fff;
  border-bottom: 1px solid #d3d4d6a8;
}

.ag-grid-tree-table .ag-root-wrapper {
  border-color: #d3d4d6a8;
}

.ag-grid-tree-table .ag-row {
  border-color: transparent;
}

.ag-grid-tree-table .ag-row-odd {
  background-color: #fff;
}

.ag-grid-tree-table .ag-ltr .ag-cell {
  text-align: right;
}

.ag-grid-table-cell-left.ag-grid-tree-table .ag-ltr .ag-cell {
  text-align: left;
}

.ag-grid-table-cell-left.ag-grid-tree-table .ag-ltr .ag-cell .p-checkbox {
  vertical-align: middle;
}

/* .ag-grid-tree-table .ag-header-cell:last-child .ag-header-cell-label {
  justify-content: end;
} */

.ag-grid-table-cell-left.ag-grid-tree-table .ag-header-row {
  background: #f7f8fa;
}

.ag-grid-tree-table .ag-header-icon.ag-header-cell-menu-button {
  display: none;
}

.ag-grid-tree-table .ag-body-vertical-scroll-viewport {
  width: 0 !important;
  max-width: 0 !important;
  min-width: 0 !important;
}

.l-h-s .header-dp-select .p-dropdown {
  /* border-top-right-radius: 0;
  border-bottom-right-radius: 0; */
  max-width: 210px;
}

.r-h-s .header-dp-select .p-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
  max-width: 210px;
}

.mega-menu-dlg {
  margin-top: 70px !important;
}

.mega-menu-dlg .p-dialog-content {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  /* padding: 1rem !important; */
  overflow-x: hidden;
}

.mega-menu-dlg .p-dialog-content:last-of-type {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.mega-menu-dlg .p-dialog-header {
  position: absolute;
  right: 0px;
  background: transparent;
  border: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
  top: 0px;
}

.mega-menu-dlg .p-dialog-header-icons i {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  right: 15px;
  top: 0px;
  margin: auto;
  line-height: 40px;
  /* background: #F7F8FA; */
  /* border: 1px dashed #d3d4d6; */
  border-radius: 25px;

}

.mega-menu-dlg .p-dialog-header-icons i:hover {
  background: #F7F8FA;
}

.trans-buttons a.btn-outline-light {
  border: 2px solid #e9e9e9;
  text-decoration: none;
  font-weight: 500;
  padding: 10px;
  border-radius: 0.375rem;
  color: #23252D;
}

.trans-buttons button.btn-primary {
  border: 2px solid #305FF0;
  text-decoration: none;
  font-weight: 500;
  padding: 10px;
  border-radius: 0.375rem;
}

.trans-buttons button.btn-primary i {
  position: absolute;
  right: 10px;
  top: 15px;
}

/* #ledger-view-card .card-header {
  display: none;
}

#ledger-view-card .card-footer {
  display: none;
}

#ledger-view-card .org-card-body-ledger {
  height: 100%;
} */

.ledger-view-table thead th {
  border-left: 0;
  border-right: 0;
  border-color: #d3d4d654;
}

.ledger-view-table tbody td {
  border-left: 0;
  border-right: 0;
  border-color: #d3d4d654;
}

input:disabled::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #C8C9CB;
}

input:disabled:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #C8C9CB;
}

input:disabled::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #C8C9CB;
}

input:disabled:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #C8C9CB;
}

#view-ledger-inp input {
  color: #C8C9CB;
}

#view-ledger-inp .form-control {
  pointer-events: none;
  color: #C8C9CB;
  background: #F5F5F5;
}

#view-ledger-inp .dp-chip span.badge {
  background-color: #e0e0e0 !important;
  color: #b8b8b8;
}

.ledger-view-gst-table tr td {
  border: 1px solid #e8eaed;
}

.ag-table-style.no-sidebar-grid .ag-body-vertical-scroll-viewport {
  display: none;
}

.ag-table-style.no-sidebar-grid .ag-body-vertical-scroll {
  display: none;
}

.total-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 10px;
}

.pl-18 {
  padding-left: 18px !important;
}

.pr-18 {
  padding-right: 18px !important;
}

.net-loss {
  color: #F05252;
}

.text-danger {
  color: #F05252 !important;
}

.gross-profit {
  color: #0E9F6E;
}

.profit-bg-success {
  background: #f7f8fa;
}

.profit-bg-success p {
  color: #0E9F6E;
}

.pl-44 {
  padding-left: 44px;
}

.pr-44 {
  padding-right: 44px;
}

.net-loss-bg {
  background: #FDF2F2;
}

.net-loss-bg p {
  color: #F05252;
}

.ag-table-style.row-drag-top .ag-column-drop-horizontal {
  border-top: 1px solid #d3d4d6a8
}

.b-r-025 {
  border-radius: 0.25rem;
}

.ag-table-style .ag-root-wrapper {
  background-color: transparent;
}

.summary-cards-9c .card .card-header p:after {
  content: "";
  background-color: #305FF0;
  position: absolute;
  left: 0px;
  top: 18px;
  width: 4px;
  height: 20px;
}

.summary-cards-9c .card {
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
}

.summary-cards-9c .card:hover {
  -webkit-transform: translate(0, -8px);
  -moz-transform: translate(0, -8px);
  -ms-transform: translate(0, -8px);
  -o-transform: translate(0, -8px);
  transform: translate(0, -8px);
  /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); */
}

.background-focus:focus {
  box-shadow: rgb(35 131 226 / 57%) 0px 0px 0px 1px inset, rgb(35 131 226 / 35%) 0px 0px 0px 2px;
  background-color: #EBF0FF;
  outline: 0;
  border-radius: 3px;
}

.background-focus-hover-btns-td:focus {
  box-shadow: rgb(35 131 226 / 57%) 0px 0px 0px 1px inset, rgb(35 131 226 / 35%) 0px 0px 0px 2px;
  background-color: #EBF0FF;
  outline: 0;
  border-radius: 3px;
}

.background-focus-hover-btns-td .hover-btn {
  /* display: none; */
  visibility: hidden
}

.background-focus-hover-btns-td:hover .hover-btn,
.background-focus-hover-btns-td:focus-within .hover-btn,
.background-focus-hover-btns-td:focus .hover-btn {
  /* display: inline-block; */
  visibility: visible;

}

.table-selection .table>:not(caption) tr td {
  background-color: inherit !important;
}

.bg-white-select input {
  background: #fff;
}

.bg-white-select .select-row-revese .MuiInputBase-fullWidth {
  background-color: #fff;
}

.btn-list-type-ul .fa-angle-right {
  position: absolute;
  right: 10px;
  top: 10px;
}

.btn-list-ul-padding {
  padding: 0.5em 0.5em;
}

.btn-list-hr {
  border-color: #00000020;
}

.white-head-thead tr td input.form-control {
  background: #fff;
}

.add-plus-circle {
  width: 40px;
  height: 40px;
  background: #f8f9fa;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
}

.edit-footer-btns {
  position: absolute;
  bottom: 15px;
  right: 10px;
}

.autocomplete-listbox {
  font-size: 14px;
  color: #23252d;

}

.select-inp .MuiInputBase-formControl:hover {
  border-color: transparent !important;
  border: 2px solid #000 !important;
  border-width: 0px !important;
}

.select-inp .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding: 0px 5px !important;
}



/* .MuiAutocomplete-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #D3D4D6 !important;
  border: 1px solid #D3D4D6;
} */

/* .MuiOutlinedInput-notchedOutline {
  border-color: #D3D4D6 !important;
} */

/* .MuiOutlinedInput-notchedOutline:focus {
  box-shadow: 0 0 0 1px #3060f08e;
} */
.vouchers-table thead tr th {
  border-left: 0;
  /* border-right: 0; */
}

.vouchers-table thead tr th {
  border-top: 0;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  background: #f7f8fa;
  border-bottom: 1px solid #D3D4D6;
  border-right: 1px solid #D3D4D6;
}

.vouchers-table thead tr th:first-child {
  border-left: 0;
}

.vouchers-table thead tr th:last-child {
  border-right: 0;
}

.vouchers-table tbody tr td:first-child {
  border-left: 0;
}

.vouchers-table tbody tr td:last-child {
  border-right: 0;
}

.vouchers-table tbody tr td {
  border-color: #e8eaed;
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid #D3D4D6;
  border-right: 1px solid #D3D4D6;
}

.vouchers-table tbody tr.total-vch-data td {
  border-bottom: 0;
}

.table-vch-card {
  border-color: #D3D4D6;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_content.create-vch-header {
  padding-left: 0 !important;
}

#create-vch-header-logo .header-beside-logo {
  display: none;
}

.vouchers-table td .form-control {
  /* border: transparent; */
  background: transparent;
}

.vch-inputs .form-control {
  border-color: transparent;
}

.sidebar-none-prop {
  left: -20rem;
}

.sidebar-block-prop {
  transition: all 0.5s ease;
  left: 0;

}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ababab;
  opacity: 1;
  font-weight: 400;
  /* Firefox */
}

.colspan-text {
  text-align: center;
}

.ag-table-style.colspan-borders .ag-header-row:not(:first-child) .ag-header-cell {
  border-right: 1px solid #d3d4d6a8;
}

.ag-table-style.colspan-borders .ag-cell {
  border-right: 1px solid #d3d4d6a8;
}

.justify-content-col-span .ag-header-group-cell-label,
.justify-content-col-span .ag-header-cell-label {
  justify-content: center;
}

.justify-content-col-span-data .ag-header-group-cell-label,
.justify-content-col-span-data .ag-header-cell-label {
  justify-content: right;
}

.btn-outline-secondary-light {
  border-color: #D3D4D6;
}

.btn-outline-secondary-light:hover {
  border-color: #D3D4D6;
}

.jn-vouchers-table thead tr th {
  border-left: 0;
  /* border-right: 0; */
}

.jn-vouchers-table thead tr th {
  border-top: 0;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  background: #f7f8fa;
  border-bottom: 1px solid #D3D4D6;
  border-right: 1px solid #D3D4D6;
}

.jn-vouchers-table thead tr th:first-child {
  border-left: 0;
}

.jn-vouchers-table thead tr th:last-child {
  border-right: 0;
}

.jn-vouchers-table tbody tr td:first-child {
  border-left: 0;
}

.jn-vouchers-table tbody tr td:last-child {
  border-right: 0;
}

.jn-vouchers-table tbody tr:first-child td {
  border-top: 0;
}

.jn-vouchers-table tbody tr td {
  border-color: #e8eaed;
  font-size: 14px;
  padding: 2px;
  border-bottom: 0px solid #D3D4D6;
  border-top: 1px solid #D3D4D6;
  border-right: 1px solid #D3D4D6;
}

.jn-vouchers-table tbody tr.total-vch-data td {
  border-bottom: 0;
}

.jn-vouchers-table td .form-control {
  background: transparent;
  border: 1px solid #fff0 !important;
  border-radius: 1px;
}

.jn-vch-inputs .form-control {
  border-color: transparent !important;
}

.jn-vouchers-table td .form-control:focus-within {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.jn-total-padd td {
  padding: 10px !important;
}

.jn-add-padd td {
  padding: 5px !important;
}

.org-menus-list .list-group-item.active {
  background-color: #f7f8fa;
  color: #23252D;
  border-radius: 5px;
}

.p-confirm-dialog.block-confirm .p-dialog-content {
  display: block;
  overflow: hidden;
}

.p-confirm-dialog.block-confirm .p-confirm-dialog-icon {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}


.p-confirm-dialog.block-confirm .p-confirm-dialog-icon:before {
  border: 2px solid #0E9F6E;
  border-radius: 50px;
  padding: 10px;
  color: #0E9F6E;
}

.p-confirm-dialog.block-confirm .p-confirm-dialog-message {
  display: block;
  width: 100%;
  text-align: center;
  margin-left: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.p-confirm-dialog.block-confirm .p-confirm-dialog-accept {
  background: #305FF0;
  border: 1px solid #305FF0;
  padding: 0.375rem 0.75rem;
}

.p-confirm-dialog.block-confirm .p-confirm-dialog-reject {
  padding: 0.375rem 0.75rem;
}

.p-confirm-dialog.block-confirm .p-button-label {
  font-weight: 500;
}

.ag-grid-br-tree .ag-header-cell .ag-header-cell-text {
  font-weight: 500;
  font-size: 14px;
  color: #23252D;
}

.ag-grid-br-tree .ag-header {
  border-bottom: 1px solid #D3D4D6;
}

.ag-grid-br-tree .ag-row {
  border-bottom: 0;
}

.ag-grid-br-tree .ag-row-odd {
  background-color: #fff;
}

.ag-grid-br-tree .ag-root-wrapper {
  border: 1px solid #D3D4D6;
}

.white-select-inp .form-control {
  background: #fff;
}

.sales-h-select .form-control {
  height: 38px;
}

.sales-scroll-bar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.sales-scroll-bar::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}

.sales-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: transparent;
}

.sales-scroll-bar-copy::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.sales-scroll-bar-copy::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}

.sales-scroll-bar-copy::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: transparent;
}

.total-amount-sales {
  border: 1px solid #d4d5d7;
  padding: 10px 10px;
  border-radius: 5px;
}

.l-h-2 {
  line-height: 2;
}

.table-email {
  position: absolute;
  top: 12px;
}

.btn-cngh {
  position: absolute;
  top: 4px;
  right: 7px;
}

.branch-stepper .p-steps {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #f7f8fa;
  border-radius: 5px;
}

.branch-stepper .p-steps-item {
  justify-content: start;
}

.branch-stepper .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  font-size: 14px;
  color: #23252D;
}

.branch-stepper .p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #305FF0;
  color: #fff;
}

.branch-stepper .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  border-color: transparent;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.branch-stepper .p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
}

.branch-stepper .p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
}

.branch-stepper .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  font-size: 14px;
}

.branch-stepper .p-steps-item .p-menuitem-link {
  flex-direction: row;
  padding: 2px;
}

.branch-stepper .p-steps .p-steps-item:before {
  border-color: transparent;
}

.branch-stepper .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0;
  margin-left: 10px;
}

.branch-card-body {
  height: calc(100vh - 255px);
  overflow-y: auto;
  overflow-x: hidden;
}

.branch-card-body-vertical {
  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.additional-card {
  border: 1px solid #e8eaed;
}

.add-card-no-bg .list-group-item {
  background: transparent;
}

.bg-success-chip {
  line-height: 1;
  font-size: 12px;
  border: 1px solid #00be6680;
  background-color: #00be6680 !important;
  color: #23252D;
  width: 115px;
}

.bg-warning-chip {
  line-height: 1;
  font-size: 12px;
  border: 1px solid #f3893d54;
  background-color: #f3893d54 !important;
  color: #23252D;
  width: 115px;
}

.bg-yellow-chip {
  line-height: 1;
  font-size: 12px;
  border: 1px solid #b1be0080;
  background-color: #b1be0080 !important;
  color: #23252D;
  width: 115px;
}

.bg-grey-chip {
  line-height: 1;
  font-size: 12px;
  border: 1px solid #bababad2;
  background-color: #bababad2 !important;
  color: #23252D;
  width: 115px;
}


.bg-blue-chip {
  line-height: 1;
  font-size: 12px;
  border: 1px solid #0d88f480;
  background-color: #0d88f480 !important;
  color: #23252D;
  width: 115px;
}

.vertical-tabs .nav-link .step-number {
  color: #23252D;
  border-color: #e8eaeb;
  /* border: 1px solid #e8eaeb; */
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075) !important;
  background: #f7f8fa;
  min-width: 5rem;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  z-index: 1;
  border-radius: 25px;
  margin-right: 20px;
}

.vertical-tabs .nav-link:after {
  content: "";
  background-color: #e8eaed;
  position: absolute;
  width: 1px;
  height: 100px;
  top: 10px;
  left: 40px;
  display: block;
}

.vertical-tabs .nav-link:last-child:after {
  content: "";
  background-color: transparent;
  position: absolute;
  width: 2px;
  height: 100px;
  top: 10px;
  left: 16px;
  display: block;
}

.vertical-tabs .nav-link.active .step-number {
  background: #305FF0;
  border: 1px solid #305FF0;
  color: #fff;
}

.vertical-tabs .nav-pills .nav-link {
  margin-bottom: 15px;
  position: relative;
  color: #23252D;
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
}

.vertical-tabs .nav-pills .nav-link.active,
.vertical-tabs .nav-pills .show>.nav-link {
  background-color: transparent;
}

.b-r-line {
  border-right: 1px solid #e8eaed
}

.inside-cb-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.inside-cb-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}

.inside-cb-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: #C8C9CB;
}

.total-amt-sales {
  background: #f3deb0;
  padding: 10px;
  border-radius: 3px;
}

.analysis-inside-table tr td:first-child {
  border-left: 0;
}

.analysis-inside-table tr td {
  background: #f7f8fa82;
  border: 0;
}

.analysis-inside-table tr:first-child td {
  border-top: 0;
}

.analysis-inside-table tr:last-child td {
  border-bottom: 0;
}

.analysis-inside-table tr td:last-child {
  border-right: 0;
}

.f-g-1 {
  flex-grow: 1;
}

.tree-data-border .ag-header-cell,
.tree-data-border .ag-header-group-cell {
  border-left: 1px solid #d3d4d6;
}

.tree-data-border .ag-header-cell:first-child,
.tree-data-border .ag-header-group-cell:first-child {
  border-left: 0px solid #d3d4d6;
}

.tree-data-border .ag-ltr .ag-cell {
  border-left: 1px solid #d3d4d6;
  text-align: right;
  border-bottom: 1px solid #d3d4d6;
}

.tree-data-border .ag-ltr .ag-cell:first-child {
  border-left: 0px solid #d3d4d6;
}

.tree-data-border .ag-root-wrapper {
  border-left: 0;
  border-right: 0;
}

.border-less-ag-grid .ag-column-drop.ag-column-drop-horizontal {
  display: none;
}

.no-drag .ag-column-drop.ag-column-drop-horizontal {
  display: none;
}

.border-less-ag-grid .ag-root-wrapper {
  border-left: 0;
  border-right: 0;
}

.border-less-ag-grid .ag-ltr .ag-cell {
  border-bottom: 1px solid #d3d4d6;
}

.vertical-tabs-list .nav-link .step-number i {
  color: #23252D;
  min-width: 5rem;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  z-index: 1;
  border-radius: 25px;
}

.vertical-tabs-list .nav-link:after {
  content: "";
  background-color: #e8eaed;
  position: absolute;
  width: 1px;
  height: 100px;
  top: 10px;
  left: 40px;
  display: block;
}

.vertical-tabs-list .nav-link:last-child:after {
  content: "";
  background-color: transparent;
  position: absolute;
  width: 2px;
  height: 100px;
  top: 10px;
  left: 16px;
  display: block;
}


.vertical-tabs-list .nav-pills .nav-link {
  position: relative;
  color: #23252D;
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
  padding: 15px;
  border-bottom: 1px solid #e8eaed;
  border-radius: 0;
}

.vertical-tabs-list.vertical-tab-temp .nav-pills .nav-link {

  padding: 10px;
  border-bottom: 0;
}


.vertical-tabs-list .nav-pills .nav-link.active,
.vertical-tabs-list .nav-pills .show>.nav-link {
  background-color: #f7f8fa;
  color: #305FF0;
}

.vertical-tabs-list .nav-link.active .step-number i {
  color: #305FF0;
}

.vertical-tabs-list .nav-link:after {
  content: "";
  background-color: transparent;
  position: absolute;
  width: 1px;
  height: 100px;
  top: 10px;
  left: 40px;
  display: block;
}

.upload-btn-prop {
  position: relative;
  overflow: hidden;
}

.upload-btn-prop input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.btn-outline-dash {
  border: 1px dashed #305FF0
}

.p-colorpicker-preview {
  border-radius: 3px;
}

.print-item-table tr th {
  font-size: 12px;
  font-weight: 500;
  color: #23252D;
  border-top: 1px solid #e8eaed;
  border-bottom: 1px solid #e8eaed;
}

.annex-table tr th {
  font-size: 12px;
  font-weight: 500;
  color: #23252D;
  border-top: 1px solid #e8eaed;
  border-bottom: 1px solid #e8eaed;
}

.annex-table tr td {
  font-size: 12px;
}

.b-t-l-25 {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.b-t-r-25 {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.template-card-color {
  border-color: #e8eaed;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-radius: 0;
}

.totals-inv-gst tr th {
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #d3d4d6a8;
  font-size: 12px;
  background-color: #fed8b140;
}

.totals-inv-gst tr td {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #d3d4d6a8;
}

.f-8 {
  font-size: 8px;
}

.books-text {
  color: #305FF0;
  font-weight: 500;

}

.twoa-text {
  color: #1bcfb4;
  font-weight: 500;
}

.diff-text {
  color: #fe7c96;
  font-weight: 500;
}

.ag-details-hide .ag-details-row {
  padding: 0;
}

.ag-details-hide .ag-details-row .ag-header {
  display: none;
}

/* .ag-theme-alpine.recontable .ag-row:nth-child(3n+3) .ag-cell {
  background-color: #fed8b140;
  font-weight: 600;

}

.ag-theme-alpine.recontable .ag-row.first-row .ag-cell:first-child {
  color: #305FF0;
  font-weight: 600;

}

.ag-theme-alpine.recontable .ag-row.second-row .ag-cell:first-child {
  color: #305FF0;
  font-weight: 600;

} */

.comparison-td td {
  font-size: 14px;
}

.comparison-td td.bg-red-color {
  background-color: #fed8b140 !important;
}

.ag-theme-alpine .ag-row.diff-row {
  background-color: #fed8b140;
}

.recon-Difference {
  color: #fe7c96 !important;
}

.recon-Asper2A {
  color: #1bcfb4;
}

.recon-AsperBooks {
  color: #305FF0;
}

/* .ag-theme-alpine.recontable .ag-row:nth-child(even) .ag-cell:first-child {
  color: red;

}

.ag-theme-alpine.recontable .ag-row:nth-child(odd) .ag-cell:first-child {
  color: blue;

} */
.diff-icon-arrow {
  position: absolute;
  margin-left: -16px;
  margin-top: 2px;
}

.user-info-email {
  display: inline-flex;
  justify-content: space-between;
  border: 1px solid #EEEEEE;
  margin-bottom: 20px;
  border-radius: 7px;
  cursor: pointer;
  box-sizing: border-box;
  max-width: 100%;
  flex-wrap: nowrap;
}

.menu-text-email {

  display: inline-block;
  font-size: 16px;
  padding: 12px 14px;
  line-height: 20px;
  width: auto;
  word-break: break-all;

}

.menu-change {
  position: relative;
  float: right;
  font-size: 14px;
  padding: 0px 14px 0px 0px;
  display: inline-block;
  color: #0091FF;
  line-height: 38px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-mt-24 {
  margin-top: 18px;
}

.audit-icon {
  width: 50px;
  height: 50px;
  background: #e8eaed;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
}


.a-icon-1 {
  background: #FFA07A3b;
}

.a-icon-1-grey {
  background: #f7f8fa;
}

.a-icon-2 {
  background: #B256563b;
}

.a-icon-3 {
  background: #FFA5003b;
}

.a-icon-4 {
  background: #305FF03b;
}

.audit-card {
  border: 1px solid #e8eaed;
}

.audit-card .card-footer {
  border-color: #e8eaed;
}

.bg-white-border {
  border-color: #c6c7c8;
}

.min-width-18rem {
  min-width: 18rem;
}

.dropdown-click-menus {
  border: 1px solid #d3d4d6a8;
  box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07), 0 10px 20px -2px rgba(0, 0, 0, 0.04) !important;
}

.dropdown-click-menus li hr {
  border-color: #d3d4d6a8;
}

.dropdown-click-menus li.features-item {
  margin-bottom: 0;
  padding: 0.35rem 0.9rem;
}

.dropdown-click-menus li.features-item .features-item-text {
  margin-left: 0;
}


.min-width-18rem.dropdown-click-menus .hover-level {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07), 0 10px 20px -2px rgba(0, 0, 0, 0.04) !important;
  margin-left: -5px;
}

.min-width-18rem.dropdown-click-menus .hover-level .list-group-flush.sidebar-menu-ul>.list-group-item a {
  border-radius: 0;
  padding: 0.35rem 0.75rem;
}

.hover-level .list-group-flush .list-group-item {
  background-color: transparent;
  padding: 0 !important;
}

.single-level a {
  margin-bottom: 0;
  padding: 0.35rem 0.9rem;
  border-radius: 0 !important;
  width: 100%;
}

.single-level li {
  padding: 0 !important;
}

.sidebar-scrolling-height {
  height: 100%;
  overflow-y: auto;
}

.bg-red-clr-table td.bg-red-color-bg {
  background-color: #fed8b140 !important;
}

.bg-red-clr-table td.bg-white {
  background-color: #fff !important;
}

.last-td-record tbody tr:last-child td {
  border-bottom: 1px solid #d3d4d6;
}

.p-18-padding {
  padding: 15px;
}

.p-l-8 {
  padding-left: 8px;
}

.p-r-8 {
  padding-right: 8px;
}

.p-b-8 {
  padding-bottom: 8px;
}

.p-t-8 {
  padding-top: 8px;
}

.voucher-type-ps {
  position: absolute;
  right: 18px;
  top: 20px;
  display: none;
}

.bg-primary-tp {
  background-color: #e5e5e5a3;
  color: #23252D !important;
}

.p-b-r-bottom {
  border-bottom: 1px solid #d3d4d6;
}

.header-main-icn {
  background: #f7f8fa;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.p-3-sidenav .dropdown-menu.show {
  position: fixed !important;
}

.sidebar-scrolling-height::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.sidebar-scrolling-height::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}

.sidebar-scrolling-height::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: #C8C9CB;
}

.sidebar-minimized .side-bar.collapse .dropdown-menu.show .features-item-text {
  width: inherit;
  display: inherit;
  margin-left: inherit;
  opacity: 1;
  pointer-events: inherit;
}

.org-settings-dlg .p-dialog-content {
  border-radius: 15px;
}

.org-settings-dlg .p-dialog-content:last-of-type {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.org-settings-dlg {
  margin-top: 70px !important;
}

.org-settings-dlg .p-dialog-header {
  position: absolute;
  right: 5%;
  top: 5%;
  border: 0;
  padding: 0;
  background: transparent;
  z-index: 999;
}

.org-settings-dlg .p-dialog-header .p-dialog-header-icon {
  border-radius: 5px;
  background: #f7f8fa;
}

.mailing-btn i {
  display: block;
  width: 40px;
  height: 40px;
  background: #f8f9fa;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
}

.marked-label {
  position: absolute;
  top: 15px;
  right: 15px;
}

.template-footer .card-footer {

  display: none;
}

.template-footer:hover .card-footer {

  display: block;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 9999;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.container-p-1 {
  height: 200px;
  position: relative;
  border: 1px dashed #d3d4d6;
}

.vertical-center-p-1 {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.triangle-bottom {

  border-bottom: 45px solid #8fbf54;
  border-right: 45px solid transparent;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 0.375rem;

}

.triangle-bottom i {
  position: absolute;
  bottom: -44px;
  left: 5px;
}

.profit-loss thead th {
  font-size: 14px;
  font-weight: 500;
  padding: 15px 15px;
  border: 1px solid #d3d4d6a8;
  border-left: 0;
  border-right: 0;
  /* border-bottom: ;
  border-top: 0; */
}

.profit-loss tbody td {
  font-size: 14px;
  padding: 10px 15px;
}

.profit-loss .total-border tr td {
  border: 1px solid #d3d4d6a8;
  border-left: 0;
  /* border-bottom: 0; */
  border-right: 0;
}

.profit-loss tbody tr:hover td {
  background-color: #cff4fc;
}

.profit-loss tfoot tr:hover td {
  background-color: #cff4fc;
}

.table-12-font-size tr th {
  font-size: 12px !important;
}

.table-12-font-size tr td {
  font-size: 12px !important;
}

.op-bal-bg {
  background-color: #fed8b140 !important;
}

.top-border-ag .ag-root-wrapper {
  border-top: 1px solid #d3d4d6;
}

.grid-properties {
  background: #fff;
  min-height: 90vh;
  background-color: #fff;
}

.react-grid-item:not(.react-grid-placeholder) {
  background: #f7f8fa;
  border: 1px solid #e8eaed;
  border-radius: 5px;
}

.react-grid-layout {
  transform: translate(1px, 2px);
}

/* .remove-button {
  display: none;
  position: absolute;
  right: 10px;
  top: -10px;
  background: none;
  border: none;
} */

/* .setting-button {
  display: none;
  position: absolute;
  right: 25px;
  top: -10px;
  background: none;
  border: none;
} */

.settings-close-btn {
  display: none;
}

.react-grid-item:hover .settings-close-btn {
  display: block;
}

/* .react-grid-item:hover .setting-button {
  display: block;
} */

.password-strength-group .password-strength-meter {
  width: 100%;
  transition: height 0.3s;
  display: flex;
  justify-content: stretch;
}

.password-strength-group .password-strength-meter .meter-block {
  height: 6px;
  background: #ccc;
  margin-right: 6px;
  border-radius: 5px;
  flex-grow: 1;
}

.password-strength-group .password-strength-meter .meter-block:last-child {
  margin: 0;
}

.password-strength-group .password-strength-message {
  font-weight: 20px;
  height: 1em;
  text-align: right;
  transition: all 0.5s;
  margin-top: 3px;
  position: relative;
}

.password-strength-group .password-strength-message .message-item {
  font-size: 12px;
  position: absolute;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s;
}

.password-strength-group[data-strength="1"] .meter-block:nth-child(-n+1) {
  background: #FF9966;
}

.password-strength-group[data-strength="1"] .message-item:nth-child(1) {
  opacity: 1;
}

.password-strength-group[data-strength="2"] .meter-block:nth-child(-n+2) {
  background: #ffc43b;
}

.password-strength-group[data-strength="2"] .message-item:nth-child(2) {
  opacity: 1;
}

.password-strength-group[data-strength="3"] .meter-block:nth-child(-n+3) {
  background: #9ea60a;
}

.password-strength-group[data-strength="3"] .message-item:nth-child(3) {
  opacity: 1;
}

.password-strength-group[data-strength="4"] .meter-block:nth-child(-n+4) {
  background: #289116;
}

.password-strength-group[data-strength="4"] .message-item:nth-child(4) {
  opacity: 1;
}

.text-success-strong {
  color: #9ea60a;
}

.text-danger-weak {
  color: #FF9966;
}

.criteria-icon {
  position: absolute;
  bottom: -1px;
}

.org-card-body-auth {
  /* max-height: 34vw; */
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
}

.w-10 {
  width: 10% !important;
}

.w-5 {
  width: 5% !important;
}

.or-rounded {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #d3d4d6;
  line-height: 40px;
  text-align: center;
}

.bottom-btn-parent {
  width: auto;
  height: 150px;
}

.bottom-btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: left;
}

.font-size-30 {
  font-size: 30px;
}

.op-img {
  opacity: 0.5;
}

.card-table thead tr:first-child th {
  border-top: 1px solid #d3d4d6 !important;
}

.accordion-heading-branch .p-accordion-header .p-accordion-header-link {
  font-weight: 600;
}

.accordion-heading-branch .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  font-size: 12px;
}

/* .accordion-heading-branch .p-accordion-header .p-accordion-header-link {
  margin-bottom: 14px;
} */

.accordion-heading-branch .p-accordion-tab {
  margin-bottom: 14px;
}

.accordion-heading-branch .p-accordion-header-text {
  font-weight: 600;
  font-size: 14px;
}

.fin-dp .dropdown-menu {
  max-height: 250px;
  background: #fff;
  overflow-y: auto;
}

.gst-dp .p-dropdown-trigger .p-dropdown-trigger-icon {
  display: none;
}

.gst-dp .p-dropdown {
  cursor: not-allowed;
  pointer-events: none;
}

.otp-inpt input {
  height: 45px;
  background: #eee;
  margin-left: 5px;
  border-bottom: 2px solid hsla(0, 0%, 87.1%, .93) !important;
}

.last-type-hr hr:last-child {
  border: 0;
}

.y-overflow .p-dialog-content {
  overflow-x: hidden;
}

.p-dialog .p-dialog-header-icons .fa-times {
  margin-top: 7px;
}

.collapse-expand-option {
  position: absolute;
  top: 16px;
  left: -4%;
}

.collapse-expand-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  line-height: 25px;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  background: #e8eaed;
}

.collapse-expand-btn:hover {
  background: #305FF0 !important;
  color: #fff;
}

.collapse-content {
  transition: transform 0.15s ease-in-out;
  z-index: 1038;

}

.collapse-content .collapse-content-hidden {
  display: none;
}

.additional-icn {
  display: none;
}

.collapse-content .create-collapse-content-hidden .addtional-long-text {
  display: none;
}

.collapse-content .create-collapse-content-hidden .additional-icn {
  display: block;
}

.collapse-content .create-collapse-content-hidden .add-long-text {
  display: none;
}

.collapse-content .create-collapse-content-hidden .create-vch-icn {
  display: block;
  margin-right: 15px;
  line-height: 22px;
  font-size: 16px;
  color: #6f7380;
}

.collapse-content .create-collapse-content-hidden .key-btn {
  font-size: 10px;
  /* display: none; */
}

.collapse-content .create-collapse-content-hidden .list-group-item .icn-center-position {
  justify-content: center !important;
}

.collapse-content .create-collapse-content-hidden .main-add-text {
  text-align: center;
  margin-left: -50px;
}

.collapse-content .collapse-expand-option {
  left: -12%;
}

/*--- toast ---*/

.Toastify__toast-container--bottom-left {
  bottom: 2rem;
  left: 2rem;
}

.Toastify__toast-container--top-right {
  bottom: 2rem;
  left: 2rem;
  top: inherit;
  right: inherit;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #fff;
  color: #515561;
  font-size: 14px;
  font-weight: 500;
}


.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #fff;
  color: #515561;
  font-size: 14px;
  font-weight: 500;
}


.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #fff;
  color: #515561;
  font-size: 14px;
  font-weight: 500;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #fff;
  color: #515561;
  font-size: 14px;
  font-weight: 500;
}

.Toastify__toast-theme--colored.Toastify__toast--success .Toastify__zoom-enter {
  color: #629426;
  width: 18px;
}

.Toastify__toast-theme--colored.Toastify__toast--info .Toastify__zoom-enter {
  color: #305FF0;
  width: 18px;
}

.Toastify__toast-theme--colored.Toastify__toast--error .Toastify__zoom-enter {
  color: #f84e4e;
  width: 18px;
}

.Toastify__toast-theme--colored.Toastify__toast--warning .Toastify__zoom-enter {
  color: #d1a800;
  width: 16px;
}

.Toastify__close-button {
  color: #6f7380;
}

/* .Toastify__progress-bar {
  display: none;
} */

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info {
  /* background-color: #305FF0; */
  background-color: #e8eaed;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  /* background-color: #629426; */
  background-color: #e8eaed;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
  /* background-color: #d1a800; */
  background-color: #e8eaed;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  /* background-color: #f84e4e; */
  background-color: #e8eaed;
}

.Toastify__progress-bar--error {
  background-color: #e8eaed;
}

.Toastify__progress-bar--success {
  background-color: #e8eaed;
}

.Toastify__progress-bar--warning {
  background-color: #e8eaed;
}

.Toastify__progress-bar--info {
  background-color: #e8eaed;
}

.Toastify__toast {
  border: 1px solid #ededed;
  box-shadow: -1px 7px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);

}

.fields-collapse-btn {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
}

.fields-collapse-btn i {
  font-size: 12px;
}

.card-e8-color {
  border-color: #e8eaed;
}

.font-size-8 {
  font-size: 8px;
}

.c-header-b {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.fin-acc .p-accordion .p-accordion-header .p-accordion-header-link {
  font-size: 14px;
  color: #23252D !important;
  background: #f7f8fa !important;
  border-color: #e8eaed !important;
  padding: 15px;
}

.fin-acc .p-accordion .p-accordion-content {
  border-color: #e8eaed !important;
  padding: 15px;
}

.fin-acc .p-accordion .p-accordion-tab {
  margin-bottom: 10px;
}

.fin-acc .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  font-size: 12px;
}

.def-label {
  position: absolute;
  right: 15px;
  top: 5px;
}

.p-15-total {
  padding: 15px;
}

.p-15-l {
  padding-left: 15px;
}

.p-15-r {
  padding-right: 15px;
}



.table-td-elipsis {
  max-width: 150px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.table-td-elipsis-lg {
  max-width: 350px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.react-table-elipsis {
  width: 12rem;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.table-status-cell {
  width: 22rem;
}

.table-receiver-name {
  width: 4rem;
  color: red;
}

.eway-preview .p-dialog-content {
  padding: 0;
  overflow-x: hidden;
}



.igst-table-secondary thead th {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 12px;
  border: 1px solid #D3D4D6;
  border-bottom: 0;
  background: #f9f9f9;
}

.igst-table-secondary tbody td {
  border: 1px solid #D3D4D6;
  padding: 10px 12px;
  font-size: 14px;
}

.preview-eway-table thead th {
  font-size: 12px;
}

.preview-eway-table tbody td {
  font-size: 12px;
  font-weight: 600;
  color: #515561;
}

.preview-eway-table tfoot tr td {
  font-size: 12px;
  font-weight: 600;
  color: #515561;
  border: 1px solid #D3D4D6;
  border-top: 0;
}

.figma-bg-eway {
  background: #f9f9f9 !important;
}

.border-radius-5px {
  border-radius: 5px;
}

.h-70px {
  height: 50px;
  font-size: 24px;
}

.select-inp.select-units .MuiInputBase-fullWidth {
  height: 40px;
}

.select-inp.select-units .MuiChip-deletable.MuiChip-deletableColorDefault {
  font-size: 14px;
}

.nexted-inside-table thead tr th {
  padding: 5px;
  font-size: 12px;
}

.nexted-inside-table tbody tr td {
  padding: 5px;
  font-size: 12px;
}

.pull-end-btn {
  position: absolute;
  right: 17px;
}

.def-imgs {
  position: absolute;
  top: 10px;
  left: 10px;
}

.den-num-btn {
  border-color: #D3D4D6;
  background: #e8eaed;
  background-color: #D3D4D6;
}

.den-num-btn:hover {
  background: #D3D4D6;
  background-color: #D3D4D6;
  color: #23252D;
}

.from-dt input {
  height: 38px;
  border-radius: 5px;
  border: 1px solid #d3d4d6;
  width: 180px !important;
  box-shadow: none;
  font-size: 14px;
}

.to-dt input {
  height: 38px;
  border-radius: 5px;
  border: 1px solid #d3d4d6;
  margin-left: -1px;
  width: 180px !important;
  box-shadow: none;
  font-size: 14px;
}

.p-datepicker.dt-drp .p-monthpicker .p-monthpicker-month {
  font-size: 14px;
}

.p-datepicker.dt-drp .p-datepicker-title select {
  border: 1px solid #d3d4d6;
  border-radius: 5px;
  font-size: 14px;
}

.p-datepicker.dt-drp .p-datepicker-header .pi-chevron-left {
  font-size: 12px;
}

.p-datepicker.dt-drp .p-datepicker-header .pi-chevron-right {
  font-size: 12px;
}


.to-text-between {
  border-top: 1px solid #d3d4d6;
  height: 38px;
  border-bottom: 1px solid #d3d4d6;
  line-height: 36px;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
}

.from-dt i,
.to-dt i {
  position: absolute;
  right: 10px;
  top: 9px;
  color: #d1d1d1;
}

.p-tabview.org-tabs-view .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #305FF0;
  color: #fff;
  border-color: #305FF0;
}

.p-tabview.org-tabs-view .p-tabview-nav li .p-tabview-nav-link {
  font-size: 14px;
  font-weight: 500;
  padding: 0.5rem;
  margin: 0;
  background: #f7f8fa;
  box-shadow: none;
  border: 1px solid #d3d4d6;
  color: #6f7380;
  margin-right: 15px;
  border-radius: 3px;
}

.p-tabview.org-tabs-view .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview.org-tabs-view .p-tabview-nav {

  border: 1px solid #e8eaed;
  border-width: 0 0 0px 0;
  padding-left: 0px;
}

.p-tabview.org-tabs-view .p-tabview-nav li.p-highlight .p-tabview-nav-link span {
  position: relative;
}

.p-tabview.org-tabs-view .p-tabview-nav .p-tabview-ink-bar {
  background-color: transparent;
}

.p-tabview.org-tabs-view .p-tabview-panels {
  padding: 0;
}

.read-table thead tr th {
  padding: 7px 10px;
  font-size: 12px;
  font-weight: 600;
}

.read-table tbody tr td {
  padding: 7px 10px;
  font-size: 12px;
}

.read-table-vch thead tr th {
  padding: 7px 10px;
  font-size: 12px;
  font-weight: 500;
}


.read-table-vch tbody tr td {
  padding: 7px 10px;
  font-size: 12px;
}

.checkbox-disabled {
  cursor: not-allowed;
}

.checkbox-disabled .p-checkbox {
  cursor: not-allowed;
}

.checkbox-disabled label {
  color: #d3d4d6;
  cursor: not-allowed;
  pointer-events: none;
}

.table-left-right-0 tr th:first-child {
  border-left: 0;
}

.table-left-right-0 tr th:last-child {
  border-right: 0;
}

.table-left-right-0 tr td:first-child {
  border-left: 0;
}

.table-left-right-0 tr td:last-child {
  border-right: 0;
}

.table-left-right-0 tr th {
  border-left: 0;
  border-right: 0;
  padding: 14px;
  padding-left: 20px;
  border-color: #e8eaed;
}

.table-left-right-0 tr td {
  border-color: #e8eaed;
}

.pl-6-padding {
  padding-left: 4rem !important;
}

.nested-table-three tr td {
  border-left: 0;
  border-right: 0;
  border-color: #e8eaed;
}

.nested-table-two tr td {
  border-left: 0;
  border-right: 0;
  vertical-align: middle;
  border-color: #e8eaed;
}

.nested-table-three tr td:first-child,
.nested-table-two tr td:first-child {
  font-size: 12px !important;
}

.cap-btn {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  color: #305FF0 !important;
}

.cap-btn:hover {
  color: #fff !important;
}

.show-eye-pwd {
  position: absolute;
  right: 0;
  top: 1px;
}

.create-vch-icn {
  display: none;
}

.add-icons-size .create-icn i {
  font-size: 14px;
  margin-right: 5px;
}

.key-btn {

  line-height: 18px;
  line-height: 12px;
  background: #F3F4F6;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  padding: 4px 8px;
  color: #1F2A37;
  font-size: 12px;
  font-weight: 500;
}

.thin-tooltip .p-tooltip-text {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.create-vch-sel .select-row-revese.form-control {
  width: 200px;
  background: #fff;
  border: 1px solid #f5f6f8;
}

.create-vch-sel .select-row-revese .form-control {
  width: 200px;
  background: #f5f6f8;

}

.create-vch-sel .MuiChip-deletable.MuiChip-deletableColorDefault {
  border-radius: 3px !important;
}

.figma-bg-ef {
  background-color: #efefef;
}

.sales-scroll-bar-straight::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.sales-scroll-bar-straight::-webkit-scrollbar-track {
  background-color: #e8eaed;
  border-radius: 0px;
}

.sales-scroll-bar-straight::-webkit-scrollbar-thumb {
  border-radius: 0px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: #d3d4d6;
}

.p-datatable-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

.p-datatable-wrapper::-webkit-scrollbar-track {
  background-color: #e8eaed;
  border-radius: 0px;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb {
  border-radius: 0px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: #d3d4d6;
}

.acc-vch-bt {
  border-color: #e8eaed;
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding-left: 0;

}

.icn-spe {
  border-left: 1px solid #d3ded6;
}

.acc-vch-bt:hover {
  background: #e8eaed;
}

.custom-loader {
  background: #ffffffb5;
  height: 85vh;
  position: absolute;
  width: 100%;
  z-index: 1;
  border-radius: 16px;
}

.react-table-loader {
  height: 30vh;
}

.itc-recon-table-loader {
  position: relative !important;
  height: 50vh !important;
}

.popup-loader {
  height: 50vh;
}

.loader {
  top: 50% !important;
  border-radius: 5px;
}

.grid-loader {
  position: absolute !important;
  top: 35% !important;
  left: 50% !important;
}

.text-disabled {
  color: #b3b7bd;
}

.text-disabled:focus {
  color: #b3b7bd;
}

.text-disabled:hover {
  color: #b3b7bd;
}

.bg-error-chip {
  line-height: 1;
  font-size: 12px;
  border: 1px solid #d2000054;
  background-color: #d2000054 !important;
  color: #23252D;
  width: 115px;
}

.vch-table-2-padding thead tr th {
  padding-top: 2px;
  padding-bottom: 2px;
}

.table-selection tr td.figma-bg {
  background-color: #F7F8FA !important;
}

tr.total-vch-data {
  background-color: #F7F8FA !important;
}

.left-main-padding {
  padding-bottom: 10px;
}

.right-sub-padding {
  padding-bottom: 5px;
}

.left-side-menus i {
  position: absolute;
  right: 10px;
  top: 12px;
}

.finsight-toggle .p-inputswitch.p-disabled {
  cursor: no-drop;
  pointer-events: none;
}

.finsight-toggle .p-inputswitch.p-disabled .p-inputswitch-slider:before {
  background: #e8eaeb;
}

.acc-stock-icon i {
  position: absolute;
  right: 10px;
  top: 10px;
}

.ag-cell-expandable .ag-group-value {
  font-weight: 600;
}

/* .ag-row-group-expanded {
  background-color: #fbfbfb82;
} */
/* .ag-table-style .ag-row-group-expanded.ag-row-odd {
  background-color: #fbfbfb82 !important;
} */
.ag-table-style.colspan-borders.last-right-remove .ag-header-row .ag-header-cell:first-child {
  border-right: 0 !important;
}

.ag-table-style.colspan-borders.last-right-remove .ag-cell:last-child {
  border-right: 0;
}

.select-40-inp .select-row-revese .MuiInputBase-fullWidth {
  height: 40px;
}

.balance-sheet-table thead tr th {
  background-color: #f7f8fa;
  font-weight: 600;
}

.total-figma td {
  background: #f7f8fa;
}

.balance-sheet-table-summary tr th {
  border-right: 0 !important;
}

.min-h-h-100 {
  min-height: 100vh;
  height: 100%;
}

.button-in {
  width: 300px;
  position: relative;
  float: right;
}

.button-in .form-control {
  margin: 0px;
  width: 100%;
  outline: none;
  border-radius: 5px;
}

.button-in button {
  position: absolute;
  top: 3px;
  right: 5px;
  z-index: 2;
  border: none;
  top: 3px;
  cursor: pointer;
  transform: translateX(2px);
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.bulk-shadow {
  border-left: 5px solid #305FF075;
  border-radius: 7px;
}

.bulk-shadow-current {
  border-left: 5px solid #0E9F6E99;
  border-radius: 7px;
}

.eb-bg {
  background: #EBF0FF;
}

.bulk-vch-thead thead tr th {
  background-color: #fff;
  font-weight: 600;
  color: #6f7380;
}

.s-inp-h-40 {
  height: 40px;
  border-radius: 5px;
}

.vertical-tabs-list-dashboard .nav-link .step-number i {
  color: #23252D;
  min-width: 5rem;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  z-index: 1;
  border-radius: 25px;
}

.vertical-tabs-list-dashboard .nav-link:after {
  content: "";
  background-color: #e8eaed;
  position: absolute;
  width: 1px;
  height: 100px;
  top: 10px;
  left: 40px;
  display: block;
}

.vertical-tabs-list-dashboard .nav-link:last-child:after {
  content: "";
  background-color: transparent;
  position: absolute;
  width: 2px;
  height: 100px;
  top: 10px;
  left: 16px;
  display: block;
}

.vertical-tabs-list-dashboard .nav-pills .nav-link {
  position: relative;
  color: #23252D;
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
  padding: 15px;
  border-bottom: 1px solid #e8eaed;
  border-radius: 0;
}

.vertical-tabs-list-dashboard.vertical-tab-temp .nav-pills .nav-link {
  padding: 10px;
  border-bottom: 0;
}

.vertical-tabs-list-dashboard .nav-pills .nav-link.active,
.vertical-tabs-list-dashboard .nav-pills .show>.nav-link {
  background-color: #305FF0;
  color: #fff;
}

.vertical-tabs-list-dashboard.vertical-tabs-sub .nav-pills .nav-link.active,
.vertical-tabs-list-dashboard.vertical-tabs-sub .nav-pills .show>.nav-link {
  background-color: #f7f8fa;
  color: #305FF0;
}

.vertical-tabs-list-dashboard .nav-link.active .step-number i {
  color: #305FF0;
}

.vertical-tabs-list-dashboard .nav-link:after {
  content: "";
  background-color: transparent;
  position: absolute;
  width: 1px;
  height: 100px;
  top: 10px;
  left: 40px;
  display: block;
}

.vsz-dlg .p-dialog-content {
  background: #f7f8fa;
}

.icon-check {
  position: absolute;
  top: 0;
  right: 0;
}

.icon-check .btn {
  color: #e8eaeb !important;
  font-size: 18px !important;
}

.p-tabview.org-tabs-lines .p-tabview-nav li .p-tabview-nav-link {
  font-size: 14px;
  font-weight: 500;
  padding: 0.75rem;
  padding-right: 24px;
  margin-top: -1px;
  background: transparent;
  box-shadow: none;
}

.p-tabview.org-tabs-lines .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview.org-tabs-lines .p-tabview-nav {
  border: 1px solid #e8eaed;
  background: transparent;
  border-width: 0 0 1px 0;
  padding-left: 5px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: transparent;
  border-width: 0;
}

.p-tabview.org-tabs-lines .p-tabview-nav li.p-highlight .p-tabview-nav-link span {
  position: relative;
}

.p-tabview.org-tabs-lines .p-tabview-panels {
  background-color: transparent;
}

.p-tabview.org-tabs-lines .p-tabview-nav li.p-highlight .p-tabview-nav-link span:after {
  position: absolute;
  content: "";
  bottom: -86%;
  left: 0px;
  width: 100%;
  height: 3px;
  background: #305FF0;
  transform: scaleX(1);
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.p-tabview-selected {
  border-width: 10px;
}

.p-tabview.org-tabs-lines .p-tabview-nav .p-tabview-ink-bar {
  background-color: transparent;
}

.p-tabview.org-tabs-lines .p-tabview-panels {
  padding: 0;
}

.bg-outline-success {
  border: 1px solid #198754;
  color: #198754;
}

.bg-outline-secondary {
  border: 1px solid #6c757d;
  color: #6c757d;
}

.view-side-menu .list-group-item {
  background: #f7f8fa;
}

.view-side-menu .list-group-item:hover {
  background: #fff;
}

.visualize-btns .btn {
  border-color: #E2E3E4;
}

.chart-container::-webkit-scrollbar {
  display: none;
}

.text-underline {
  text-decoration: underline;
}

.btn-vertical button {
  border-radius: 0px;
  border: 0;
  padding: 10px;
  padding-left: 24px;
  padding-right: 24px;
  border-left: 3px solid transparent !important;
}

.btn-vertical button.active {
  background-color: #fff;
  border-color: transparent;
  color: #23252d;
  border-left: 3px solid #305FF0 !important;
}

.btn-vertical button:hover {
  background-color: #fff;
  border-color: transparent;
  color: #23252d;
}

.btn-vertical button i {
  position: absolute;
  right: 24px;
  top: 15px;
}

.ledgers-list-ul {
  border: 0;
  border-radius: 0;
}

.ledgers-list-ul li {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-color: #d3d4d654;
  border-left: 4px solid #EBF0FF;
}

.ledgers-list-ul li.active {
  background-color: #F7F8FA;
  border-left: 4px solid #305ff070;
  border-bottom-color: #d3d4d654;
}

.ledgers-list-ul li:hover {
  background-color: #F7F8FA;
  cursor: pointer;
  border-left: 4px solid #e8eaeb;
}

.bg-primary-label {
  background: #ebf0ff;
  border: 1px solid #A2B9FF;
  color: #305FF0;
  padding: 2px 8px;
  border-radius: 15px;

}

.bg-figma-label {
  background: #ebf0ff;
  border: 1px solid #A2B9FF;
  color: #305FF0;
  padding: 2px 8px;
  border-radius: 15px;
}



.bg-success-label {
  border: 1px solid #ACE8D3;
  background: #F3FAF7;
  color: #0E9F6E;
  padding: 2px 8px;
  border-radius: 15px;
}

.bg-active-label {
  color: #19875499;

}

.bg-inactive-label {
  background: #f052529c;
  padding: 2px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 15px;
}

.scroll-height-list {
  height: 100vh;
  overflow-y: auto;
}

.scroll-height-list::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.scroll-height-list::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}

.scroll-height-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: transparent;
}

.org-card-body-dashboard {
  /* max-height: 34vw; */
  height: calc(100vh - 205px);
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard-sheets {
  border-radius: 0;
}

.dashboard-sheets li {
  padding: 0 !important;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
}

.dashboard-sheets li button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.sidebar ul.features-list li:hover {
  background: transparent;
}

.navigated {
  background-color: #F7F8FA;
  cursor: pointer;
  border-left: 4px solid #e8eaeb;
}

/*-- new select --*/

.main-select-readonly .form-control {
  background-color: #fff !important;
  border-color: #e8eaed;
  /* background-color: transparent !important;
  border-color: transparent; */
}

/* .main-select-readonly .select-row-revese .MuiInputBase-fullWidth {
  padding: 0 !important;
}

.select-inp.main-select-readonly .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding: 0 !important;
} */

.select-inp.main-select-readonly .MuiAutocomplete-tag {
  border: 0;
}

.select-inp.main-select-readonly.large-place-holder ::placeholder {
  color: #6F7380;
  opacity: 1;
  font-size: 14px;
}


.b-r-4 {
  border-radius: 4px;
}

.long-text-btn {
  border-color: #d3d4d6;
  padding: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.select-inp.main-select-readonly.large-place-holder .MuiChip-deletable.MuiChip-deletableColorDefault {
  background: transparent;
}

.select-inp.main-select-readonly.large-place-holder .MuiChip-label {
  font-size: 14px;
  padding: 6px;
}

.select-inp.main-select-readonly.large-place-holder .MuiChip-deleteIcon {
  display: none;
}

.p-12 {
  padding: 12px;
}


.select-items-list {
  border: 0;
  border-radius: 0;
}

.select-items-list li {
  border: 0;
  border-radius: 4px;
  padding: 8px 10px;
  margin-bottom: 8px;

}

.select-items-list li.active {
  background-color: #EBF0FF;
  border-bottom-color: #d3d4d654;
}

.select-items-list li:hover {
  background-color: #F7F8FA;
  cursor: pointer;
}

.item-badge-grey {
  background: #E2E3E4;
  padding: 4px 8px;
}

.items-new-table thead th {
  font-size: 12px;
  color: #515561;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.items-new-table thead tr th:first-child {
  border-top-left-radius: 5px;
}

.items-new-table thead tr th:last-child {
  border-top-right-radius: 5px;
}

.items-new-table tbody tr td {
  font-size: 14px;
  color: #23252D;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.items-new-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

.items-new-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.elipsis-two {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-size-6 {
  font-size: 6px;
}

.dr-bg {
  font-size: 12px;
  background: #305ff061;
  color: #23252d;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 600;
  border-radius: 3px;
}

.cr-bg {
  font-size: 12px;
  background: #e8eaeb;
  color: #23252d;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 600;
  border-radius: 3px;
}

.hover-btns-td .hover-btns {
  display: none;
}


.hover-btns-td:hover .hover-btns,
.hover-btns-td:focus .hover-btns {
  display: block;
  position: absolute;
  right: 2px;
  top: 6px;
}

.hover-btns-td:focus-within,
.hover-btns-td:focus-visible {
  /* box-shadow: inset 0 0 0 2px #3060f08e !important; */
  background-color: #EBF0FF !important;
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -1px;
  /* box-shadow: 0 0 0 2px #3060f08e !important;
  outline: 0; */
  border-radius: 3px;

}

.hover-btns-td-focus .hover-btns {
  display: none;
}


.hover-btns-td-focus:hover .hover-btns,
.hover-btns-td-focus:focus .hover-btns,
.hover-btns-td-focus:focus-within .hover-btns {
  display: block;
  /* position: absolute; */
  text-align: right;
  right: 2px;
  top: 0px;
}



.hover-btns-td-focus:focus,
.hover-btns-td-focus:focus {
  /* box-shadow: inset 0 0 0 2px #3060f08e !important; */
  background-color: #EBF0FF !important;
  outline-style: solid;
  outline-width: 3px;
  outline-color: #3060f08e;
  outline-offset: -2px;
  /* box-shadow: 0 0 0 2px #3060f08e !important;
  outline: 0; */
  border-radius: 3px;

}

.hover-btns-td-nhl .hover-btns {
  display: none;
}

.hover-btns-td-nhl:hover .hover-btns,
.hover-btns-td-nhl:focus .hover-btns,
.hover-btns-td-nhl:focus-within .hover-btns {
  display: block;
  position: absolute;
  right: 2px;
  top: 6px;
}

.joint-inp {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: transparent;
}

.kg-select .form-control {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.kg-select .select-row-revese .MuiInputBase-fullWidth {
  height: 34.4px;
  background: #ebf0ff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.kg-select .MuiAutocomplete-input {
  min-width: 5px !important;
}

.kg-select .MuiAutocomplete-tag {
  margin: -2px !important;
  margin-top: -2px !important;
  max-width: calc(80% - 4px) !important;
}

.unit-price .MuiAutocomplete-input {
  min-width: 10px !important;
}

.unit-price .MuiChip-label {
  font-size: 12px;
  color: #305FF0;
}

.kg-select .MuiChip-label {
  color: #305FF0;
  font-size: 12px;
  padding-top: 7px;
}

.unit-price .select-row-revese .MuiInputBase-fullWidth {
  background: #EBF0FF;
  border-color: transparent;
  border-radius: 8px;
}

.unit-price .form-control {
  border-color: #EBF0FF;
}

/* .finsights-data-table.last-line-record .p-datatable .p-datatable-tbody>tr:last-child>td {
  border-bottom: 0 !important;
} */

.dt-table-card {
  border-color: #d3d4d69c;
}

.finsights-data-table.col-span-table .p-datatable .p-datatable-tbody>tr>td {
  border-width: 0 1px 1px 0;
}

.finsights-data-table.col-span-table .p-datatable .p-datatable-tbody>tr>td:last-child {
  border-right: 0;
}

.finsights-data-table.col-span-table .p-datatable .p-datatable-thead>tr>th {
  border-width: 0 1px 1px 0;
}

.finsights-data-table.col-span-table .p-datatable .p-datatable-thead tr th:last-child {
  border-right: 0;
}

.finsights-data-table.col-span-table .p-datatable .p-datatable-thead tr:first-child th:last-child {
  border-right: 1px solid #d3d4d69c !important;
}


.select-25-inp .form-control {
  border-radius: 0.25rem !important;
}

.finsights-data-table.col-span-table .p-datatable .p-datatable-thead tr th.col-span-head .p-column-header-content {
  justify-content: center;
}

.finsights-data-table.col-span-table .p-datatable .p-datatable-thead tr th.col-span-head .p-column-header-content {
  justify-content: center;
}

.ledger-border-less thead tr th {
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  padding: 12px 12px;
}

.ledger-border-less tbody tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ledger-border-less tbody tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ledger-border-less tbody tr td {
  border-right: 0;
  border-left: 0;
  border-color: transparent;
  border-bottom: 0 !important;
  padding: 10px 12px;
}

/* .ledger-border-less tbody tr td:first-child {
  padding-left: 0;
} */

.ledger-border-less tbody tr:first-child td {
  border-top: 0;
}

.dr-cr-select .form-control {
  background: #EBF0FF;
  border-color: transparent;
}

/* .td-highlight tbody tr.selected-row td {
  background: #f7f8fa;
} */
.finsights-data-table.top-head-dt .p-datatable .p-datatable-thead>tr>th {
  border-top: 1px solid #d3d4d69c;
}

.hsn-name {
  border-radius: 4px;
  border: 1px solid #E5E7EB;
  background: #E2E3E4;
  font-size: 12px;
  color: #23252D;
  padding: 4px 8px;
  font-weight: 500;
}

.finsights-data-table.less-padding-dt-table .p-datatable .p-datatable-thead tr th {
  padding: 10px 5px;
}

/* .finsights-data-table .p-datatable .p-datatable-thead tr:nth-child(2) th {
  background: #fff;
} */

.finsights-data-table.less-padding-dt-table .p-datatable .p-datatable-tbody tr td {
  padding: 10px 5px !important;
  vertical-align: middle;
}

.finsights-data-table.exp-icn .p-datatable .p-datatable-tbody tr td .p-row-toggler.p-link span {
  font-size: 12px;
}

.finsights-data-table.exp-icn .p-datatable .p-datatable-tbody tr td .p-row-toggler.p-link {
  width: 1.5rem;
  height: 1.5rem;
}

.finsights-data-table.btn-inside-table .p-datatable .p-datatable-tbody>tr>td {
  padding: 8px 24px;

}

.finsights-data-table.exp-icn .p-datatable .p-datatable-tbody tr.p-datatable-row-expansion td {
  padding: 10px !important;
}


.finsights-data-table.last-line-remove .p-datatable .p-datatable-tbody>tr:last-child>td {
  border-bottom: 0;
}

.finsights-data-table.expand-table-inside .p-datatable .p-datatable-tbody tr td {
  background: #F7F8FA;
}

.finsights-data-table.expand-table-inside .p-datatable .p-datatable-thead tr th {
  font-size: 10px;
}

.finsights-data-table.expand-table-inside .p-datatable .p-datatable-tbody tr td {
  font-size: 12px;
}

.fi-br-square-x.text-danger {
  opacity: 0.5;
}


.fi-br-checkbox.text-success {
  opacity: 0.8;
}

.filter-check-box .p-checkbox .p-checkbox-box {
  border: 1px solid #d3d4d6;
  background: #f7f8fa;
  width: 18px;
  height: 18px;
  border-radius: 3px;
}

.toggle-line-height .finsight-toggle {
  line-height: 0;
}


.custom-dashboard-card-body {
  /* max-height: 34vw; */
  height: calc(100vh - 202px);
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-dashboard-card-body-no-data {
  /* max-height: 34vw; */
  height: calc(100vh - 215px);
  overflow-y: auto;
  overflow-x: hidden;
}

.table-border-less tbody tr:last-child td {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 0;
}


.table-border-less thead tr th {
  padding: 8px 15px;
  border-bottom: 1px solid #d3d4d6;
}

.table-border-less tbody tr td {
  padding: 5px 15px;
}

.table-border-less thead tr th:first-child {
  border-top-left-radius: 5px;
}

.table-border-less thead tr th:last-child {
  border-top-right-radius: 5px;
}

.table-border-less td .form-control {
  background: transparent;
}



.create-bulk-vch-sel .select-row-revese.form-control {
  /* width: 200px; */
  background: #fff;
  border-radius: 5px;
  /* border: 1px solid #f5f6f8; */
}

.create-bulk-vch-sel .select-row-revese .form-control {
  width: 200px;
  border-radius: 5px;

}

.create-bulk-vch-sel .MuiChip-deletable.MuiChip-deletableColorDefault {
  border-radius: 8px !important;
}

.dashed-border-button {
  border: 1px dashed #d3d4d6;
  background: #F7F9FC;
}

.hover-btns-index .hover-btns {
  display: none;
}

.hover-btns-index:hover .hover-btns {
  display: block !important;
  width: 250px;
  z-index: 1;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
  background: linear-gradient(262deg, #D0D0D0 0%, rgba(226, 227, 228, 0.00) 63.61%);
}

.hover-btns-index:hover .hover-btns .btn {
  margin-right: 17px;
  margin-top: 17px;
}

.tree-inp .p-treeselect {
  font-size: 14px;
  border: 1px solid #D3D4D6;
  color: #23252D;
  background: #fff;
  border-radius: 8px;
  padding: 5px 10px;
  line-height: 24px;
  height: 36px;
}

.tree-inp .p-treeselect-trigger {
  justify-content: end;
}

.tree-inp .p-treeselect .p-treeselect-label.p-placeholder {
  color: #aeaeae;
}

.tree-inp .p-treeselect .p-treeselect-label {
  padding: 0;
}

.tree-inp .p-treeselect-trigger-icon.p-clickable.pi-chevron-down {
  font-size: 10px;
}

.settings-close-btn {
  position: absolute;
  top: 25%;
  right: 0;
  background: #f7f8fa;
  border: 1px solid #e8eaeb;
  border-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

}

.settings-side-hidden .cog-btn {
  display: none;
}

.settings-side-hidden:hover .cog-btn {
  display: block;
}

.dashed-color-card {
  border: 2px dashed #e8eaeb !important;
}

.dashed-color-card-header {
  border-bottom: 2px dashed #e8eaeb;
}

.dashed-card-lyt .react-grid-item:not(.react-grid-placeholder) {
  border: 1px solid #d3d4d6;
  /* box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.2); */
  /* box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05); */
  border-radius: 10px;
}



.finsights-data-table .p-datatable .p-datatable-thead th.p-filter-column input {
  height: 32px;
  font-size: 12px;
  border: 1px solid #D3D4D6;
  color: #23252D;
  background: #fff;
  border-radius: 0.1525rem;
  padding: 5px 10px;
}

.finsights-data-table .p-datatable .p-datatable-thead th.p-filter-column .pi-filter-icon.pi-filter {
  font-size: 12px;
}

.finsights-data-table .p-datatable .p-datatable-thead th.p-filter-column .p-column-filter-clear-button .pi-filter-slash {
  font-size: 12px;
}

.finsights-data-table .p-datatable .p-datatable-thead th.p-sortable-column .p-column-filter-menu-button .pi-filter-icon.pi-filter {
  font-size: 12px;
}

.finsights-data-table .p-datatable .p-datatable-thead th.p-sortable-column .p-column-filter-menu-button .p-column-filter-clear-button .pi-filter-slash {
  font-size: 12px;
}



.multi-sel {
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 10px;
  background: white !important;
  border: 1px solid #d3d4d6 !important;
  justify-content: center;
}

.multi-sel .p-multiselect-label-container {
  display: none;
}

.multi-sel-box {
  width: auto;
  display: flex;
  align-items: center;
  background: white !important;
  border: none !important;
  justify-content: space-between;
}

.multi-sel .p-multiselect-trigger span::before {
  font-family: uicons-bold-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\f1e0";

}

.mul-sel .fi-br-bars-filter {
  line-height: 1;
}

.multi-sel .p-multiselect-trigger {
  width: auto !important;

}

.p-datatable-header {
  background: white !important;
  border: none !important;
  border-radius: rem;

}

.multi-sel .p-datatable-header {
  padding: 0 1rem !important;

}

.finsights-data-table .p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: #f7f8fa;
  background-color: #EBF0FF !important;
}

.l-h-20-before:before {
  line-height: 20px !important;
}

.btn.btn-link:focus-visible,
.btn.btn-outline-primary:focus-visible,
.btn.btn-outline-light:focus-visible,
.btn.btn-primary:focus-visible,
.btn.btn-primary:focus {

  box-shadow: 0 0 0 2px #3060f08e !important;
}

.header-top-left-radius {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #d3d4d6;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.b-b-r-5 {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.finsights-data-table .p-datatable .p-datatable-tbody>tr:focus-visible {
  background: #ebf0ff;
  background-color: #ebf0ff !important;
  outline-style: solid;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
  outline-offset: -2px;
  border-radius: 0px;
  box-shadow: 5px 0px #305FF0 inset;
}

.finsights-data-table .p-datatable .p-datatable-tbody>tr:focus {
  background: #ebf0ff;
  background-color: #ebf0ff !important;
  outline-style: solid;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
  outline-offset: -2px;
  border-radius: 0px;
  box-shadow: 5px 0px #305FF0 inset;
}

.finsights-data-table .p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:focus {
  background-color: #ebf0ff !important;
  outline-style: solid;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
  outline-offset: -2px;
  border-radius: 0px;
  box-shadow: 5px 0px #305FF0 inset;

}

.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background-color: transparent !important;
  /* outline-style: solid;
  outline-style: solid;
  outline-width: 2px;
  outline-color: #3060f08e;
  outline-offset: -2px;
  border-radius: 3px; */
}

.record-btns .btn {
  padding-left: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 0;
  border-left: 3px solid transparent;
  margin-bottom: 5px;
}

.record-btns button.active {
  background: #f7f8fa;
  border-left: 3px solid #305FF03b;
}

.record-btns button:hover {
  background: #f7f8fa;
  border-left: 3px solid #ebf0ff !important;

}

.elipsis-dt-table {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.finsights-data-table.b-r-5-dt-table .p-datatable .p-datatable-thead tr th:first-child {
  border-top-left-radius: 8px;
}

.finsights-data-table.b-r-5-dt-table .p-datatable .p-datatable-tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.finsights-data-table.b-r-5-dt-table .p-datatable .p-datatable-thead tr th:last-child {
  border-top-right-radius: 8px;
}

.finsights-data-table.b-r-5-dt-table .p-datatable .p-datatable-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.input-padding-dt-table .p-datatable .p-datatable-thead tr th {
  padding: 15px 15px;
}

.input-padding-dt-table .p-datatable .p-datatable-tbody tr td {
  padding: 5px 5px;
}

.input-padding-dt-table .p-datatable .p-datatable-tbody tr td:first-child {
  padding-left: 10px;
}

.select-inp.search-beside-inp .select-row-revese.form-control {
  height: 36px;
  border-radius: 5px;
}

.select-inp.search-beside-inp .select-row-revese .MuiInputBase-fullWidth {
  height: 34px;
  border-radius: 5px;
}

.select-inp.search-beside-inp .form-control {
  border-radius: 5px;
}

.select-inp.search-beside-inp .MuiAutocomplete-tag {
  margin-top: -2px !important;
}

.h-38-search {
  height: 36px;
}

.select-inp .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicatorOpen {
  width: 30px;
  height: 30px;
  line-height: 12px;
}

.select-inp .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator {
  width: 30px;
  height: 30px;
  line-height: 12px;
}

.finsights-data-table .p-datatable .p-datatable-tbody tr td .hover-btns-dt {
  visibility: hidden;
}

.finsights-data-table .p-datatable .p-datatable-tbody tr:hover .hover-btns-dt {
  visibility: inherit;
}

.list-box-styling .p-listbox {
  border: 0;
}

.list-box-styling .p-listbox-list {
  padding: 0;
}

.list-box-styling .p-listbox .p-listbox-list .p-listbox-item {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-color: #d3d4d654;
  border-bottom: 1px solid #d3d4d654;
  border-left: 4px solid transparent;
}

.list-box-styling .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background-color: #F7F8FA;
  border-left: 4px solid #305ff070;
  border-bottom-color: #d3d4d654;
}


.list-box-styling .p-listbox .p-listbox-list .p-listbox-item:hover {
  background-color: #F7F8FA;
  cursor: pointer;
  border-left: 4px solid #e8eaeb;
}

.list-box-styling .p-listbox .p-listbox-list .p-listbox-item:focus {
  box-shadow: none;
  background-color: #F7F8FA;
  cursor: pointer;
  border-left: 4px solid #305ff070;
}

.list-box-styling .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  background: #F7F8FA;
}

.v-list-scroll-body {
  height: calc(100vh - 225px);
  overflow-y: auto;
  overflow-x: hidden;
}

.view-body-list-scroll {
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.view-trans-list-scroll {
  height: calc(100vh - 181px);
  overflow-y: auto;
  overflow-x: hidden;
}



.summary-view-scroll {
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.summary-view-scroll-category {
  height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
}

.bg-warning-label {
  border: 1px solid #FBD08C;
  background: #FEE6C0;
  padding: 2px 8px;
  border-radius: 15px;
  color: #C27803;
}

.bg-info-label {
  border: 1px solid #DAABEA;
  background: #F9E9FF;
  padding: 2px 8px;
  border-radius: 15px;
  color: #9833BB;
}


.collapse-content-sidebar {
  transition: transform 0.15s ease-in-out;
  z-index: 1;

}

.collapse-content-sidebar .collapse-content-hidden {
  display: none;
}


.collapse-content-sidebar .create-collapse-content-hidden .addtional-long-text {
  display: none;
}

.collapse-content-sidebar .create-collapse-content-hidden .additional-icn {
  display: block;
}

.collapse-content-sidebar .create-collapse-content-hidden .add-long-text {
  display: none;
}

.collapse-content-sidebar .create-collapse-content-hidden .create-vch-icn {
  display: block;
  margin-right: 0px;
  line-height: 22px;
  font-size: 16px;
  color: #6f7380;
}

.collapse-content-sidebar .create-collapse-content-hidden .key-btn {
  font-size: 10px;
  display: none;
}

.collapse-content-sidebar .create-collapse-content-hidden .list-group-item .icn-center-position {
  justify-content: center !important;
}

.collapse-content-sidebar .create-collapse-content-hidden .main-add-text {
  text-align: center;
  margin-left: -50px;
}

.collapse-content-sidebar .collapse-expand-option {
  left: -12%;
}

.no-head-table-new tr:first-child td {
  border-top: 0;
}

.no-head-table-new tr:first-child td:first-child {
  border-top-left-radius: 8px;
}

.no-head-table-new tr:first-child td:last-child {
  border-top-right-radius: 8px;
}

.btn-vertical-round .btn.active {
  border-left: 3px solid #305FF0 !important;
}

.btn-vertical-round .btn {
  border-left: 3px solid transparent !important;
}

.w-20 {
  width: 20%;
}

.vch-inputs input[type="text"].form-control:focus-within {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.dr-cr-btn .btn {
  font-size: 12px;
  background: #305ff061 !important;
  border-color: #305ff061 !important;
  color: #23252d;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 600;
  border-radius: 3px;
  line-height: 15px;
}

.dr-cr-btn .dropdown-item {
  font-size: 14px;
}

.dr-cr-btn .dropdown-menu {
  min-width: 7rem;
}


.p-top-7px {
  padding-top: 8px !important;
}

.dt-list-pagination .p-datatable .p-datatable-thead {
  display: none;
}

.dt-list-pagination .p-datatable .p-datatable-tbody td {
  border-bottom-color: #d3d4d654;
}

.dt-list-pagination table {
  width: 100%;
}

.dt-list-pagination .p-datatable .p-paginator {
  background: #000;
}

.dt-list-pagination .p-datatable-wrapper {

  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.pagination-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
}

.paginator-box-top-shadow {
  box-shadow: 0 -6px 10px -10px #9e9e9e;
  z-index: 11;
  border-top: 1px solid #ffffff48;
}

.last-hr-rem hr:last-child {
  border: 0;
}

.toggle-l-h .finsight-toggle {
  line-height: 1;
}

.readonly-select .form-control {
  background-color: #f6f8fc;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
  border-color: transparent !important;
}


.items-new-table.audit-dash-table thead tr:first-child th {
  border-top: 1px solid #d3d4d68f;
}

.items-new-table.audit-dash-table tbody tr td {
  border-color: #d3d4d68f !important;
  color: #6f7380;

}

.items-new-table.audit-dash-table tbody tr:last-child td {
  border-bottom: 1px solid #d3d4d68f;
}

.card-columns-lyt {
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 1.25rem;
  column-gap: 1.25rem;
  orphans: 1;
  widows: 1;
}

.card-columns-lyt .card {
  display: inline-block;
  width: 100%;
  position: inherit;
  margin-bottom: 15px;
  padding: 0;
}

.card-columns-lyt .card:hover {
  cursor: pointer;
}

.btn-list-type-ul .btn-list-ul-padding button:focus-visible,
.btn-list-type-ul .btn-list-ul-padding button:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.pref-stcok-btns button:focus-visible,
.pref-stcok-btns button:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.items-new-table.multi-th thead tr:first-child th {
  border-bottom: 1px solid #d3d4d6;

}

.items-new-table.multi-th thead tr th {
  border-right: 1px solid #d3d4d6;
}

.items-new-table.multi-th thead tr:first-child th:last-child {
  border-right: 0 !important;
}

.dashboard-dt-tables .p-datatable .p-datatable-thead tr th {
  font-size: 12px;
  font-weight: 600;
  padding: 10px 10px;
  text-transform: capitalize;
  color: #23252D;
}

.dashboard-dt-tables .p-datatable .p-datatable-tbody tr td {
  font-size: 12px;
  padding: 10px 10px;
  text-transform: capitalize;

}

.ptb-10-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.l-h-15 {
  line-height: 15px;
}

.main-module-padding {
  padding-top: 15px;
  padding-left: 18px;
}

.main-module-li .list-group-item {
  border-color: transparent;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0px;
  border-bottom: 1px solid #d3d4d6;
}

.kitaab-main-module-icn {
  width: 40px;
  height: 40px;
  background: #EBF0FF;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
}

.kitaab-main-module-icn i {
  line-height: 45px;
  color: #305FF0;
  font-size: 14px;
}

.second-p {
  display: none;
}


.main-module-li li.list-group-item.active {
  background: transparent;
}

.main-module-li li.list-group-item.active button {
  background: #eaf1fb;
  /* border-left: 4px solid #305FF0; */
}

/* .main-module-li li.list-group-item.active p {
  color: #fff;
}

.main-module-li li.list-group-item.active i {
  color: #fff;
} */


.modules-card {
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.modules-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0 0 0 2px #3060f08e !important;
  top: -1px;
  background-color: white;
}

button.modules-card:focus-visible,
button.modules-card:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.card2:hover:before {
  transform: scale(1.15);
}

.dashboard-dt-tables .p-datatable-wrapper::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.dashboard-dt-tables .p-datatable-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}

.dashboard-dt-tables .p-datatable-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: transparent;
}

.react-grid-item:focus,
.react-grid-item:focus-visible,
.react-grid-item:focus-within,
.react-grid-item:active,
.react-grid-item:visited,
.react-grid-item:hover {
  box-shadow: 0 0 0 2px #3060f08e !important;
  border-color: transparent !important;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se {
  /* display: none; */
  opacity: 0;
}

.react-grid-item:hover>.react-resizable-handle.react-resizable-handle-se {
  /* display: block; */
  opacity: 1;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se {
  bottom: 5px !important;
  right: 5px !important;
}

/* .main-cards-dashboard {
  border: 1px solid #f7f8fa;
  box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.2);
  border-radius: 10px;
} */


.icon-shape {
  width: 45px;
  height: 45px;
  background-position: 50%;
  border-radius: 5px;
  line-height: 45px;
}

.icon-shape-blue {
  background: #eaf1fb;
}

.demo-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.col-span-gst-table thead th {
  font-size: 12px;
  color: #515561;
  border-top: 0;

}

.col-span-gst-table thead tr th:first-child {
  border-top-left-radius: 5px;
  border-left: 0;
}

.col-span-gst-table thead tr:first-child th {
  border-bottom: 1px solid #d3d4d6;
}

.col-span-gst-table thead tr th:last-child {
  border-top-right-radius: 5px;
  border-right: 0;
}

.col-span-gst-table tbody tr td {
  font-size: 14px;
  color: #23252D;
  border-bottom: 0;
}

.col-span-gst-table tbody tr td:first-child {
  border-left: 0;
}

.col-span-gst-table tbody tr td:last-child {
  border-right: 0;
}

.col-span-gst-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

.col-span-gst-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.col-span-gst-table.col-span-multi thead tr:nth-child(2) th {
  border-bottom: 1px solid #d3d4d6;
}

.col-span-gst-table.col-span-multi thead tr:nth-child(3) th {
  border-bottom: 1px solid #d3d4d6;
}

.l-h-30-before:before {
  line-height: 30px !important;
}

.recon-2a-vs-books .p-datatable-wrapper .p-virtualscroller {
  height: calc(100vh - 365px) !important;

}

.dlg-vrt-scroll .p-datatable-wrapper .p-virtualscroller {
  height: calc(100vh - 160px) !important;
}

.missing-in-2a-dt .p-datatable-wrapper .p-virtualscroller {
  height: calc(100vh - 325px) !important;
}

.gstr-1-dt .p-datatable-wrapper .p-virtualscroller {
  height: calc(100vh - 305px) !important;
}

.recon-matched-dt .p-datatable-wrapper .p-virtualscroller {
  height: calc(100vh - 345px) !important;
}

.multi-month-2a-dt .p-datatable-wrapper .p-virtualscroller {
  height: calc(100vh - 320px) !important;
}

.supplier-2b-dt .p-datatable-wrapper .p-virtualscroller {
  height: calc(100vh - 320px) !important;
}

.other-report-dt .p-datatable-wrapper .p-virtualscroller {
  height: calc(100vh - 315px) !important;
}




.inside-dt .recon-2a-vs-books .p-datatable-wrapper .p-virtualscroller {
  height: inherit;

}

.custom-cls-header .p-dialog-header-icons {
  margin-top: 8px;
  margin-left: 15px;
}

.filter-class .p-multiselect-items {
  padding: 0;
}

.filter-class .p-multiselect-items .p-multiselect-item {
  font-size: 14px;
}

.filter-class .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #f7f8fa;
}

.filter-class .p-multiselect-items .p-multiselect-item:focus,
.filter-class .p-multiselect-items .p-multiselect-item:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.13rem #305FF0;
  border-radius: 3px;
}

.multi-sel:focus-visible,
.multi-sel:active {
  background-color: #EBF0FF !important;
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -1px;
}

.dlg-inside-table table thead tr th {
  border-top: 0;
}

.dlg-inside-table-dlg .p-dialog-header {
  border-bottom: 1px solid #d3d4d6;
}

.remove-focus:focus-visible {
  outline: none;
}

.remove-focus:focus-visible .card,
.remove-focus:focus .card {
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -1px;
}


.border-in-table thead th {
  font-size: 12px;
  color: #515561;
  border-top: 0;
}

.border-in-table thead tr th:first-child {
  border-top-left-radius: 5px;
}

.border-in-table thead tr th:last-child {
  border-top-right-radius: 5px;
}

.border-in-table thead tr th:first-child {
  border-left: 0;
}

.border-in-table thead tr th:last-child {
  border-right: 0;
}

.border-in-table tbody tr td:first-child {
  border-left: 0;
}

.border-in-table tbody tr td:last-child {
  border-right: 0;
}




.border-in-table tbody tr td {
  font-size: 14px;
  color: #23252D;
  border-bottom: 0;
}

.border-in-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

.border-in-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.finsights-data-table .p-datatable-emptymessage td {
  border-bottom: 0 !important;
}

.finsights-data-table .p-datatable-emptymessage td img {
  opacity: 0.5;
}

.finsights-data-table .p-datatable-scrollable .p-datatable-tbody>tr.p-datatable-emptymessage>td {
  justify-content: center;
}

.gst-skeleton {
  border-radius: 0 !important;
  transform-origin: 0% !important;
  transform: inherit !important;

}

.finsights-data-table .p-datatable-thead .p-sortable-column .p-column-header-content span.p-column-title {
  /* max-width: 150px; */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.org-del-icn {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.list-group-item:hover .org-del-icn {
  display: block;
}

.org-view-scroll-bdy {
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}

.finsights-data-table.summary-grp-table .p-datatable-thead tr:nth-child(2) th:first-child {
  border-right: 1px solid #d3d4d69c !important;
}

/* .finsights-data-table.summary-grp-table .p-datatable-tbody tr.p-datatable-row-expansion>td {
  padding: 0;
} */

.p-datatable-loading-overlay.p-component-overlay {
  background-color: #b8b8b866;
}

.inside-unit {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 14px;
}

.module-round-icon {
  width: 35px;
  height: 35px;
  border-radius: 25px;
  line-height: 37px;
  background: #EBF0FF;
}

.modules-icon-buttons {
  border: 2px solid #e8eaeb;
}

.p-10-total {
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
}

.p-10-20-total {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.p-t-15-padding {
  padding-top: 15px;
}

.p-b-15-padding {
  padding-bottom: 15px;
}

.double-border-btn {
  border-width: 2px;
}

.skeleton-custom {
  transform-origin: 0 !important;
  transform: none !important;
}

.kg-select.kg-select-sec .select-row-revese .MuiInputBase-fullWidth {
  height: 30.2px;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.inp-grp-first .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.kitaab-modules-v-height {
  height: calc(100vh - 105px);
  overflow-y: auto;
}

.kitaab-modules-h-height {
  height: calc(100vh - 180px);
  overflow-y: auto;
}

.kitaab-modules-v-in-height {
  height: calc(100vh - 175px);
  overflow-y: auto;
}

.db-tree-inp .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  width: 15px;
  height: 15px;
}

.db-tree-inp .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler .pi-fw.pi-chevron-right {
  font-size: 10px;
}

.db-tree-inp .p-treeselect-items-wrapper .p-tree {
  padding: 5px;
}

.db-tree-inp .p-treeselect-header {
  display: none;
}

.db-tree-inp .p-tree-container .p-treenode .p-treenode-content {
  padding: 5px;
}

.db-tree-inp .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
  font-size: 12px;
}

.db-tree-inp .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 3px;
}

.tree-inp .p-treeselect:focus-visible,
.tree-inp .p-treeselect:focus-within,
.tree-inp .p-treeselect:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #3060f08e;
  outline-offset: -1px;
}



.inp-grp-first .form-control:focus-visible {
  /* box-shadow: inset 0 0 0 2px #3060f08e !important; */
  background-color: #EBF0FF !important;
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -1px;
  /* box-shadow: 0 0 0 2px #3060f08e !important;
  outline: 0; */
  border-radius: 3px;

}

.inp-grp-first .select-row-revese.form-control:first-child:focus-within {
  box-shadow: transparent !important;
}

/* .inp-grp-first .form-control:focus-within {
  outline-style: auto;
  outline-width: 1px;
  outline-color: #3060f08e;
  outline-offset: 1px;

} */

.kg-select.kg-select-sec .MuiChip-label {
  padding-top: 4px;
}

/* .kg-select-sec .form-control:focus-within {
  outline-style: auto;
  outline-width: 1px;
  outline-color: #3060f08e;
  outline-offset: 1px;

} */

.vert-modules-bn button {
  border-radius: 0;
}

.p-20-total {
  padding: 18px;
}

.graph-exp {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.span-elipsis span {
  max-width: max-content;
  display: inline-block;
}

.period-change-dlg .p-dialog-content {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.period-change-dlg .p-dialog-header .p-dialog-title {
  font-size: 16px;
}

.main-module-li li.list-group-item.active button.btn-link:focus-visible,
.main-module-li li.list-group-item.active button.btn-link:focus {
  box-shadow: none !important;
  background-color: #EBF0FF !important;
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -2px;
  border-radius: 0px;
}

.bg-warning-red-label {
  background-color: #FDE8E8;
  color: #23252D;
  padding: 2px 10px;
  border-radius: 6px;
}

/* 
.finsights-data-table .p-datatable .p-datatable-tbody>tr>td {
  padding: 12px 24px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  border: 1px solid #d3d4d69c;

  border-width: 0 0 1px 0;
} */

.finsights-data-table-tree .p-treetable-thead>tr>th {
  padding: 16px 16px;
  font-weight: 500;
  font-size: 12px;
  background: #F9FAFB;
  color: #515561;
  border: 1px solid #d3d4d6a8;
  border-width: 0 0 1px 0;
}

.finsights-data-table-tree.col-span-tree .p-treetable-thead>tr:first-child>th:first-child {
  border-right: 1px solid #d3d4d6;
}

.finsights-data-table-tree.col-span-tree .p-treetable-thead>tr>th:first-child {
  border-right: 1px solid #d3d4d6;
}

.finsights-data-table-tree.col-span-tree .p-treetable-thead>tr>th:last-child {
  border-right: 0;
}

.finsights-data-table-tree .p-treetable-tbody>tr>td {
  padding: 12px 16px;
  /* text-transform: capitalize; */
  font-size: 14px;
  font-weight: 400;
  color: #23252D;
  border: 1px solid #d3d4d69c;
  border-width: 0 0 1px 0;
}

.finsights-data-table-tree.tree-last-line .p-treetable-tbody>tr:last-child>td {
  border-bottom: 0;
}

.finsights-data-table-tree .p-treetable-tbody>tr>td .p-treetable-toggler {
  width: 15px;
  height: 15px;
}

.finsights-data-table-tree .p-treetable-tbody>tr>td .p-treetable-toggler i {
  font-size: 14px;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.p-treetable .p-treetable-tbody>tr:focus {
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -3px;
  /* box-shadow: 0 0 0 2px #3060f08e !important;
  outline: 0; */
  border-radius: 3px;
}

.finsights-data-table.first-last-total .p-datatable-tbody tr:first-child td {
  font-weight: 600;
}

.finsights-data-table.first-last-total .p-datatable-tbody tr:last-child td {
  font-weight: 600;
}

.round-skeleton {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
}

.save-tick-round {
  width: 72px;
  height: 72px;
  border: 5px solid #EBF0FF;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  line-height: 64px;
  background: #EBF0FF;
}

.save-tick-round img {
  line-height: 72px;
}

.save-dlg .p-dialog-header {
  border-bottom: 0;
}

.inv-hr {
  border-top: 5px solid #d3d4d6;
  opacity: 1;
}

.inv-table tr td {
  border-bottom: 1px solid #d3d4d6;
  border-right: 1px solid #d3d4d6;
  font-size: 14px;
  padding: 10px;
}

.inv-table tr td:first-child {
  border-left: 1px solid #d3d4d6;
}


.inv-table tr td:last-child {
  border-right: 1px solid #d3d4d6;
}

.inv-table tr:first-child td {
  border-top: 1px solid #d3d4d6;
}

.inv-table tr:last-child td {
  border-bottom: 1px solid #d3d4d6;
}

.inv-table tr:last-child td {
  border-bottom: 1px solid #d3d4d6;
}


.no-table tr td {
  border: 0 !important;
  padding: 0;
}

.no-left-right-table tr td {
  border-left: 0 !important;
  border-right: 0 !important;
}

.no-left-right-table tr th {
  border-left: 0;
  border-right: 0;
}

.no-left-right-table tr:last-child td {
  border-bottom: 0;
}

.no-left-right-table tr th {
  border-top: 0;
}

.colspan-table-inv tr td {
  background: #f7f8fa;
}

.colspan-table-inv tr td:first-child {
  border-left: 0;
}

.colspan-table-inv tr:first-child td {
  border-top: 0;
}


.colspan-table-inv tr:last-child td {
  border-bottom: 0;
}

.b-both-15-top {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.border-red .p-inputtext {
  border: 2px solid red;
}

.panel-status-summary .p-panel .p-panel-header {
  background: #f7f8fa;
  border: 1px solid #d3d4d6;
  padding: 10px;
}

.panel-status-summary .p-panel {
  margin-bottom: 12px;
}

.panel-status-summary .p-panel .p-panel-header .p-panel-header-icon {
  width: 17px;
  height: 17px;
}

.panel-status-summary .p-panel .p-panel-header-icon span {
  font-size: 12px;
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.panel-status-summary .p-panel .p-panel-content {
  padding: 10px;
}

.panel-status-summary .p-panel .p-panel-header .p-panel-title {
  font-size: 14px;
  font-weight: 600;
}

.panel-status-summary:last-child .p-panel {
  margin-bottom: 0;
}

.align-items-center-table tbody tr td {
  vertical-align: middle;
}

.finsights-data-table.col-span-table.single-colspan-table .p-datatable .p-datatable-thead tr:nth-child(2) th:last-child {
  border-right: 1px solid #d3d4d6a8;
}

.finsights-data-table.col-span-table.single-colspan-table .p-datatable .p-datatable-thead tr:nth-child(1) th:last-child {
  border-right: 0 !important;
}

.only-tbody tbody tr:first-child td {
  border-top: 0 !important
}

.only-tbody tbody tr:first-child td:first-child {
  border-top-left-radius: 5px;
}

.only-tbody tbody tr:first-child td:last-child {
  border-top-right-radius: 5px;
}

.sk-square {
  width: 45px !important;
  height: 45px !important;
  background-position: 50%;
  border-radius: 5px;
  line-height: 45px;
  display: inline-block;
}

.split-button .p-splitbutton {
  margin-bottom: 0 !important;
}

.split-button .p-splitbutton .p-button {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}

.split-button .p-splitbutton .p-button span.p-button-label {
  font-weight: 600;
}

.split-button .p-splitbutton .p-button span.p-button-icon.pi-chevron-down {
  font-size: 12px;
}

.split-button .p-button:focus,
.split-button .p-button:focus-visible {
  /* box-shadow: 0 0 0 2px #305FF08e, 0 0 0 0px #b1b3f8, 0 0px 0px 0 black; */
  outline-style: solid !important;
  outline-width: 2.5px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px;
  box-shadow: none;
  /* outline: 0; */
}

.split-button .p-splitbutton.p-button-outlined>.p-button {
  border-color: #d3d4d6 !important;
  color: #305FF0 !important;
  background: #f7f8fa;

}

.opacity-disabled {
  opacity: 0.5;
}

.sort-icon-rot:before {
  transform: rotate(135deg);
  display: inline-block;
  margin-right: 0px;
}

.border-table-sheet thead tr th {
  border: 1px solid #d3d4d6 !important;
}

.border-table-sheet tbody tr td {
  border: 1px solid #d3d4d6 !important;
}

.file-div {
  position: relative;
  overflow: hidden;
}

.file-div input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.figma-select .form-control {
  background: #F7F8FA;
  border-radius: 5px;
}

.v-align-middile-tab tbody tr td {
  vertical-align: middle;
}

.templates-ul li {
  border-bottom: 0 !important;
}

.p-10-24-padding {
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
}

.w-20-temp {
  width: 20%;
}

.w-80-temp {
  width: 80%;
}

.temp-side-scroll {
  height: calc(100vh - 225px);
  overflow-y: auto;
  overflow-x: hidden;
}

.filter-sort-ledger-dlg {
  height: 75%;
}

.filters-card-body-scroll {
  height: calc(100vh - 335px);
  overflow-y: auto;
  overflow-x: hidden;
}

.y-overflow-hide .p-dialog-content {
  overflow-y: hidden;
}

.tooltip-min-max {
  background: #f7f8fa;
  border: 1px solid #d3d4d6;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  position: relative;
}

.tooltip-min-max:after {
  content: '';
  position: absolute;
  right: 10px;
  bottom: -5px;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #d3d4d6;
  border-right: 1px solid #d3d4d6;
  background: inherit;
  transform: rotate(45deg);
}

.list-unstyled li:focus-visible {
  box-shadow: 0 0 0 2px #3060f08e !important;
  outline: 0;
  border-radius: 8px;
}

.bottom-val {
  border: 1px solid #515561;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
}

.btn-primary-active {
  background: #EBF0FF;
  color: #305FF0;
}

.icn-right i {
  position: absolute;
  right: 10px;
  top: 12px;
}

.w-80-slide {
  width: 80%;
}

.list-group-item+.list-group-item.active {
  margin-top: inherit;
  border-top-width: inherit;
}

.dt-table-5-padding .p-datatable-tbody tr td {
  padding: 5px 24px !important;
}

.first-th .p-datatable-thead tr th {
  border-top: 1px solid #d3d4d6a8 !important;
}

.first-th .inside-th .p-datatable-thead tr th {
  border-top: 0px solid #d3d4d6a8 !important;
}

.text-end-flex .p-column-header-content {
  justify-content: end;
}

.round-skeleton-imp {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
}

.series-skeleton span {
  display: inline-block;
}

.db-skeleton {
  width: 275px;
  height: 275px !important;
  border-radius: 10% !important;
  margin: auto;
}

.tree-fist-last .p-treetable-thead tr th {
  border-top: 1px solid #d3d4d6a8;
}

.tree-fist-last .p-treetable-tbody tr:last-child td {
  border-bottom: 1px solid #d3d4d6a8 !important;
}

.tree-fist-last .p-treetable-tbody tr.f-600-heading td {
  font-weight: 600;
}

.cost-center-class-card-body {
  height: calc(100vh - 178px);
  overflow-y: auto;
  overflow-x: hidden;
}

.cost-center-list li {
  padding-top: 20px;
  padding-bottom: 20px;
}

.org-list-del-hover .p-listbox-item:hover .org-del-icn {
  display: block;
}

.error-info-symbol {
  position: absolute;
  top: 4px;
  right: 13px;
  background: #FDF2F2;
  cursor: pointer;
}

.error-input .form-control {
  border: 1px solid #F05252;
  background: #FDF2F2 !important;
}

.error-input .select-row-revese.form-control {
  border: 0 !important;
}

.error-info-symbol:hover .error-info-message {
  display: block;
  cursor: pointer;
  position: absolute;
  background-color: #525252;
  color: #fff;
  width: 250px;
  border-radius: 5px;
  padding: 5px;
}

.error-info-message {
  line-height: 1.5;
  display: block;
  display: none;
}

.v-top-td td {
  vertical-align: top;
}

.list-box-styling .p-listbox-list .p-listbox-item:last-child {
  margin-bottom: 5rem;
}

.l-h-12px {
  line-height: 12px;
}

.text-light-pref {
  color: #d3d4d6;
}

.e-way-history-btn {
  position: absolute;
  top: 18px;
  right: 67px;
}

.multi-sel-join .p-multiselect {
  font-size: 14px;
  border: 1px solid #D3D4D6;
  color: #23252D;
  background: #fff;
  border-radius: 0.1525rem;
  box-shadow: none;
  padding: 5px 10px;
  line-height: 20px;
  height: 40px;
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.multi-sel-join .p-multiselect .p-multiselect-label {
  padding: 5px;
}

.split-btn-focus .form-control:focus-visible {
  outline-style: solid !important;
  outline-width: 2.5px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px;
  box-shadow: none;
}

.split-btn-focus .p-multiselect:focus-visible {
  outline-style: solid !important;
  outline-width: 2.5px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px;
  box-shadow: none;
}

.split-btn-focus .p-multiselect:not(.p-disabled):hover {
  outline-style: solid !important;
  outline-width: 2.5px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px;
  box-shadow: none;
}

.split-btn-focus .form-control:focus-within,
.split-btn-focus .form-control:focus {
  box-shadow: none !important;
}

.finsights-data-table.empty-message .p-datatable-tbody .p-datatable-emptymessage td {
  text-align: center;
}

.error-div-parent {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #fff;
  line-height: 0;
  color: #F05252;
  border-radius: 50%;
}

.kg-select .error-div-parent {
  background: #f7f8fa !important;
}

.form-control.has-error {
  border: 1px solid #F05252bd;
}

.error-message-child {
  position: absolute;
  background: #525252a8;
  width: 250px;
  right: 0;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
  border-radius: 5px;
  display: none;
  color: #fff;
}

.error-div-parent:hover .error-message-child {
  display: block;
}

.p-tabview.stock-d-none .p-tabview-nav {
  display: none;
}

.fixed-header-chart {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.p-15-l-r-imp {
  padding-left: 15px;
  padding-right: 15px;
}

.f-g-nowrap {
  flex-wrap: nowrap;
}

.v-middle-table tr td {
  vertical-align: middle;
}

.amount-round-sk {
  border: 1px solid #d3d4d6;
  border-radius: 25px !important;
  background-color: #0000000f !important;
  border-color: #0000000f;
}

.eway-bill-scroll .p-datatable-wrapper {

  height: calc(100vh - 180px);
  overflow-y: auto;
  /* overflow-x: hidden; */
}

.react-grid-item>.react-resizable-handle {
  z-index: 2;
}

.login-error-i .error-div-parent {
  top: 16px;
}



.react-grid-item>.react-resizable-handle.react-resizable-handle-n {
  opacity: 0;
}

.react-grid-item:hover>.react-resizable-handle.react-resizable-handle-n {
  opacity: 1;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n {
  top: 5px !important;

}

.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
  opacity: 0;
}

.react-grid-item:hover>.react-resizable-handle.react-resizable-handle-e {
  opacity: 1;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
  bottom: 5px !important;
  right: 5px !important;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w {
  opacity: 0;
}

.react-grid-item:hover>.react-resizable-handle.react-resizable-handle-w {
  opacity: 1;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w {
  left: 5px !important;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
  opacity: 0;
}

.react-grid-item:hover>.react-resizable-handle.react-resizable-handle-s {
  opacity: 1;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
  bottom: 5px !important;
  right: 5px !important;
}

.bottom-dot-address {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.address-menu-item .p-menuitem {
  padding-left: 0.75rem;
}

.tax-desc {
  height: 32px !important;
  min-height: inherit !important;
}

.org-confirmation-dlg-custom .p-dialog-content {
  display: block;
}

.org-confirmation-dlg-custom .p-dialog-content span:first-child {
  display: block;
  width: 100%;
  text-align: center;
  color: #ffc10796;
  font-size: 55px;
  margin: auto;
}

.org-confirmation-dlg-custom .p-dialog-content span:nth-child(2) {
  display: block;
  width: 100%;
  text-align: center;
  margin-left: 0;
  font-size: 14px;
  margin-top: 20px;
}

.org-sign-in-card-body {
  /* max-height: 34vw; */
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
}


.react-grid-item>.react-resizable-handle::after {
  border-right: 0 !important;
  border-bottom: 0 !important;
}

.water-mark {
  position: absolute;
  top: 45%;
  width: 100%;
  text-align: center;
  opacity: 0.2;
  z-index: 99;
  color: #000;
}

.water-mark-text {
  transform: rotate(-45deg);
  font-size: 34px;
  font-weight: 900;
}


.payment-list-tooltip {
  display: none;
  background: #23252d;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  width: 25%;
  z-index: 999;
}

.hover-info-message:hover .payment-list-tooltip {
  display: block !important;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
}

.outline-bg-primary {
  border: 1px solid #305FF0;
  color: #305FF0;
  background: #fff;
}

.outline-bg-warning {
  border: 1px solid #f9bc07;
  color: #f9bc07;
  background: #fff;
}

.outline-bg-secondary {
  border: 1px solid #515151;
  color: #23252D;
  background: #fff;
}


.outline-bg-success {
  border: 1px solid #198754;
  color: #198754;
  background: #fff;
}

.outline-bg-danger {
  border: 1px solid #F05252;
  color: #F05252;
  background: #fff;
}

.outline-bg-purple {
  border: 1px solid #9833BB;
  color: #9833BB;
  background: #fff;
}

.outline-bg-orange {
  border: 1px solid #F3893D;
  color: #F3893D;
  background: #fff;
}

.empty-card-body {
  /* max-height: 34vw; */
  height: calc(100vh - 175px);
  overflow-y: auto;
  overflow-x: hidden;
}

.main-parent-f-600 td {
  font-weight: 600 !important;
}

.org-card-body-gst-rate {
  /* max-height: 34vw; */
  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.p-datatable-reorderablerow-handle.pi.pi-bars {
  font-size: 12px;
}

.finsights-data-table.last-record-td tr:last-child td {
  border-bottom: 0 !important;
}

.join-sel-inp input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.join-sel-inp input:focus-within {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px;
  box-shadow: none !important;
}

.join-sel-inp .select-inp .select-row-revese .MuiInputBase-fullWidth:focus-within {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #3060f08e !important;
  outline-offset: -2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
}

.join-sel-inp .select-inp .select-row-revese .form-control:focus-within {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px;
  box-shadow: none !important;
}

.join-sel-inp .select-inp.inp-grp-first .select-row-revese .form-control:focus-within {
  outline-style: solid !important;
  outline-width: 0px !important;
  outline-color: transparent !important;
  outline-offset: 0px;
  box-shadow: none !important;
}

.join-sel-inp .select-inp.inp-grp-first .select-row-revese .MuiInputBase-fullWidth:focus-within {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #3060f08e !important;
  outline-offset: -2px;
  border-top-left-radius: 0.1525rem !important;
  border-bottom-left-radius: 0.1525rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none;
}

.join-sel-inp .select-inp .select-row-revese.form-control:focus-within {
  box-shadow: none !important;
}

.join-sel-inp .select-inp .select-row-revese input:focus-within {
  box-shadow: none !important;
  outline-color: transparent !important;
  outline-width: 2px !important;
}



/* .join-sel-inp input:focus-visible {

  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px;
  box-shadow: none;

} */

.large-sel-inp-text .select-row-revese input {
  font-weight: 600;
  font-size: 18px;
}

.large-sel-inp-text .form-control .MuiInputBase-fullWidth.MuiInputBase-formControl {
  padding-top: 5.35px;
  padding-left: 0;
  min-width: 125px;
  padding-right: 35px !important;

}

.large-sel-inp-text .MuiAutocomplete-endAdornment {
  right: 5px !important;
  top: calc(50% - 15px) !important;

}

.large-sel-inp-text .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator {
  width: 32px;
  height: 32px;
  line-height: 12px;
}

.input-tooltip-msg {
  position: absolute;
  background: #4B4D53;
  border-radius: 4px;
  color: #fff;
  padding: 7px;
  padding-top: 0;
  padding-bottom: 0;
  display: none;
  margin-top: 3px;
  z-index: 1;

}

.input-hover-tooltip:hover .input-tooltip-msg,
.input-hover-tooltip:focus-within .input-tooltip-msg {
  display: block;
}


.auto-width-input {
  min-width: 100px;
  /* Set a minimum width */
  transition: width 0.2s;
  /* Add a smooth transition for resizing */
}

/* .auto-width-input input {
  width: auto !important;

} */

/* .auto-width-input input[value] {
  width: calc(1ch * 8) !important;
} */

.large-sel-inp-text .auto-width-input input:focus {
  width: auto !important;

}

.vch-create-sel-inp .select-row-revese.form-control {
  padding: 0;
  border: 0 !important;
}

.vch-create-sel-inp .select-row-revese.form-control input {
  padding-top: 0 !important;
}

.vch-create-sel-inp fieldset {
  border: 0 !important;
}

.rounded-dt-radius .p-datatable .p-datatable-thead tr th:first-child {
  border-top-left-radius: 8px;
}

.rounded-dt-radius .p-datatable .p-datatable-thead tr th:last-child {
  border-top-right-radius: 8px;
}


/* .rounded-dt-radius .p-datatable .p-datatable-tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.rounded-dt-radius .p-datatable .p-datatable-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
} */

.pagination-m-p-remove .p-paginator {
  padding-right: 10px;
  border-top: 0px solid #d3d4d69c !important;
}

.pagination-m-p-remove .p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2rem;
  height: 2rem;
}

.no-action-btns .p-datatable .p-datatable-tbody tr td.dt-action-btns button {
  opacity: 0;
}

.no-action-btns .p-datatable .p-datatable-tbody tr:hover td.dt-action-btns button {
  opacity: 1;
}

.no-action-btns .p-datatable .p-datatable-tbody tr:focus-visible td.dt-action-btns button {
  opacity: 1;
}

.no-action-btns .p-datatable .p-datatable-tbody tr:focus-within td.dt-action-btns button {
  opacity: 1;
}

.no-action-btns .p-datatable .p-datatable-tbody tr:focus td.dt-action-btns button {
  opacity: 1;
}

.pagination-m-p-remove .p-paginator .p-paginator-first,
.pagination-m-p-remove .p-paginator .p-paginator-prev,
.pagination-m-p-remove .p-paginator .p-paginator-next,
.pagination-m-p-remove .p-paginator .p-paginator-last {
  min-width: 2rem;
  height: 2rem;
}

.no-dt-scroll .p-datatable-wrapper::-webkit-scrollbar {
  width: 0;
}

.tn-list-card-body .p-datatable-wrapper {

  height: calc(100vh - 305px);
}

.org-card-body-ledger-right {
  /* max-height: 34vw; */
  height: calc(100vh - 265px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-vch {
  height: calc(100vh - 170px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-vch-primary {
  height: calc(100vh - 173px);
  overflow-y: auto;
  overflow-x: hidden;
}

.h-29px {
  height: 29px;
}

.w-14ch {
  width: 14ch;
}

.p-dialog.list-view-dlg-screen {
  height: 86%;
  max-height: 100%;
  margin: 0;
  margin-right: 24px;
}

.p-dialog.list-view-dlg-screen .p-dialog-content {
  padding: 12px;
  padding-top: 0;
}

.card-absolute {
  position: absolute;
  top: 91px;
  width: 75%;
  z-index: 1;
  transition: width 0.3s ease-in-out;
}


.right-hide {
  right: -75%;
  width: -75%;
  transition: width 0.3s ease-in-out;
  -webkit-transform: translate3d(25%, 0, 0);
  transform: translate3d(25%, 0, 0);
}


.left-hide {
  right: 24px;
  transition: width 0.3s ease-in-out;
}

.h-38 {
  height: 38px;
}

.ptb-12 {
  padding-top: 17px;
  padding-bottom: 16px;
}

.v-middle {
  vertical-align: middle;
}

.l-h-0 {
  line-height: 0;
}

.left-shadow {
  box-shadow: -10px 0px 9px -7px #e1e0e0;
}

.view-content-border {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.list-content-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pr-24 {
  padding-right: 24px;
}

.border-c {
  border-color: #d3d4d6;
}

/* .row {
  display: flex;
} */

.left-column,
.right-column {
  transition: width 0.3s ease-in-out;
  overflow: hidden;
}

.left-column {
  width: 33.33333333%;
  transition: width 0.3s ease-in-out;
}

.left-column-50 {
  width: 50%;
  transition: width 0.3s ease-in-out;
}

.left-column-50.full {
  width: 100%;
}

.left-column.full {
  width: 100%;
}

.right-column {
  width: 66.66666667%;

}

.d-block-right {
  margin-left: 0%;
}

.d-none-right {
  left: 100rem;
  position: absolute;
}

.p-8-24 .p-datatable .p-datatable-thead tr th {
  padding: 5px 24px;
  text-transform: inherit;
}

.p-8-24 .p-datatable .p-datatable-tbody tr td {
  padding: 8px 24px;
}

.finsights-data-table.filters-dt .p-datatable .p-datatable-thead th.p-sortable-column .p-column-filter-menu-button .pi-filter-icon.pi-filter {
  font-size: 10px;
}

.finsights-data-table.filters-dt .p-datatable .p-datatable-thead th.p-sortable-column .p-column-filter-menu-button .pi-filter-icon.pi-filter:before {
  font-family: uicons-bold-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f5a7";
  color: #a3a4a7;
  font-size: 10px;
}

/* .filters-dt .p-column-filter-menu {
  opacity: 0;
  margin-left: 8px;
  margin-right: auto;
} */

.filters-dt .p-column-filter-menu button {
  opacity: 0;
  margin-left: 8px;
  margin-right: auto;
}

.filters-dt .p-column-filter-menu button.p-column-filter-menu-button-active {
  opacity: 1;
  margin-left: 8px;
  margin-right: auto;
  background: transparent;
}

.filters-dt .p-column-filter-menu button.p-column-filter-menu-button-active span:before {
  color: #305FF0 !important;
}

.finsights-data-table.filters-dt .p-datatable .p-datatable-thead th.p-sortable-column .p-sortable-column-icon.pi-fw.pi-sort-alt:before {
  font-family: uicons-solid-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\fa3f";
  color: #a3a4a7;
  font-size: 10px;

}

.finsights-data-table.filters-dt .p-datatable .p-datatable-thead th.p-sortable-column .p-sortable-column-icon.pi-sort-amount-up-alt:before {
  font-family: uicons-solid-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f313";
  color: #a3a4a7;
  font-size: 14px;

}

.finsights-data-table.filters-dt .p-datatable .p-datatable-thead th.p-sortable-column .p-sortable-column-icon.pi-sort-amount-down:before {
  font-family: uicons-solid-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f30b";
  color: #a3a4a7;
  font-size: 14px;

}

.finsights-data-table.filters-dt .p-datatable .p-datatable-thead tr th:hover .p-column-filter-menu {
  opacity: 1;
}

.finsights-data-table.filters-dt .p-datatable .p-datatable-thead tr th:hover .p-column-filter-menu button {
  opacity: 1;
}

.finsights-data-table.filters-dt .p-column-filter-menu-button.p-column-filter-menu-button-open:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.finsights-data-table.filters-dt .p-column-filter-menu-button:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.finsights-data-table.hover-list-dt .p-datatable-tbody tr:first-child td.td-active {
  background: #EBF0FF !important;
  border-left: 4px solid #305FF0 !important;
}

.finsights-data-table.hover-list-dt .p-datatable-tbody tr td:hover {
  background: #EBF0FF !important;
  border-left: 4px solid #d3d4d6 !important;
}

.finsights-data-table.filters-dt.list-hover .p-datatable-tbody tr td {
  background: transparent;
  border-left: 4px solid transparent;
}



.btn-tabs-list .btn {
  border-bottom: 3px solid transparent;
  border-radius: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;
}

.btn-tabs-list .btn:focus-visible {
  box-shadow: none !important;
  outline: 0;
  border-bottom: 3px solid #305FF0 !important;
}

.btn-tabs-list .btn.active {
  /* border-bottom: 3px solid #305FF0; */
  border-radius: 0;
}

.btn-tabs-list .btn.active::after {
  content: '';
  border-bottom: 3px solid #305FF0;
  width: 100%;
  display: block;
  position: absolute;
  /* top: 37px; */
  bottom: -2px;
}

.tab-hrs-line {
  border-color: #e8eaeb;
  opacity: 1;
}

.me-24 {
  margin-right: 24px;
}

.pt-14 {
  padding-top: 14px;
}

.large-table-focus .p-datatable .p-datatable-tbody>tr.p-highlight {
  background: #EEF2FF;
  background-color: transparent !important;
  outline-style: solid;
  outline-style: solid;
  outline-width: 3px;
  outline-color: #3060f08e;
  outline-offset: -2px;
  border-radius: 3px;
}

.small-table-focus .p-datatable .p-datatable-tbody>tr.p-highlight {
  background: #ebf0ff;
  background-color: #ebf0ff !important;
  outline-style: solid;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
  outline-offset: -2px;
  border-radius: 0px;
  box-shadow: 5px 0px #305FF0 inset;
}

.small-table-focus-fade-out .p-datatable .p-datatable-tbody>tr.p-highlight {
  background: #ebf0ff;
  background-color: #ebf0ff !important;
  outline-style: solid;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
  outline-offset: -2px;
  border-radius: 0px;
  box-shadow: 5px 0px #d3d4d6 inset;
}

/* .small-table-focus .p-datatable .p-datatable-tbody>tr.p-highlight td {
  background: #EBF0FF !important;
  border-left: 4px solid #305FF0 !important;
}


.small-table-focus .p-datatable .p-datatable-tbody>tr td {
  border-left: 4px solid transparent !important;
}

.small-table-focus .p-datatable .p-datatable-tbody>tr.p-highlight:focus-visible {
  box-shadow: none !important;
  outline: 0 !important;
}

.small-table-focus .p-datatable .p-datatable-tbody>tr.p-highlight:focus {
  box-shadow: none !important;
  outline: 0 !important;
  background-color: #ebf0ff !important;
} */

@keyframes fadeOutBorder {
  0% {
    box-shadow: inset 0px 0 0px 3px #305FF0;
  }

  100% {
    box-shadow: none;
  }
}

.fading-border {
  box-shadow: inset 0px 0 0px 3px #305FF0;
  animation: fadeOutBorder 5s forwards;
  /* 'forwards' ensures the end state is retained */
}

#clickble-id:focus-visible {
  outline-style: solid;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
  outline-offset: -2px;
  border-radius: 0px;
}

.info-ps i.fi-br-info {
  position: absolute;
  left: 2px;
  top: 19px;
}

.menu-ps-add {
  position: absolute;
  bottom: 10px;
  right: 0;
}

.line-h-0 {
  line-height: 0;
}

.text-color-b {
  color: #b1b1b1;
}

.tds-tcs-info-ps {
  position: absolute;
  top: 20px;
  left: 125px;
}

.dark-theme-logo {
  display: none;
}

#dark-theme .normal-theme-logo {
  display: none;
}

#dark-theme .dark-theme-logo {
  display: block;
}

#dark-theme .card.figma-bg.dark-card-org {
  background: transparent !important;
}

.td-main-only-border {
  border-bottom: 1px solid #d3d4d6 !important;
}

.td-zero-main-only-border {
  border-bottom: 0px solid #d3d4d6 !important;
}

.gst-analysis-long-table tr td {
  border-bottom: 1px solid #d3d4d6 !important;
}

.gst-analysis-long-table tr:last-child td {
  border-bottom: 0px solid #d3d4d6 !important;
}

.gst-analysis-long-table tr td:first-child {
  border-right: 1px solid #d3d4d6 !important;
}

.gst-analysis-long-table tr:first-child td:first-child {
  border-top-left-radius: 0.375rem;
}

.gst-analysis-long-table tr:first-child td:last-child {
  border-top-right-radius: 0.375rem;
}

.p-datepicker .p-monthpicker-month {
  font-size: 14px;
}

.btn-list-type-ul .btn-list-ul-padding button.btn-link.text-disabled {
  cursor: no-drop;
}

button.btn-link:disabled,
button.btn-link[disabled] {
  opacity: 0.4;
}

.non-assets-card-body {
  height: calc(100vh - 185px);
  overflow-y: auto;
  overflow-x: hidden;
}

.main-module-li.non-assets-list li.list-group-item.active button {
  border-right: 4px solid #305FF0 !important;
}

.b-r-thick-line {
  border-right: 2px solid #e8eaed !important;
}

.search-list {
  background-color: #fff;
  list-style: none;
  font-size: 14px;
  overflow: auto;
}

.header-d-none .p-dialog-header {
  display: none !important;
}

.global-search-dlg.p-dialog {
  margin-top: 70px;
}

.global-search-dlg .p-dialog-content {
  border-radius: 5px !important;
}

.search-global-focus input {
  border: 0;
}

.search-global-focus input:focus-within {
  box-shadow: none !important;
}

.search-list li {
  padding: 15px;
  border-radius: 8px;
}

.search-list li:hover {
  background: #F7F8FA;
  border-radius: 8px;
}


.search-list li:focus-visible {
  background: #F7F8FA;
  border-radius: 8px;
  outline: 0;
}

.ledger-statement-border {
  border-bottom: 1px solid #d3d4d6 !important;
}

.figma-bg-op {
  background-color: #f7f8fa !important;
}

.bg-danger-label {
  border: 1px solid #F05252;
  background: #FDF2F2;
  border-radius: 15px;
  color: #F05252;
}

.right-active-btns .btn {
  border-radius: 0;
  padding-left: 0;
}

.right-active-btns .btn.active {
  border-right: 4px solid #305FF0 !important;
}

.f-w-900 {
  font-weight: 900;
}

.op-1 {
  opacity: 1;
}

.icon-rotate-90 {
  transform: rotate(270deg);
  display: inline-block;
  margin-right: 5px;
}


.finsights-data-table.hover-list-dt-row .p-datatable-tbody tr.active {
  background: #EBF0FF !important;
  border-left: 4px solid #305FF0 !important;
}

.finsights-data-table.hover-list-dt-row .p-datatable-tbody tr:hover {
  background: #EBF0FF !important;
  border-left: 4px solid #305FF0 !important;
}

.finsights-data-table.filters-dt-row.list-hover .p-datatable-tbody tr {
  background: transparent;
  border-left: 4px solid transparent;
}

.select-36-inp .select-row-revese .MuiInputBase-fullWidth {
  height: 36px;
  border-radius: 8px;
}

.select-36-inp .form-control {
  border-radius: 8px;
}

.reconcile-progress .p-progressbar {
  border-radius: 25px;
  height: 10px;
}

.reconcile-progress .p-progressbar-label {
  visibility: hidden;
}

.no-header-dlg .p-dialog-header {
  display: none;
}

.no-header-dlg .p-dialog-content {
  border-radius: 8px;
}

.dashboard-footer-bg .list-group-horizontal>.list-group-item:last-child:not(:first-child) {
  border-top-right-radius: 0 !important;
}

.right-modal-content-dlg {
  /* margin-top: 75px !important; */
  /* margin-top: -50px !important; */
  margin-top: -59px !important;
  margin-right: 24px !important;
}

.right-modal-dlg-170 {

  margin-top: -48px !important;

}

.right-modal-content-dlg .p-dialog-content {
  /* height: calc(100vh - 173px); */
  height: calc(100vh - 169px);
  border-bottom-right-radius: 16px !important;
  border-bottom-left-radius: 0 !important;

}

.right-modal-dlg-170 .p-dialog-content {
  height: calc(100vh - 170px);
  border-bottom-right-radius: 16px !important;

}

.right-modal-content-dlg .p-dialog-header {
  border-top-left-radius: 0px;
  line-height: 1;
}

.right-modal-content-dlg {
  margin: 0;
}

.right-modal-content-dlg .p-dialog-footer {
  box-shadow: 0 -6px 10px -10px #d0d0d0;
  border-top: 1px solid #e8eaed48;
}

.right-modal-content-dlg .p-dialog-footer {
  border-bottom-left-radius: 0;
}



.custom-mask-bg {
  background: transparent !important;
  top: 70px;
}

.right-modal-content-dlg.p-dialog-maximized {
  height: auto !important
}

.right-modal-content-dlg.p-dialog-maximized .p-dialog-content {
  height: calc(100vh - 260px) !important;
}

.right-modal-content-dlg.p-dialog-maximized .p-dialog-header {
  border-top-left-radius: 16px !important;
}

.right-modal-content-dlg.p-dialog-maximized {
  margin-left: 24px !important;
}

.right-modal-content-dlg.p-dialog-maximized .p-dialog-footer {
  border-bottom-left-radius: 16px !important;
}

.right-modal-content-dlg.right-modal-content-dlg-footer-not .p-dialog-content {
  height: calc(100vh - 183px);

}

.right-modal-content-dlg.right-modal-content-dlg-footer-not.p-dialog-maximized .p-dialog-content {
  height: calc(100vh - 183px) !important;
  border-bottom-left-radius: 16px !important;
}

.right-modal-content-dlg.right-modal-content-dlg-footer-not .p-dialog-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 16px;
}

.b-r-8-imp {
  border-radius: 8px !important;
}

.b-t-l-r {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.right-modal-content-dlg .p-dialog-header .pi-window-maximize:before,
.dashboard-ledger-display-dlg .p-dialog-header .pi-window-maximize:before {
  font-family: uicons-bold-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f51D";
}

.right-modal-content-dlg .p-dialog-header .pi-window-minimize:before,
.dashboard-ledger-display-dlg .p-dialog-header .pi-window-minimize:before {
  font-family: uicons-regular-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f436";
}

.text-second-variant {
  color: #A855F7;
}

.text-third-variant {
  color: #FFA84A;
}

.primary-top-border {
  border-top: 3px solid #305FF0 !important;
}

.primary-sec-border {
  border-top: 3px solid #A855F7 !important;
}

.primary-third-border {
  border-top: 3px solid #FFA84A !important;
}

.vertical-line-path {
  border-left: 2px solid #a3a4a7;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
}

.p-dialog.right-modal-content-dlg {
  box-shadow: -10px 0px 9px -7px #e1e0e0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-12-24 .p-datatable .p-datatable-thead tr th {
  padding: 16px 24px;
  text-transform: capitalize;
}

.dotted-card {
  border: 1px dashed #305FF0;
}

.border-tp {
  border-color: transparent;
}

.border-dashed {
  border-style: dashed;
}

.hr-d-color {
  border-top: 1px solid #d3d4d6;
  opacity: 1;
}

.figma-bg-2 {
  background: #F7F9FC;
}

.alert-success-bg {
  background: #DEF7EC;
}

.alert-danger-bg {
  background: #FDE8E8;
}


.up-1px-border {
  border: 1px dashed #305FF0;
}

.att-icon-circle {
  line-height: 40px;
  width: 40px;
  height: 40px;
  margin: auto;
  background: #305ff0;
  border-radius: 50%;
}

.att-icon-circle i {
  color: #fff !important;
  font-size: 14px;
}

.icon-bg-att {
  line-height: 35px;
  width: 40px;
  height: 40px;
  margin: auto;
  background: #E2E3E4;
  border-radius: 8px;
  text-align: center;
}

.att-progress-h .p-progressbar {
  height: 10px;
}

.att-progress-h .p-progressbar .p-progressbar-label {
  visibility: hidden;
}

.att-del {
  visibility: hidden;
}

.att-del-icon-display:hover .att-del {
  visibility: visible;
}

.open-mode button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
}

.outline-primary-btn-focus:focus-visible {
  background: transparent;
  color: #305ff0;
  border-color: transparent;
}

.p-dialog-content::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.small-dt-text .p-datatable-thead tr th {
  text-transform: capitalize !important;
}

.b-r-8-dt .p-datatable-thead tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.b-r-8-dt .p-datatable-thead tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

.finsights-data-table.col-span-table .p-datatable .p-datatable-thead tr:first-child th.line-0 {
  border-right: 0 !important;
}

.finsights-data-table.col-span-table .p-datatable .p-datatable-thead tr th.line-apply {
  border-right: 1px solid #d3d4d69c !important;
}

.text-color-c8 {
  color: #c8c9cb;
}

.text-color-danger {
  color: #f05252;
}

.text-color-danger-imp {
  color: #f05252 !important;
}

.only-td-line-rem .p-datatable-tbody tr td {
  border-right: 0 !important;
}

.header-remove .p-datatable-thead {
  display: none;
}

.header-remove .p-datatable-tbody tr td {
  border-left: 0;
  border-right: 0;
}

.finsights-data-table.active-row .p-datatable .p-datatable-tbody>tr:first-child td {
  background-color: #EBF0FF !important;
}

.items-new-table.reports-main-table tr td {
  border-top: 0;
}

.items-new-table.reports-main-table tr th {
  border-bottom: 1px solid #d3d4d6;
}

.items-new-table.reports-main-table tr.gross-profit td {
  border-top: 1px solid #d3d4d6;
  border-bottom: 1px solid #d3d4d6;
}

.items-new-table.reports-main-table tr.net-profit-row td {
  border-top: 1px solid #d3d4d6;
}

.net-profit-bg {
  background-color: #F3FAF7 !important;
}

.grs-profit-bg {
  background-color: #F3FAF7 !important;
}

.grs-profit-bg-1 {
  background-color: #F3FAF7 !important;
  font-weight: 600;
}

.grs-profit-bg-1-nobg {
  font-weight: bold !important;
}

.reports-main-table tbody tr:hover td {
  background-color: #EBF0FF !important;
  cursor: pointer;
}

.open-sub-group-bg td {
  background-color: #f2f5ff;
}

.active-open td {
  background-color: #EBF0FF !important;
}

.active-open td p {
  color: #305FF0;
}

.reports-main-table tr td.ps-32 {
  padding-left: 32px;
}

.reports-main-table tr td.ps-48 {
  padding-left: 48px;
}

.reports-main-table tr.sub-hover:hover td p {
  color: #305FF0 !important;
}

.finsights-data-table.no-pagination-lines tbody tr:last-child td {
  border-bottom: 0 !important;
}

.finsights-data-table.no-pagination-lines tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.finsights-data-table.no-pagination-lines tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}


.finsights-data-table.opening-total tbody tr:first-child td:first-child {
  font-weight: 600;
}

.finsights-data-table.opening-total tbody tr:first-child td:last-child {
  font-weight: 600;
}


.finsights-data-table.opening-total tbody tr:first-child td:nth-child(2),
.finsights-data-table.opening-total tbody tr:first-child td:nth-child(3),
.finsights-data-table.opening-total tbody tr:last-child td:nth-child(2),
.finsights-data-table.opening-total tbody tr:last-child td:nth-child(3) {
  opacity: 0;
}



.finsights-data-table.opening-total tbody tr:last-child td:first-child {
  font-weight: 600;
}

.finsights-data-table.opening-total tbody tr:last-child td:last-child {
  font-weight: 600;
}

.td-padding-sel .p-datatable-tbody tr td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 24px !important;
}

.td-padding-sel .p-datatable-thead tr th:nth-child(2) {
  padding-left: 44px !important;
}


.address-cards-focus .card:focus-visible,
.address-cards-focus .card:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
  outline: none;
}

.remove-menu-header .p-submenu-header {
  display: none;
}

.menu-bar-style.p-menu {
  border-radius: 8px;
  padding: 12px;
}

.menu-bar-style.p-menu button:hover {
  background-color: #EBF0FF;
}

.outline-group .btn-outline-light:hover {
  border-color: #d3d4d6;
}

.b-r-d-line {
  border-right: 1px solid #d3d4d6;
}

.items-new-table.compare-table tbody tr td {
  border-right: 1px solid #d3d4d6;
  padding: 16px;
}

.items-new-table.compare-table tbody tr td:first-child {
  background-color: #f7f8fa;
}

.items-new-table.compare-table tbody tr:first-child td {
  border-top: 0;
}

.items-new-table.compare-table tbody tr td:last-child {
  border-right: 0;
}

.items-new-table.compare-table tbody tr:first-child td:first-child {
  border-top-left-radius: 8px;
}

.items-new-table.compare-table tbody tr:first-child td:last-child {
  border-top-right-radius: 8px;
}

.align-table-middle tbody tr td {
  vertical-align: middle;
}

.items-new-table.reports-main-table.left-table tr th {
  border-top-right-radius: 0;
}

.items-new-table.reports-main-table.left-table tr:last-child td {
  border-radius: 0;
}

.items-new-table.reports-main-table.right-table tr:last-child td {
  border-radius: 0;
}

.items-new-table.reports-main-table.right-table tr th {
  border-top-left-radius: 0;
}

.items-new-table.reports-main-table.left-table tr.net-profit-row td:first-child {
  border-bottom-left-radius: 6px;
}

.items-new-table.reports-main-table.right-table tr.net-profit-row td:last-child {
  border-bottom-right-radius: 6px;
}

.inp-group-join input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: transparent;
}

.right-modal-content-footer-dlg {
  margin-top: -59px !important;
  margin-right: 24px !important;
}

.right-modal-content-footer-dlg .p-dialog-header {
  border-top-left-radius: 0px;
  line-height: 1;
  padding-top: 26px;
  padding-bottom: 26px;
}

.right-modal-content-footer-dlg .p-dialog-content {
  height: calc(100vh - 260px);
  border-bottom-right-radius: 16px !important;
  border-bottom-left-radius: 0 !important;
}

.right-modal-content-footer-dlg {
  margin: 0;
}

.right-modal-content-footer-dlg .p-dialog-footer {
  box-shadow: 0 -6px 10px -10px #d0d0d0;
  border-top: 1px solid #e8eaed48;
}

.right-modal-content-footer-dlg .p-dialog-footer {
  border-bottom-left-radius: 0;
}

.p-dialog.right-modal-content-footer-dlg {
  box-shadow: -10px 0px 9px -7px #e1e0e0;
}

.collapse-expand button:hover {
  border-color: #d3d4d6;
  color: #23252D;
  background: #f7f8fa;
}

.dr-cr-light-badge {
  background: #f7f8fa;
  border: 1px solid #d3d4d6;
  border-radius: 25px;
  padding: 5px;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 1;
}

.amount-bg {
  background: #E2E3E4;
  padding: 10px;
  border-radius: 8px;
}

.p-total-16 {
  padding: 16px;
}

.inv-card-body-purchase-no-foot {
  height: calc(100vh - 183px);
}


.right-modal-content-footer-dlg-n-inp {
  margin-top: -59px !important;
  /* margin-top: -47px !important; */
  margin-right: 24px !important;
}

.right-modal-content-footer-dlg-n-inp .p-dialog-header {
  border-top-left-radius: 0px;
  line-height: 1;
  padding-top: 20px;
  padding-bottom: 20px;
}

.right-modal-content-footer-dlg-n-inp .p-dialog-content {
  /* height: calc(100vh - 251px); */
  height: calc(100vh - 248px);
  border-bottom-right-radius: 16px !important;
  border-bottom-left-radius: 0 !important;
}

.right-modal-content-footer-dlg-n-inp {
  margin: 0;
}

.right-modal-content-footer-dlg-n-inp .p-dialog-footer {
  box-shadow: 0 -6px 10px -10px #d0d0d0;
  border-top: 1px solid #e8eaed48;
}

.right-modal-content-footer-dlg-n-inp .p-dialog-footer {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 15px;
}

.p-dialog.right-modal-content-footer-dlg-n-inp {
  box-shadow: -10px 0px 9px -7px #e1e0e0;
}

.new-temp-button {
  position: absolute;
  z-index: 999;
  bottom: 10px;
  right: 10px;
}

.new-temp-button button {
  font-size: 12px;
  border-radius: 25px;
  padding: 2px 12px;
}

.disabled-menu button {
  color: #E2E3E4 !important;
  pointer-events: none;
}

.double-select .first-sel .select-row-revese.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.double-select .second-sel .select-row-revese.form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.double-select .first-sel .select-row-revese .form-control:focus-visible {

  /* box-shadow: inset 0 0 0 2px #3060f08e !important; */
  background-color: #EBF0FF !important;
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -1px;
  /* box-shadow: 0 0 0 2px #3060f08e !important;
    outline: 0; */
  border-radius: 3px;


}


.double-select input:focus-within {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px;
  box-shadow: none !important;
}

.double-select .select-inp .form-control:focus-within {
  box-shadow: none !important;
}

.double-select .select-inp.first-sel .select-row-revese .form-control .MuiInputBase-fullWidth:focus-within {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #3060f08e !important;
  outline-offset: -2px;
  box-shadow: none !important;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.double-select .select-inp.second-sel .select-row-revese .form-control .MuiInputBase-fullWidth:focus-within {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #3060f08e !important;
  outline-offset: -2px;
  box-shadow: none !important;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* .double-select .select-inp .select-row-revese .form-control:focus-within {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px;
  box-shadow: none !important;
} */



.double-select .select-inp .select-row-revese.form-control:focus-within {
  box-shadow: none !important;
}

.double-select .select-inp .select-row-revese input:focus-within {
  box-shadow: none !important;
  outline-color: transparent !important;
  outline-width: 2px !important;
}

.danger-label {
  border: 1px solid #F05252;
  background: #FDF2F2;
  border-radius: 15px;
  color: #F05252;
  padding: 2px 8px;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
  background-color: #EBF0FF;
}

.p-datatable-thead {
  background-color: transparent !important;
}

.items-new-table.first-cell-highlight tr td:first-child {
  background: #f9f9f9;
}

.items-new-table.first-cell-highlight tr td {
  border-right: 1px solid #d3d4d6;
}

.items-new-table.first-cell-highlight tr td:last-child {
  border-right: 0px solid #d3d4d6;
}

.items-new-table.first-cell-highlight tr th {
  border-right: 1px solid #d3d4d6;
}

.items-new-table.first-cell-highlight tr th:last-child {
  border-right: 0px solid #d3d4d6;
}

.p-14-normal-table thead th {
  padding: 14px;
}

.p-14-normal-table tbody td {
  padding: 14px;
}

.dropdown-toggle-split {
  border-left: 1px solid #d3d4d6;
}

.dropdown-menu {
  padding: 12px;
}

.dropdown-menu .dropdown-item {
  margin-bottom: 5px;
  padding: 5px 12px;
  font-size: 14px;
}

.dropdown-menu .dropdown-item:hover {
  background: #EBF0FF;
  color: #305FF0;
  border-radius: 8px;
}

.plain-label {
  background: #F7F8FA;
  border-radius: 15px;
  border: 1px solid #d3d4d6;
  color: #6f7380;
  padding: 4px 16px;
}

.p-14-table tbody tr td {
  padding: 14px 12px;
}

.p-14-table thead tr th {
  padding: 14px 12px;
}

.p-14-table-tbody tbody tr td {
  padding: 14px 12px;
}


.p-datatable .p-datatable-tbody tr.p-datatable-emptymessage {
  border-bottom: 1px solid #d3d4d6a8;
}

.p-datatable .p-datatable-tbody tr.p-highlight.p-selectable-row {
  background: #ebf0ff;
  background-color: #ebf0ff !important;
  outline-style: solid;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
  outline-offset: -2px;
  border-radius: 0px;
  box-shadow: 5px 0px #305FF0 inset;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  width: 2rem;
  height: 2rem;
  min-width: inherit;
}

.no-footer-header-inp .p-dialog-content {
  height: calc(100vh - 182px);
}

.right-modal-content-dlg.no-footer-header-inp {
  margin-top: -60px !important;
}

.p-dialog.no-footer-header-inp .p-dialog-header {
  padding-top: 26px;
  padding-bottom: 26px;
}


.inside-percentage {
  font-size: 6px;
  position: absolute;
  top: 13px;
  left: 17px;
}

.dp-menu-chevron {
  opacity: 0;
}

.has-dp-hover:hover .dp-menu-chevron {
  opacity: 1;
}

.has-dp-hover:focus .dp-menu-chevron {
  opacity: 1;
}

.dp-menu-chevron button {
  position: absolute;
  top: -5px;
  right: 0;
}

.finsights-data-table.no-th-elipsis .p-datatable-thead .p-sortable-column .p-column-header-content span.p-column-title {
  max-width: initial;
}

.f-6-imp {
  font-weight: 600 !important;
}

.th-500-dt .p-datatable-thead tr th {
  font-weight: 500 !important;
}

.org-card-body-footer-rem {
  height: calc(100vh - 182px);
  overflow-y: auto;
  overflow-x: hidden;
}

.finsights-data-table.p-12-data-table .p-datatable-thead tr th {
  padding: 12px 24px !important;
}

.w-35 {
  width: 30%;
}

.b-l-t-b-8 {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.prime-sel .p-dropdown {
  height: 38px;
  border-radius: 8px;
  background: #fff;
}

.prime-sel .p-dropdown .p-inputtext {
  line-height: 1;
}

.first-sel.prime-sel .p-dropdown {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.second-sel.prime-sel .p-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.second-sel.prime-sel .p-dropdown {
  margin-left: -1px;
}

.prime-sel .p-dropdown .p-dropdown-trigger {
  height: 37px;
}

.l-h-32 {
  line-height: 32px;
}

.mb-26 {
  margin-bottom: 26px;
}

/* button {
  border-radius: 8px !important;
} */
.finsights-data-table.p-16-data-table .p-datatable-thead tr th {
  padding: 16px 16px !important;
  font-size: 12px;
  text-transform: capitalize !important;
}

.p-16-data-table .p-datatable-tbody tr td {
  padding: 16px 16px !important;
}

.finsights-data-table-tree.tree-table-no-lines .p-treetable-tbody>tr>td {
  border-bottom: 0;
}



.finsights-data-table-tree .p-treetable-tbody tr td .pi-chevron-down:before {
  font-family: uicons-bold-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f30b";
}

.finsights-data-table-tree .p-treetable-tbody tr td .pi-chevron-right:before {
  font-family: uicons-bold-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f30e";
}

.tree-rounded .p-treetable-thead tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.tree-rounded .p-treetable-thead tr:last-child th:last-child {
  border-top-right-radius: 8px;
}


.tree-rounded .p-treetable-tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.tree-rounded .p-treetable-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.b-b-r-r .bottom-15 {
  border-bottom-right-radius: 15px;
}

.button-angle-icn-last i {
  position: absolute;
  right: 10px;
  top: 16px;
}

.finsights-data-table-tree.tree-table-p-l-th .p-treetable-thead {
  display: none;
}

.finsights-data-table-tree.tree-table-p-l-th .p-treetable-header {
  border-top: 0;
  text-align: center;
  font-size: 12px;
  color: #515151;
  font-weight: 500;
}

.finsights-data-table-tree.tree-table-p-l-th.first-left .p-treetable-header {
  border-top-left-radius: 8px;
}

.finsights-data-table-tree.tree-table-p-l-th.second-left .p-treetable-header {
  border-top-right-radius: 8px;
}

.selected-table-row-highlight {
  background-color: #EBF0FF !important;
}

.recon-input-padding {
  padding-right: 4.5rem;
}

.mb-1-8 {
  margin-bottom: 18px;
}

.select-inp .p-dropdown:not(.p-disabled):hover {
  border-color: #d3d4d6;
}


.p-dropdown:not(.p-disabled).p-focus {
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -1px;
  box-shadow: none;
  border-color: #3060f08e !important;
}

.p-dropdown:focus-visible,
.p-multiselect:focus-visible {
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -3px;
  box-shadow: none;
  border-color: #3060f08e !important;

}

.first-sel .p-dropdown:not(.p-disabled).p-focus {
  outline-style: solid !important;
  outline-width: 2.5px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px;
  box-shadow: none;
  border-color: #3060f08e !important;
}

.second-sel .p-dropdown:not(.p-disabled).p-focus {
  outline-style: solid !important;
  outline-width: 2.5px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px;
  box-shadow: none;
  border-color: #3060f08e !important;
}


.right-modal-content-dlg.max-dlg-2a.p-dialog-maximized .p-dialog-content {
  height: calc(100vh - 169px) !important;
  border-bottom-left-radius: 16px !important;
}

.show-db-bal {
  position: absolute;
  top: 5px;
  right: 0;
}

.org-card-body-vch-trn {
  height: calc(100vh - 181px);
  overflow-y: auto;
  overflow-x: hidden;
}

.view-trans-list-scroll-trn {
  height: calc(100vh - 170px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-vch-trn-exp {
  height: calc(100vh - 181px);
  overflow-y: auto;
  overflow-x: hidden;
}

.view-trans-list-scroll-exp {
  height: calc(100vh - 171px);
  overflow-y: auto;
  overflow-x: hidden;
}

.ptb-19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}

.ptb-19-20 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}

.btn-outline-light.outline-dark-border:hover,
.btn-outline-light.outline-dark-border:active {
  border-color: #d3d4d6 !important;
}

.p-t-26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}

.ptb-22-24 {
  padding-top: 23px !important;
  padding-bottom: 22px !important;
}

/* .menu-bar-style.p-menu {
  padding: 0 !important;
} */

.p-l-r-16 {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 6px;
  padding-bottom: 6px;
}

.grip-color {
  color: #6e707f;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.b-b-l-r-8-radius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.less-min-padding {
  padding: 5px;
}

.select-dp-no-clr .form-control {
  background: transparent !important;
}


.finsights-data-table-tree .p-treetable-tbody tr.children-item-no-bg td {
  color: #305FF0 !important;
}

.finsights-data-table-tree .p-treetable-tbody tr.f-900-heading td:first-child {
  font-weight: 600;
  font-size: 14px;
}

.right-active-org-btns .btn {
  border-radius: 0;
}

.right-active-org-btns .btn:hover {
  background: #F7F8FA;
}

.right-active-org-btns .btn.active {
  border-right: 4px solid #305FF0 !important;
  background: #ebf0ff;
}

.active-status-label {
  background: #F3FAF7;
  border-radius: 15px;
  border: 1px solid #0E9F6E;
  color: #0E9F6E;
  padding: 4px 16px;
}

.danger-status-label {
  border: 1px solid #F05252;
  background: #FDF2F2;
  border-radius: 15px;
  color: #F05252;
  padding: 4px 16px;
}

.warning-status-label {
  border: 1px solid #C27803;
  color: #C27803;
  ;
  background: #FDF2F2;
  border-radius: 15px;
  padding: 4px 16px;
}

.org-file-btn .btn-file {
  position: relative;
  overflow: hidden;
}

.org-file-btn .btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block;
}

.org-file-btn .file-input-label {
  padding: 0px 10px;
  display: table-cell;
  vertical-align: middle;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.org-file-btn input[readonly] {
  background-color: white !important;
  cursor: text !important;
}

.org-upload-btn-style {
  border-top-right-radius: 0;
  padding: 4px 16px;
  font-size: 14px;
}

.org-img-width {
  width: 55px;
  height: 55px;
  background: #EBF0FF;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
}

.org-settings-scroll {
  height: calc(100vh - 220px);
  overflow-y: auto;
  overflow-x: hidden;
}

.border-table-cells thead tr th {
  border-right: 1px solid #d3d4d6;
}

.border-table-cells tbody tr td {
  border-right: 1px solid #d3d4d6;
}

.border-table-cells thead tr th:last-child {
  border-right: 0;
}

.border-table-cells tbody tr td:last-child {
  border-right: 0;
}

.finsights-data-table.col-span-table.prime-col-span-th .p-datatable .p-datatable-thead tr:first-child th:last-child {
  border-right: 0 !important;
}

.p-column-filter-overlay .p-dropdown-label.p-inputtext {
  font-size: 12px;
  color: #23252D;
  padding: 10px;
}

.p-column-filter-overlay.p-fluid .p-inputtext {
  font-size: 12px;
  padding: 10px;
}

.p-column-filter-buttonbar .p-button.p-button-outlined {
  padding: 6px 16px;
}

.p-column-filter-buttonbar .p-button.p-button-outlined .p-button-label {
  font-size: 14px;
  font-weight: 500;
}

.p-column-filter-buttonbar .p-button {
  padding: 6px 16px;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding-top: 0px;
}

.p-column-filter-overlay .p-dropdown-items .p-dropdown-item {
  font-size: 12px;
}

.p-column-filter-overlay .p-multiselect .p-multiselect-label.p-placeholder {
  font-size: 12px;
  padding: 10px;
}

.p-column-filter-overlay .p-multiselect-trigger-icon {
  font-size: 12px;
}

.overview-message-text {
  display: none;
}

.overview-message:hover .overview-message-text {
  display: block !important;
  background: #000000;
  border-radius: 3px;
  padding: 12px;
  color: #fff;
  width: auto;
  position: absolute;
  font-size: 12px;
}

.finsights-data-table .p-datatable-tbody tr.p-selectable-row td .form-control {
  background: transparent;
}

.right-active-org-btns .btn.btn-link:focus-visible {
  box-shadow: none !important;
  background: #F7F8FA;
}

.p-checkbox-disabled .p-checkbox-box.p-highlight {
  border-color: #d3d4d6;
  background: #F3FAF7;
}

.p-checkbox-disabled .p-checkbox-box .p-checkbox-icon {
  color: #a3a4a7;
}

.view-create-dlg .p-dialog-header {
  padding-top: 26px;
  padding-bottom: 26px;
}

.view-create-dlg .p-dialog-content {
  height: calc(100vh - 185px) !important;
}

.finsights-data-table .p-datatable-tbody tr.p-highlight td.dt-action-btns button {
  opacity: 1;
}

.p-checkbox-disabled .p-checkbox-box.p-disabled {
  background: #d3d4d67a;
  pointer-events: none;
}

.p-checkbox-disabled .p-checkbox-box .p-checkbox-icon {
  background-color: #d3d4d6;
  color: #6f7380;
}

.finsights-data-table.summary-td-last .p-datatable-tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.finsights-data-table.summary-td-last .p-datatable-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.agg-table .p-datatable-thead tr th {
  padding: 6px 24px !important;
}

.search-global-footer {
  border-top: 1px solid #e8eaeb
}

.font-size-18-imp {
  font-size: 18px !important;
}

.field-checkbox .p-checkbox {
  vertical-align: middle;
}

.finsights-data-table.all-lines-rem .p-datatable-tbody tr td {
  border: 0;
  padding: 0;
}

.drag-abs-handler {
  position: absolute;
  right: 37%;
  margin-top: 18px;
}

.drag-abs-handler .pi-bars:before {
  font-family: uicons-solid-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f638";
  color: #a3a4a7;
  font-size: 10px;
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
  box-shadow: none;
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
  box-shadow: none;
}

.p-dialog-header {
  line-height: 24px !important;
}

.ledger-menu-card .p-menu-list {
  padding: 12px;
}

.tree-last-line-rm .p-treetable-tbody tr:last-child td {
  border-bottom: 0;
}

.insights-first-highlight .p-treetable-tbody tr.children-item-first td:first-child {
  color: #305FF0;
}

.finsights-data-table.p-12-tbody .p-datatable-tbody tr td {
  padding: 12px 24px;
}

.finsights-data-table.p-16-tbody .p-datatable-tbody tr td {
  padding: 16px 24px;
}

.finsights-data-table.p-17-tbody .p-datatable-tbody tr td {
  padding: 16.85px 24px;
}

.valid-gstin-success {
  border: 1px solid #198754 !important;
}

.menu-toggle-ps {
  position: absolute;
  top: 10px;
  right: 5px;
}

.finsights-data-table.no-btns-thead-p-8 .p-datatable-thead tr th {
  padding: 8px 24px;
}

.finsights-data-table.p-12-thead .p-datatable-thead tr th {
  padding: 12px 24px;
}

.no-hover {
  pointer-events: none !important;
}

.lg-st-table tbody tr td {
  border-color: transparent !important;
}

.lg-st-table thead tr th {
  border-color: #e8eaed !important;
}

.sliding-val {
  background: #F3FAF7;
  border-radius: 10px;
  border: 1px solid #d3d4d6;
  min-width: 50px;
  text-align: center;
  font-size: 12px;
  color: #23252D;
  padding: 5px;
}

.btn-p {
  padding: 0.375rem 0rem;
  line-height: 1.5;
}

.finsights-data-table.large-table-focus.less-table-focus-fade-out .p-datatable .p-datatable-tbody>tr.p-highlight {
  background: #ebf0ff;
  background-color: #ebf0ff !important;
  outline-style: solid;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
  outline-offset: -2px;
  border-radius: 0px;
  box-shadow: 5px 0px #d3d4d6 inset;
}

.space-rotate {
  transform: rotate(-90deg);
  display: inline-block;
  padding: 5px;
  font-size: 18px;
  background: #F3FAF7;
  border-radius: 4px;
  border: 1px solid #d3d4d6;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: 0 0 0 2px #3060f08e;
}

.dt-td-elipsis span {
  max-width: 150px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.max-dlg-2a {
  border-radius: 16px;
}

.p-treetable .p-treetable-tbody>tr.row-highlight-active {
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -1px;
  border-radius: 3px;
  background: #EEF2FF;
}

.p-treetable.p-treetable-selectable .p-treetable-tbody>tr:not(.p-highlight):hover,
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover {
  background: #EEF2FF;
}

.dark-preview-btn {
  border-color: #4B4D53;
}

.l-h-font {
  font-size: 16px;
}

.side-menu-block {
  width: 210px;
}

.menu-width-block {
  min-width: 20vw;
  max-width: 22vw;
  position: absolute;
  left: 154px;
  top: 182px;
  z-index: 1;
  max-height: inherit;
}

.figma-shadow {
  box-shadow: 4px 4px 21px 2px rgba(0, 0, 0, 0.07);
}

.card-e2-color {
  border-color: #e2e3e4;
}

.demo-img-circle {
  background: #e8eaeb;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.sub-block-scroll {
  max-height: 390px;
  overflow-y: auto;
}

.b-r-4-select .form-control {
  border-radius: 4px;
}

.h-40-letter-sel .select-row-revese .MuiInputBase-fullWidth {
  height: 39px;
}

.h-40px-letter {
  height: 40px;
}


.icon-card-columns {
  -webkit-column-count: 8;
  column-count: 8;
  -webkit-column-gap: 1.25rem;
  column-gap: 1.25rem;
  orphans: 1;
  widows: 1;
}

.icon-card-columns button {
  display: inline-block;
  width: 100%;
  position: inherit;
  padding: 0;
}

.p-l-r10 {
  padding-left: 10px;
  padding-right: 10px;
}

.editior-join-button-left {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 37px;
}

.editior-join-button-right {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 37px;
}

.e2-bg {
  background: #e2e3e4;
}

.border-start-tp {
  border-left: transparent;
}

.org-card-body-if-editor {
  height: calc(100vh - 285px);
  overflow-y: auto;
  overflow-x: hidden;
}

.h-40px-37 {
  height: 37px;
}

.grs-profit-bg .grs-pr-text {
  color: #0E9F6E;
  font-weight: 600;
}

/* .MuiTextField-root input::placeholder {
  opacity: 1;
  font-weight: 500;
  color: #23242d;
  font-family: 'Avenir', sans-serif;
} */

/* .finsights-data-table-tree .grs-profit-bg {
  border-bottom: 0 !important;
} */
.m-t-8 {
  margin-top: 8px;
}

.finsights-data-table.no-pagination-last-tr tbody tr:last-child td {
  border-bottom: 0;
}


.finsights-data-table.no-pagination-last-tr tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.only-td-lines tbody tr td {
  border-left: 1px solid #d3d4d6;
}

.only-td-lines tbody tr td:first-child {
  border-left: 0;
}

.rem-last-hr hr:last-child {
  display: none;
}

.finsights-data-table-tree.tree-tbody-lines .p-treetable-tbody tr td {
  border-bottom: 1px solid #d3d4d6a8;
  font-size: 14px;
}

.finsights-data-table-tree.last-line-tbody-rem .p-treetable-tbody tr:last-child td {
  border-bottom: 0;
}

.btn-outline-primary.table-vch-card:disabled {
  border-color: #d3d4d6a8;
  color: #b3b7bd;
}

.tr-insights-group-summary .tr-primary-group-insights {
  padding: 0;
}

.tr-insights-group-summary .org-card-body-vch-primary {
  height: inherit;
}

.finsights-data-table.col-span-table .p-datatable .p-datatable-thead tr th.col-span-head.last-th-radius {
  border-top-right-radius: 6px;
}

.acc-round-btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.left-right-select-input .select-inp .select-row-revese.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.left-right-select-input .select-inp .select-row-revese .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.left-right-select-input .second-text-box .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.left-right-select-input .select-inp .select-row-revese .MuiInputBase-fullWidth {
  height: 34.25px;
}



.group-cards-deck>.card:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.group-cards-deck>.card:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.group-cards-deck>.card+.card {
  margin-left: 0;
  border-left: 0;
}

.grey-inp-sel .form-control {
  background: #f7f8fa;
}

.dlg-16-content .p-dialog-content {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.p-16-normal-table-tbody tbody tr td {
  padding: 16px;
}

.p-16-normal-table-thead thead tr th {
  padding: 16px;
}

.invalid-warning-label {
  background: #FFE7CD;
  border-radius: 15px;
  border: 1px solid #C27803;
  color: #C27803;
  padding: 4px 16px;
}

.alert-on-top-bg {
  background: #EBF0FF;
}

.alert-on-top {
  position: absolute;
  top: 70px;
  z-index: 2;
  left: 35%;
}

/* .rg-invalid {
  background-color: #ffa5a5;
} */

.inp-group-join .input-group-text {
  border-color: #d3d4d6;
  border-left-color: transparent;
}

.footer-top-color {
  border-top-color: #e8eaed;
}

.reactgrid-wrapper {
  height: 100px;
  overflow: scroll;
}

.exception-arrows {
  visibility: hidden;
}

.finsights-data-table .p-datatable-selectable .p-selectable-row:hover td .exception-arrows {
  visibility: inherit;
}

.dark-dlg-shadow {
  box-shadow: 1px 1px 16px 6px rgba(0, 0, 0, 0.08);
}

.org-card-body-footer-gst-rem {
  height: calc(100vh - 172px);
  overflow-y: auto;
  overflow-x: hidden;
}

.right-modal-content-dlg {
  max-height: inherit;
}

.rm-hr-remove:last-child hr:last-child {
  display: none;
}

.items-name-padding {
  padding: 8px 24px;
}

.items-name-padding-less {
  padding: 16px 24px;
}

.right-border-th-color {
  border-right: 1px solid #d3d4d6a8 !important;
}

.p-16-tree-table .p-treetable-tbody tr td {
  padding: 18px 16px;
}

.finsights-data-table-tree .text-primary-inside-td td:first-child {
  color: #305FF0 !important;
}

.reactgrid-table-style {
  width: 100%;
  overflow-x: auto;
}

.reactgrid-table-style .reactgrid-content .rg-pane .rg-cell {
  font-size: 14px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  color: #23252D !important;
  border-color: #d3d4d6a8 !important;
}

.reactgrid-table-style .rg-celleditor {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.reactgrid-table-style .reactgrid-content .rg-pane .rg-cell.rg-header-cell {
  font-size: 12px !important;
  color: #23252D !important;
  background: #F9FAFB !important;
}

.rg-celleditor-input,
.rg-celleditor input {
  border: 1px solid #D3D4D6;
  color: #23252D;
  border-radius: 8px;
  padding: 5px 10px !important;
}

.reactgrid-table-style .reactgrid-content .rg-invalid p {
  border: 1px solid #F05252;
  display: block;
  width: 100%;
  border-radius: 8px;
  padding: 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.finsights-data-table-tree.tree-col-rounded .p-treetable-thead tr th:first-child {
  border-top-left-radius: 8px;
}

.finsights-data-table-tree.tree-col-rounded .p-treetable-thead tr th:last-child {
  border-top-right-radius: 8px;
}

.finsights-data-table-tree.tree-col-rounded .p-treetable-tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.finsights-data-table-tree.tree-col-rounded .p-treetable-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.inactive-gstin {
  border-color: #F3893D;
}

.l-g-fixed-btn-scroll {
  height: calc(100vh - 261px);
  overflow-y: auto;
  overflow-x: hidden;
}

.max-l-g-fixed-btn-scroll {
  height: calc(100vh - 271px);
  overflow-y: auto;
  overflow-x: hidden;
}

.float-reason-icon {
  position: absolute;
  right: -30px;
  top: -17px;
}

.span-flex span {
  display: inline-block;
  margin-bottom: 12px;
  margin-right: 12px;
}

.ps-36 {
  padding-left: 36px !important;
}

.ps-56 {
  padding-left: 56px !important;
}

.text-color-1-imp {
  color: #23252d !important;
}

.noraml-col-span-table.items-new-table thead tr th {
  border-right: 1px solid #d3d4d6;
}

.noraml-col-span-table.items-new-table thead tr th:last-child {
  border-right: 0;
}

.noraml-col-span-table.items-new-table thead tr th.col-span-th-columns {
  border-bottom: 1px solid #d3d4d6;
}

.header-inp-text-size {
  height: 30px;
}

.fetch-padding {
  padding: 0.225rem 0.45rem;
}

.ptb-20-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.ledgers-modal-scroll {
  max-height: calc(100vh - 700px);
  overflow-y: auto;
  overflow-x: hidden;
}

.totals-table-b-b-0 .finsights-data-table-tree .grs-profit-bg {
  border-bottom: 0 !important;
}

.vertical-layout-tree .finsights-data-table-tree .p-treetable-tbody tr:last-child.grs-profit-bg {
  border-bottom: 0 !important;
}

.fetch-btn-label {
  position: absolute;
  right: 0px;
  top: -5px;
}

.finsights-data-table .p-datatable-tfoot tr td {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.finsights-data-table .p-datatable .p-datatable-tfoot>tr>td {
  font-weight: 500;
  color: #23252D;
  font-size: 14px;
}

.save-btn-dlg-padding .p-dialog-header {
  padding-top: 14px;
  padding-bottom: 14px;
}

.save-btn-dlg-padding .p-dialog-header-icons {
  margin-top: 7px;
}

.float-h-btn {
  position: absolute;
  right: 70px;
  top: 15px;
}

.finsights-data-table-tree.filters-dt .p-treetable .p-treetable-thead th.p-sortable-column .p-sortable-column-icon.pi-fw.pi-sort-alt:before {
  font-family: uicons-solid-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\fa3f";
  color: #a3a4a7;
  font-size: 10px;

}

.finsights-data-table-tree.filters-dt .p-treetable .p-treetable-thead th.p-sortable-column .p-sortable-column-icon.pi-sort-amount-up-alt:before {
  font-family: uicons-solid-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f313";
  color: #a3a4a7;
  font-size: 14px;

}

.finsights-data-table-tree.filters-dt .p-treetable .p-treetable-thead th.p-sortable-column .p-sortable-column-icon.pi-sort-amount-down:before {
  font-family: uicons-solid-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f30b";
  color: #a3a4a7;
  font-size: 14px;

}

.finsights-data-table-tree .p-filter-column .p-inputtext {
  border: 1px solid #d3d4d6;
  border-radius: 8px;
  font-size: 14px;
  padding: 5px 10px;
  line-height: 20px;
  color: #23252D;
  height: 35px;
  width: 100%;
  max-width: 150px;
  text-overflow: ellipsis;
}

.finsights-data-table-tree.tree-col-rounded .p-treetable-thead tr:nth-child(2) th {
  padding: 12px 16px;
}

.finsights-data-table-tree.tree-col-rounded .p-treetable-thead tr:nth-child(2) th:first-child {
  border-top-left-radius: 0;
}

.finsights-data-table-tree.tree-col-rounded .p-treetable-thead tr:nth-child(2) th:last-child {
  border-top-right-radius: 0;
}

.dt-tooltip-info-msg-child {

  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
  background: #000;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px;
}

.dt-tootlip-msg:hover .dt-tooltip-info-msg-child {
  display: block;
}

.no-filter-header .p-column-header-content {
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

.p-column-filter-menu {
  margin-right: auto;
}

.finsights-data-table.col-span-table .p-datatable .p-datatable-thead tr th.last-th-line-fr-rem:last-child {
  border-right: 0 !important;
}

.btn-link.link-btn-focus:focus {

  box-shadow: 0 0 0 2px #3060f08e !important;

}

.p-dialog.max-dlg-2a {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.filters-has-right-align .p-treetable-thead tr:nth-child(2) th:first-child.p-filter-column {
  text-align: left;
}

.filters-has-right-align .p-treetable-thead tr:nth-child(2) th.p-filter-column {
  text-align: right;
}

.finsights-data-table-tree .p-treetable-tbody tr td.react-table-elipsis:not(:first-child) {
  font-weight: 500 !important;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #f6f8fc;
}

.p-dialog-mask {
  z-index: 1101 !important;
}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  color: #23252D !important;
}

.no-toggle-icn button.p-treetable-toggler {
  display: none !important;
}

.finsights-data-table-tree .p-treetable-tbody tr.text-primary-child td:first-child {
  color: #305FF0;
  cursor: pointer;
}

.internet-alert {
  position: absolute;
  z-index: 1011;
  border-radius: 8px;
  right: 2%;
  top: 72px;
}

.wifi-icon-bg {
  background: #1aca8f;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  color: #fff;
}

.wifi-icon-bg-cross {
  background: #f05252bf;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  color: #fff;
}

.cross-close-btn {
  background: #f7f8fa;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
}

.internet-success {
  border-left: 4px solid #1aca8f !important;
}

.internet-fail {
  border-left: 4px solid #f05252bf !important;
}

.p-treetable .p-treetable-tfoot>tr>td {
  border-bottom: 0;
  border-top: 1px solid #d3d4d69c;
  font-weight: 500;
  color: #23252D;
  padding: 18px 16px;
}

.p-treetable .p-treetable-tfoot>tr>td:first-child {
  border-bottom-left-radius: 8px;
}

.p-treetable .p-treetable-tfoot>tr>td:last-child {
  border-bottom-right-radius: 8px;
}

.finsights-data-table .p-datatable .p-datatable-tfoot>tr>td {
  padding: 16px 24px;
}

.finsights-data-table.last-tfoot .p-datatable .p-datatable-tfoot>tr>td {
  border-bottom: 0;
}

.finsights-data-table .p-datatable .p-datatable-tfoot>tr>td:first-child {
  border-bottom-left-radius: 8px;
}

.finsights-data-table .p-datatable .p-datatable-tfoot>tr>td:last-child {
  border-bottom-right-radius: 8px;
}


.org-profile-pic img {
  max-width: 100%;
  height: 45px;
  width: 45px;
  max-width: 45px;
  min-width: 45px;
  border: 3px solid #d3d4d6;
  border-radius: 50%;
  object-fit: cover;
}

.org-profile-pic i {
  display: block;
  max-width: 100% !important;
  height: auto !important;
  width: 45px !important;
  max-width: 45px;
  min-width: 45px;
  border: 3px solid #e8eaeb;
  border-radius: 50%;
  max-height: 29px;
  min-height: 44px;
  line-height: 43px;
  text-align: center;
  background: #e8eaeb;
}

.contra-temp-table thead tr th {
  border: 1px solid #d3d4d6;

}

.app-inactive {
  opacity: 0.5;
}

.items-new-table.has-tfoot tfoot tr td {
  border-bottom: 0;
  border-right-color: transparent;
  border-left: 0 !important;
}

.items-new-table.has-tfoot tfoot tr td:first-child {
  border-bottom-left-radius: 8px;
}

.items-new-table.has-tfoot tfoot tr td:last-child {
  border-bottom-right-radius: 8px;
}

.footer-div-save {
  box-shadow: 0 -6px 10px -10px #d0d0d0;
  border-top: 1px solid #e8eaed48;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #fff;
  padding-left: 24px;
  padding-top: 20px !important;
  padding-right: 24px;
  padding-bottom: 20px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  z-index: 1;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 0.45;
}

.finsights-data-table.plain-col-span .p-datatable .p-datatable-thead tr th {
  border-right: 1px solid #d3d4d6a8;
}

.finsights-data-table.plain-col-span .p-datatable .p-datatable-thead tr th:last-child {
  border-right: 0;
}

.finsights-data-table.plain-col-span .p-datatable .p-datatable-tbody tr td {
  border-right: 1px solid #d3d4d6a8;
}

.finsights-data-table.plain-col-span .p-datatable .p-datatable-tbody tr td:last-child {
  border-right: 0;
}

.rem-last-hr hr:last-child {
  display: none;
}

.p-12-24 {
  padding: 12px 24px;
}

.contra-temp-table thead tr th:first-child {
  border-left: 0;
}

.contra-temp-table thead tr th:last-child {
  border-right: 0;
  border-right-color: transparent !important;
}

.contra-temp-table tbody tr td:first-child {
  border-left: 0;
}

.contra-temp-table tbody tr td:last-child {
  border-right: 0;
}

.inv-table-content-align div:nth-child(even) {
  padding-left: 30px;
}

.pan-parent {
  position: relative;
}

.pan-error {
  position: absolute;
  right: 12px;
}

.pan-error-org {
  position: absolute;
  /* left: 610px; */
  right: 10px;
}

.pan-error-2 {
  position: absolute;
  /* left: 255px */
  right: 12px;
}

.pan-success-label {
  border: 1px solid #ACE8D3;
  background: #F3FAF7;
  columns: #0E9F6E;
  padding: 2px 8px;
  border-radius: 1px 10px 1px 10px;
}

.pan-error-label {
  border: 1px solid #e8acac;
  background: #faf3f3;
  columns: #0E9F6E;
  padding: 2px 8px;
  border-radius: 1px 10px 1px 10px;
}

.upi-df-tag {
  position: absolute;
  right: 0
}

.inside-table-lines-rem tr td:first-child {
  border-left: 0;
}


.inside-table-lines-rem tr td:last-child {
  border-right: 0;
}

.inv-table-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

.inv-table-scrollbar::-webkit-scrollbar-track {
  background-color: #f7f8fa;
  border-radius: 0px;
}

.inv-table-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: #e8eaed;
}

.main-inv-table thead tr:first-child th:first-child {
  border-left: 0;
}

.main-inv-table thead tr:first-child th:last-child {
  border-right: 0;
}

.p-column-filter-menu {
  margin-left: inherit;
}

@media print {
  @page {
    margin: 10px;
    /* border: 1px solid #d3d4d6; */
  }



  html,
  body {
    width: 100%;
    overflow: visible;
    zoom: 80%;
  }
}

.two-clm div:nth-child(2) {
  text-align: right;
}

.three-clm {
  justify-content: space-between;
}

.pl-24-th thead tr th {
  padding-left: 24px;
}

.right-modal-content-footer-dlg {
  max-height: inherit;
}

.finsights-data-table.tfoot-no-pg .p-datatable tfoot tr td {
  border-bottom: 0;
}

.table-thead-fixed-container {
  max-height: 400px;
  overflow-y: auto;
}

.table-thead-fixed-container thead,
.table-thead-fixed-container tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-thead-fixed-container tbody {
  display: block;
  overflow-y: scroll;
  max-height: 300px;
  /* Adjust the max-height as needed */
}

.table-thead-fixed-container ::-webkit-scrollbar {
  width: 0px;
  height: 8px;
}

.table-thead-fixed-container table tbody tr:first-child td {
  border-top: 0 !important;
}

.table-thead-fixed-container table.items-new-table thead tr th {
  border-bottom: 1px solid #d3d4d6 !important;
}

.card-bottom-l-r-zero {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.hover-btns-td-focus td input.form-control:disabled {
  background-color: transparent !important;
}

.vertical-btns-scroll {
  width: 100%;
  white-space: nowrap;
  overflow: auto;
}

.vertical-btns-scroll button {
  flex: 0 1 auto !important;
}


.vertical-btns-scroll::-webkit-scrollbar {
  width: 8px;
  height: 2px;
  background-color: transparent;
}

.vertical-btns-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}

.vertical-btns-scroll::-webkit-scrollbar-thumb {
  border-radius: 0px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: transparent;
}

.columns-margin-align .col-4:nth-child(4),
.columns-margin-align .col-4:nth-child(5) {
  margin-top: 12px;
}

.add-details-height {
  height: calc(100vh - 325px);
  overflow-y: auto;
  overflow-x: hidden;
}

.join-flex-items .input-group {
  flex-wrap: nowrap;
}

.finsights-data-table.p-16-18-multi-thead .p-datatable .p-datatable-thead tr th {
  padding: 12px 16px;
  position: relative;
}

.filters-dt.p-16-18-multi-thead .p-column-filter-menu button {
  margin-left: 0;
}

.filters-dt.floating-filters .p-column-filter-menu {
  position: absolute;
  right: 2px;
}

.filters-dt.p-16-18-multi-thead.floating-filters .p-column-filter-menu button {
  background: #F7F8FA;
}

.justify-center-align .p-column-header-content {
  justify-content: center;
}

.last-bottom-rem p:last-child {
  margin-bottom: 0 !important;
}

.bg-warning-figma {
  background: #FFE4B9;
}

.items-new-table.has-tfoot tfoot tr td {
  border-bottom: 0;
  border-right-color: transparent;
  border-left: 0 !important;
}

.right-modal-content-footer-dlg .p-dialog-header .pi-window-maximize:before,
.right-modal-content-footer-dlg-n-inp .p-dialog-header .pi-window-maximize:before {
  font-family: uicons-bold-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f51D";
}

.right-modal-content-footer-dlg .p-dialog-header .pi-window-minimize:before,
.right-modal-content-footer-dlg-n-inp .p-dialog-header .pi-window-minimize:before {
  font-family: uicons-regular-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f436";
}

.text-second-variant {
  color: #A855F7;
}

.right-modal-content-footer-dlg.p-dialog-maximized .p-dialog-content {
  height: calc(100vh - 260px) !important;
}

.right-modal-content-footer-dlg.p-dialog-maximized .p-dialog-header {
  border-top-left-radius: 16px !important;
}

.right-modal-content-footer-dlg.p-dialog-maximized {
  margin-left: 24px !important;
}

.right-modal-content-footer-dlg.p-dialog-maximized .p-dialog-footer {
  border-bottom-left-radius: 16px !important;
}


.right-modal-content-footer-dlg.p-dialog-maximized {
  margin-top: -59px !important;
}

.p-dialog-maximized .p-dialog-content .vertical-btns-scroll {
  width: inherit;
}

.checkbox-label-elipsis .field-checkbox label {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.table-responsive {
  scroll-behavior: smooth;
}

.table-responsive::-webkit-scrollbar {
  height: 5px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #d3d4d6;
}

.dragging-handler {
  cursor: move;
}

td .dragging-handler {
  border-color: transparent !important;
  border-style: dashed !important;
  border: 2px solid;
}

.dragging-handler .top-icn-angle,
.dragging-handler .bottom-icn-angle,
.dragging-handler .left-icn-angle,
.dragging-handler .right-icn-angle .dragging-handler .dragHandle {
  opacity: 0;
}

.dragging-handler:hover .top-icn-angle,
.dragging-handler:hover .bottom-icn-angle,
.dragging-handler:hover .left-icn-angle,
.dragging-handler:hover .right-icn-angle {
  opacity: 1;
}

.dragging-handler.resizing .top-icn-angle,
.dragging-handler.resizing .bottom-icn-angle,
.dragging-handler.resizing .left-icn-angle,
.dragging-handler.resizing .right-icn-angle {
  opacity: 1;
}

.dragging-handler:hover .dragHandle {
  opacity: 1;
}

.dragging-handler .cross-hover {
  opacity: 0;
  background: #fff;
}

.dragging-handler:hover .cross-hover {
  opacity: 1;
}

.dragging-handler .bottom-right-icn-angle {
  border: 0 !important;
  opacity: 0;
  inset: unset !important;
  right: 0px !important;
  bottom: 0px !important;
  width: 5px !important;
  height: 5px !important;
}

.dragging-handler:hover .bottom-right-icn-angle {
  opacity: 0;
}

.dragging-handler .bottom-right-icn-angle:before {
  font-family: uicons-bold-straight !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  transform: rotate(-40deg);
  content: "\f139";
  font-size: 12px;
}

.sel-inp-grp .select-inp .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dragging-handler .sel-inp-grp span.input-group-text {
  border-color: #d3d4d6;
}

.dragging-handler .inp-group-join span.input-group-text {
  border-color: #d3d4d6;
}

.dragging-handler .inp-group-join span:hover i {
  color: #6f7380;
}

.dragging-handler .sel-inp-grp span:hover i {
  color: #6f7380;
}

.dragging-handler {
  border-color: transparent !important;
}

.dragging-handler:hover {
  border-color: #d3d4d6 !important;
}

.dragging-handler .cross-mark-hide {
  display: none;
}

.dragging-handler:hover .cross-mark-hide {
  display: block;
}

.cross-mark-hide {
  color: #6f7380 !important;
  background: #fff;
  cursor: pointer;
}

.letter-head-card-body {
  height: calc(100vh - 450px);
}

.water-mark-text-body {
  font-size: 40px;
  font-weight: 800;
  color: #eff1f5;
  /* transform: rotate(300deg);
  -webkit-transform: rotate(300deg); */
  display: block;
  /* margin-top: 12%; */
}

.dragging-handler .bottom-icn-angle {
  cursor: row-resize;
}

.dragging-handler .left-icn-angle {
  cursor: col-resize;
}

.dragging-handler .right-icn-angle {
  cursor: col-resize;
}

.dragging-handler .bottom-right-icn-angle {
  cursor: se-resize;
}

.bottom-icn-angle {
  cursor: row-resize;
}

.top-icn-angle {
  cursor: row-resize;
}

.bg-warning-figma p {
  color: #C27803;
}

.two-clm-flex {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
  justify-content: space-between;
}

.two-clm-flex p {
  font-size: 14px;
}

.three-clm-flex {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 10px;
  justify-content: space-between;
}

.three-clm-flex p {
  font-size: 14px;
}

.four-clm-flex {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 5px;
  justify-content: space-between;
}

.four-clm-flex p {
  font-size: 12px;
}

.finsights-data-table-tree tr.main-parent-weight td:first-child {
  font-weight: 600;
}

.tree-table-less-footer .p-treetable .p-treetable-tfoot>tr>td {
  padding: 12px 16px;
}

.finsights-data-table-tree .p-treetable-tfoot td.f-600-weight {
  color: #23252d;
}

.vertical-btns-scroll .btn.active::after {
  /* top: 35px; */
  bottom: 0px;
}

.p-treetable-scrollable-body::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

.p-treetable-scrollable-body::-webkit-scrollbar-track {
  background-color: #e8eaed;
  border-radius: 0px;
}

.p-treetable-scrollable-body::-webkit-scrollbar-thumb {
  border-radius: 0px;
  border: 0px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: #d3d4d6;
}

.finsights-data-table-tree.sub-groups-filters .p-filter-column .p-inputtext {
  height: 30px;
  font-size: 12px;
  max-width: fit-content;
}


.finsights-data-table-tree.tree-col-rounded.sub-groups-filters .p-treetable-thead tr:nth-child(2) th:first-child {
  padding: 8px 16px !important;
}

/* .finsights-data-table-tree.tree-col-rounded.sub-groups-filters .p-treetable-thead tr th:not(:first-child) {
  text-align: right;
} */

.text-elipsis-width {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subgroups-ledgers-focus .p-treetable .p-treetable-tbody>tr:focus {
  outline-style: solid;
  outline-width: 3px;
  outline-color: #3060f08e;
  outline-offset: -2px;
  border-radius: 3px;
}

.dates-float-header {
  position: absolute;
  right: 65px;
  top: 13px;
}

.menu-width-block .font-size-18.f-500.text-color-1.mb-16 {
  font-size: 16px;
}

.menu-width-block .form-control {
  font-size: 12px;
}

.menu-width-block .select-inp .MuiAutocomplete-input {
  font-size: 12px;
}

/* Custom CSS for adjusting card width */
.half-width-card {
  width: 50rem;
  /* Set child div width to 50rem */
  margin: 0 auto;
  /* Center the child div horizontally */
}

/* Custom CSS for adjusting card width */
.half-width-card {
  width: 50rem;
  /* Set child div width to 50rem */
  margin: 0 auto;
  /* Center the child div horizontally */
}

@media (max-width: 36rem) {
  .half-width-card {
    width: 34rem;
    /* Adjust width to fit within col-8 on smaller screens */
  }
}

@media screen and (min-width: 36rem) {
  .half-width-card {
    width: 46rem;
    /* Adjust width to fit within col-8 on larger screens */
  }
}

.fields-multiselect {
  width: 0;
  margin-right: 0;
  height: 32px;
  border-color: transparent !important;
}

.fields-multiselect .p-multiselect-trigger {
  opacity: 0;
}

.fields-multiselect .p-multiselect-panel {
  width: 300px;
  max-width: 300px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  top: 40px !important;
  padding: 16px;
}

.fields-multiselect .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  height: 38px;
  border-color: #d3d4d6;
  background: #F7F8FA;
  font-size: 14px;

}

.fields-multiselect .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-icon {
  display: none;
}

.fields-multiselect .p-multiselect-close-icon {
  font-size: 12px;
}

.fields-multiselect .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 24px;
  height: 24px;
}

.fields-multiselect .p-multiselect-items-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

.fields-multiselect .p-multiselect-items-wrapper {
  max-height: 300px !important;
}

.fields-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: #fff;

}

.fields-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  border-bottom: 1px solid #e8eaed;
  padding: 12px 5px;
  font-size: 14px;
}


.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -2px;
  box-shadow: none;
  border-radius: 5px;
}

.fields-multiselect .p-multiselect-panel .p-multiselect-items {
  padding: 0;
}

.fields-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: #f7f8fa;
}

.fields-multiselect .p-multiselect-panel .p-multiselect-header {
  background-color: #fff;
  border-bottom: 1px solid #d3d4d654;
  padding: 0px 0px 14px 2px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: #23252d;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #305FF0;
}

.row-items-marin-rem .col-3 {
  margin-bottom: 18px;
}

.row-items-marin-rem .col-3:nth-child(5),
.row-items-marin-rem .col-3:nth-child(6),
.row-items-marin-rem .col-3:nth-child(7),
.row-items-marin-rem .col-3:nth-child(8) {

  margin-bottom: 0;
}

.finsights-data-table.last-line-last .p-datatable .p-datatable-thead tr:first-child th:last-child {
  border-right: 0 !important;
}


.finsights-data-table.tfoot-b-r-0 .p-datatable .p-datatable-tfoot>tr>td:first-child {
  border-bottom-left-radius: 0px;
}

.finsights-data-table.tfoot-b-r-0 .p-datatable .p-datatable-tfoot>tr>td:last-child {
  border-bottom-right-radius: 0px;
}

.a4-size-div {
  width: 21cm;
  height: 29.7cm;
  background-color: white;
  border: 1px solid black;
  margin: 0 auto;
}

.class-to-convert {
  width: 21cm;
  /* height: 29.7cm; */
  margin: 0 auto;
}

.finsights-data-table.p-12-tbody .p-datatable-tbody tr td.enable-right-border {
  border-right: 1px solid #d3d4d6a8 !important;
}

.finsights-data-table.p-12-tbody .p-datatable-tbody tr td.enable-right-border-main:nth-child(3n+1) {
  border-right: 1px solid #d3d4d6a8 !important;
}

.finsights-data-table.p-12-tbody .p-datatable-tbody tr td.enable-right-border-main:nth-child(3n+1):last-child {
  border-right: 0px solid #d3d4d6a8 !important;
}

.right-modal-content-footer-dlg.vch-max-dlg.p-dialog-maximized .p-dialog-content {
  height: calc(100vh - 180px) !important;
  border-bottom-left-radius: 16px !important;

}

/* .letter-head-scroll-table {
  height: calc(100vh - 225px);
} */

.card-body-t {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.top-row {
  align-self: flex-start;
}

.bottom-row {
  align-self: flex-end;
}


.TableDisplay {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
}

.Table {
  height: 100%;
  border-collapse: collapse;
}

.Table--Full {
  height: 100%;
}

.Table--Natural {
  height: auto;
}

.Table--Natural tr:first-child td {
  vertical-align: top !important;
}

.Table--Natural tr:last-child td {
  vertical-align: bottom !important;
}

.letter-slider .p-slider-horizontal .p-slider-range {
  border-radius: 25px;
}


.letter-slider {
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 1;
}

.fi-br-humidity:before {
  transform: rotate(180deg);
  display: inline-block;
}

.saving-dropdown .dropdown-menu {
  border: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.saving-dropdown .dropdown-menu li .dropdown-item {
  font-size: 14px;
}

.saving-dropdown .dropdown-toggle::after {
  border: 0;
}

.zoom-slider {
  width: 200px;
  margin: 10px;

}

.zoom-slider {
  width: 100px;
}

.container-a4 {
  position: relative;
  width: 21cm;
  height: 29.7cm;
  border: 1px solid #000;
  overflow: hidden;
  margin: auto;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.b-r-12 {
  border-radius: 12px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.no-header-footer-line .p-dialog-header {
  border-bottom: 0;
}

.no-header-footer-line .p-dialog-footer {
  border-top: 0;
  box-shadow: none;
}

.no-header-footer-line .p-dialog-content {
  padding-top: 0;
  padding-bottom: 0;
}

.no-header-footer-line .p-dialog .p-dialog-header-icons {
  display: none;
}

.inside-btn-apply {
  position: absolute;
  top: 0;
  right: 0;
}

.print-float-btn {
  position: absolute;
  top: 20px;
  right: 95px;
}

.multi-sel.fields-multiselect:focus-visible,
.multi-sel.fields-multiselect:active {
  background-color: #EBF0FF !important;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
  outline-offset: -1px;
}

.p-multiselect.fields-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}

.inp-125-px {
  width: 125px;
}

.finsights-data-table.colspan-table-count-2 tbody tr td:nth-child(2n) {
  border-right: 1px solid #d3d4d6a8 !important;
}


.finsights-data-table.colspan-table-count-3 tbody tr td:nth-child(3n) {
  border-right: 1px solid #d3d4d6a8 !important;
}

.finsights-data-table.colspan-table-count-4 tbody tr td:nth-child(4n) {
  border-right: 1px solid #d3d4d6a8 !important;
}

.finsights-data-table.colspan-table-count-5 tbody tr td:nth-child(5n) {
  border-right: 1px solid #d3d4d6a8 !important;
}

.finsights-data-table.colspan-table-count-6 tbody tr td:nth-child(6n) {
  border-right: 1px solid #d3d4d6a8 !important;
}

.finsights-data-table.colspan-table-count-7 tbody tr td:nth-child(7n) {
  border-right: 1px solid #d3d4d6a8 !important;
}

.finsights-data-table.colspan-table-count-8 tbody tr td:nth-child(8n) {
  border-right: 1px solid #d3d4d6a8 !important;
}

.finsights-data-table.colspan-table-count-9 tbody tr td:nth-child(9n) {
  border-right: 1px solid #d3d4d6a8 !important;
}

.edit-float-btn {
  position: absolute;
  top: 14px;
  right: 95px;
}

.print-float-btn-eway {
  position: absolute;
  top: 20px;
  right: 95px;
}

td.p-frozen-column {
  box-shadow: -2px 3px 0.25rem rgb(0 0 0 / 8%) !important;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 9px;
  height: 9px;
}

.p-radiobutton .p-radiobutton-box {
  width: 22px;
  height: 22px;
}

.apps {
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.a4-size-container-wrapper {
  height: 100%;
  /* Take up full height of the app */
  overflow-y: auto;
  /* Enable vertical scrolling if content exceeds viewport height */
}

.a4-size-container {
  width: 210mm;
  /* Set width to A4 width */
  height: 297mm;
  /* Set height to A4 height */
  max-width: 100%;
  /* Ensure the A4-size div doesn't exceed the viewport width */
  max-height: 100%;
  /* Ensure the A4-size div doesn't exceed the viewport height */
  overflow: auto;
  /* Enable scrolling if the content exceeds the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.a4-size-divs {

  background-color: white;
  border: 1px solid black;
  position: relative;
  width: 100%;
  height: 100%;
}


/* .a4-size-divs {
  width: 210mm;
  height: 297mm;
  background-color: white;

  transition: transform 0.3s ease;
 
  margin: auto;
} */

.right-modal-content-footer-dlg-n-inp.p-dialog-maximized .p-dialog-content {
  height: calc(100vh - 245px) !important;
}

.right-modal-content-footer-dlg-n-inp.p-dialog-maximized {
  height: auto !important;
}

.right-modal-content-footer-dlg-n-inp.p-dialog-maximized {
  margin-left: 24px !important;
  border-radius: 16px;
}

.right-modal-content-footer-dlg-n-inp.p-dialog-maximized .p-dialog-header {
  border-top-left-radius: 15px !important;
}

.right-modal-content-footer-dlg-n-inp.p-dialog-maximized .p-dialog-footer {
  border-bottom-left-radius: 16px !important;
}

.saving-dropdown .dropdown-menu button.dropdown-item:focus,
.saving-dropdown .dropdown-menu button.dropdown-item:focus-visible {
  background: #EBF0FF !important;
  border-color: transparent !important;
  color: #305FF0 !important;
  box-shadow: 0 0 0 2px #3060f08e !important;
  border-radius: 8px;
  outline-color: #3060f08e;
}

.disabled-v-button {
  opacity: 0.5;
  pointer-events: none;
}



.letter-overlay-panel .p-overlaypanel-content {
  padding: 16px;
}

.editor-row .select-inp .select-row-revese .MuiInputBase-fullWidth {
  height: 30px;
}

.editor-row .p-inputnumber-buttons-horizontal input {
  width: 50px;
  font-size: 12px;
  margin-left: -1px;
  margin-right: -1px;
}

.editor-row .p-inputnumber-buttons-horizontal button span {

  font-size: 10px;
}

.editor-row .p-inputnumber-buttons-horizontal .p-button.p-button-icon-only {
  width: 2rem;
}

.editor-row .p-inputnumber-buttons-horizontal input.p-inputtext:enabled:focus {


  box-shadow: none !important;
  border-color: #305ff0 !important;
}

.editor-row .p-inputnumber-buttons-horizontal {
  height: 32px;
}

.dragging-handler.active {
  border-color: #305FF0 !important;
}

.empty-logo-area {
  width: 70px;
  height: 100%;
  white-space: normal;
  border: 3px solid #f7f8fa;
  text-align: center;
  font-size: 20px !important;
  font-weight: 600;
  padding: 6px;
  border-radius: 5px;
  background: #f7f8fa;
}

.sel-letter .select-row-revese.form-control {
  width: 160px;
}

.sel-letter .select-row-revese input {
  font-size: 12px;
}

.right-append-towards {
  left: -215px !important;
}

.th-td-lines-table thead tr th {
  border-right: 1px solid #d3d4d6;
}

.th-td-lines-table thead tr th:last-child {
  border-right: 0px solid #d3d4d6;
}

.th-td-lines-table tbody tr td {
  border-right: 1px solid #d3d4d654 !important;
}

.lg-st-table.th-td-lines-table tbody tr td:last-child {
  border-right: 0px solid #d3d4d6 !important;
}

.thead-elipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.th-del-button button {
  position: absolute;
  right: -9px;
  font-size: 12px;
  top: -6px;
  opacity: 0;
}


.th-button-visiblity:hover .th-del-button button {
  opacity: 1;
}

.ledger-status-btn .dropdown-toggle::after {
  display: none;
}

.no-border-table thead tr th {
  border: 0;
  background: transparent;
  color: #6F7380;
}

.no-border-table tbody tr td {
  border: 0;
  background: #f7f8fa;
}

.no-border-table tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.no-border-table tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.no-border-table.separation-table {

  border-collapse: separate;
  border-spacing: 0 15px;
}

.dlg-top-content-0 .p-dialog-content {
  padding-top: 0;
}

.blue-dotted-lines:before {
  content: "";
  width: 99%;
  height: 99%;
  border: 2px dashed #305ff09e;
  position: absolute;
  right: 4px;
  top: 5px;
}

.no-border-table thead tr th {
  border: 0;
  background: transparent;
  color: #6F7380;
}

.no-border-table tbody tr td {
  border: 0;
  background: #f7f8fa;
}

.no-border-table tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.no-border-table tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.no-border-table.separation-table {

  border-collapse: separate;
  border-spacing: 0 15px;
}

.dlg-top-content-0 .p-dialog-content {
  padding-top: 0;
}

.letter-preview-dlg .p-dialog-content {
  background: #f7f8fa;
  border-radius: 16px !important;
}

.letter-preview-dlg .p-dialog-header {
  position: absolute;
  right: 10px;
  padding: 10px;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  border-bottom: 0;
  z-index: 1;
}

.download-btn-letter {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.preview-disabled {
  color: #e3e3e3;
  border-color: #4b4d533b;
  pointer-events: none;
}

/* .add-opening-bal-record tr:first-child td:nth-child(6),
.add-opening-bal-record tr:first-child td:nth-child(7),
.add-opening-bal-record tr:first-child td:nth-child(8),
.add-opening-bal-record tr:first-child td:nth-child(9),
.add-opening-bal-record tr:first-child td:nth-child(10) {
  font-size: 0;
} */

.add-opening-bal-record {
  background: #f7f8fa;
  font-weight: 600 !important;
}

.finsights-data-table .p-datatable .p-datatable-tbody>tr:focus-visible td.add-opening-bal-record {
  background: transparent !important;
}

.finsights-data-table-tree .p-treetable-tbody tr.grs-profit-bg td.react-table-elipsis:not(:first-child) {
  font-weight: 600 !important;
}

.cal-with-icn input {
  font-size: 14px;
  border: 1px solid #D3D4D6;
  color: #23252D;
  background: transparent;
  max-width: 110px;
  border-radius: 8px;
  padding: 5px 10px;
  z-index: 1;
  line-height: 20px;
  /* border-right-color: transparent !important; */
  height: 36px;
}

.cal-with-icn .cal-inside-icn {
  display: none;
}

.cal-with-icn button {
  background-color: transparent;
  border-color: #d3d4d6;
  border-left: 0;
  color: #515151;
  height: 38px;
}

.cal-with-icn .p-button:enabled:hover,
.cal-with-icn .p-button:not(button):not(a):not(.p-disabled):hover {
  background-color: transparent;
  color: #515151;
  border-color: #d3d4d6;
}

.cal-with-icn .p-button:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
  border-color: transparent !important;
}

.cal-with-icn .p-inputtext:enabled:hover {
  border-color: #d3d4d6;
}

.cal-with-icn button span.pi-calendar:before {
  font-family: uicons-regular-rounded !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f2c7";
}

.cal-with-icn .p-button-icon {
  line-height: 1;
  color: #6F7380;
}

.p-datepicker {
  border-radius: 8px;
  max-width: 350px !important;
  min-width: 260px !important
}

.p-datepicker-calendar thead tr th {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.p-datepicker-calendar tbody tr td {
  font-size: 14px;
  padding: 3px;
}

.p-datepicker .p-datepicker-header {
  padding: 0;
}

.p-datepicker-header .p-datepicker-prev-icon,
.p-datepicker-header .p-datepicker-next-icon {
  font-size: 12px;
}

.p-datepicker-header .p-datepicker-title button {
  font-size: 14px;
}

.dragging-handler .dragHandle {
  opacity: 0;
}

.dragging-handler:hover .dragHandle {
  opacity: 1;
}

.autocomplete-listbox .Mui-focused button {
  background: #EBF0FF !important;
  border-color: transparent !important;
  color: #305FF0 !important;
  box-shadow: 0 0 0 2px #3060f08e !important;

}

.letter-dlg-header-style {
  box-shadow: 4px 4px 21px 2px rgba(0, 0, 0, 0.07) !important;

}

.letter-dlg-header-style-before-drag {
  margin-top: 1% !important;
  margin-left: -3% !important;
}

.letter-dlg-header-style .p-dialog-header {
  border-bottom: 0;
  padding: 16px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;

}

/* .letter-dlg-header-style .p-dialog-header-icons {
  display: none;
} */

.letter-dlg-header-style .p-dialog-content {
  padding: 0;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.letter-dlg-header-style .sub-block-scroll {
  padding-top: 5px;
}

.letter-dlg-header-style .sub-block-scroll p.font-size-18.f-500.text-color-1.mb-16 {
  display: none;
}

.no-mask {
  background-color: transparent !important;
}

.hover-link-drag-visible .hover-link-drag {
  position: absolute;
  right: 0;
  background: #fff;
  opacity: 0;
  width: 25px;
  text-align: center;
}

.hover-link-drag-visible:hover .hover-link-drag {

  opacity: 1;
}

.cal-inside-icn {
  position: absolute;
  top: 7px;
  right: 10px;
}

.preview-dlg-no-footer .p-dialog-content {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.key-board-shortcut-label {
  padding: 2px 8px;
  background: #F3F4F6;
  border-radius: 4px;
  border: 1px solid #E5E7EB;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.finsight-toggle .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  font-family: uicons-bold-rounded !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f34c";
  font-size: 10px;
  line-height: 19px;
  color: #305FF0;
  text-align: center;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.finsight-toggle .p-inputswitch .p-inputswitch-slider:before {
  font-family: uicons-bold-rounded !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f45b";
  font-size: 8px;
  line-height: 19px;
  color: #4B4D53;
  text-align: center;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.finsight-toggle .p-inputswitch .p-inputswitch-slider {
  background: #E2E3E4;
}

.finsight-toggle .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #7798ff;
}

.ag-table-style .ag-header-cell .ag-header-cell-text {
  font-weight: 500;
  font-size: 12px;
  color: #515561;
  text-transform: initial;
}

.ag-table-style .ag-row {
  border-bottom-color: #d3d4d69c;
}

.ag-table-style .ag-cell {
  border-right: solid transparent !important;
}

.ag-theme-alpine * {
  font-family: 'Avenir', sans-serif;
}

.ag-table-style .ag-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ag-ltr .ag-cell-range-single-cell,
.ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle {
  outline-style: solid !important;
  outline-width: 2.5px !important;
  outline-color: #3060f08e !important;
  outline-offset: -3px !important;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  outline-style: solid !important;
  outline-width: 3px !important;
  outline-color: #3060f08e !important;
  outline-offset: -2px !important;
}

.ag-popup-editor {
  background: #fff !important;
}

.ag-rich-select {
  background: #fff !important;
  border-radius: 8px;
}

.ag-table-style .ag-row:last-child {
  border-bottom: 0 !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ag-row-selected::before {
  background: #ebf0ff;
  background-color: #ebf0ff !important;
  outline-style: solid;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
  outline-offset: -2px;
  border-radius: 0px;
  /* box-shadow: 5px 0px #305FF0 inset; */
}

.ag-icon-menu::before {
  font-family: uicons-bold-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f5a7" !important;
  color: #a3a4a7;
  font-size: 10px;
  cursor: pointer;
}

.ag-popup-editor {
  border: 1px solid transparent !important;
  border-radius: 8px !important;
}

.ag-popup-child:not(.ag-tooltip-custom) {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}

.ag-rich-select-row-selected {
  background-color: #7798ff !important;
}

.ag-theme-alpine .ag-menu,
.ag-theme-alpine-dark .ag-menu {
  background-color: #f7f8fa !important;
  border: 1px solid #d3d4d6;
  border-radius: 8px;
}

.ag-menu-separator-part::after {
  border-top: 1px solid #d3d4d6 !important;
}

.ag-theme-alpine .ag-tabs-header,
.ag-theme-alpine-dark .ag-tabs-header {
  border-bottom: 1px solid #d3d4d6 !important;
}

.ag-popup-child:not(.ag-tooltip-custom) {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}

.hover-btns-card-td-focus:focus,
.hover-btns-card-td-focus:focus {
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -2px;
  border-radius: 3px;

}

.p-and-l-tree .p-treetable-tbody tr:last-child.border-1 {
  border-bottom: 0 !important;
}

.p-and-l-buttons {
  position: absolute;
  right: -9px;
  font-size: 12px;
  top: -8px;
  opacity: 0;
}

.p-and-l-button-visiblity:hover .p-and-l-buttons {
  opacity: 1;
}

.ag-header-cell.ag-header-active .ag-header-cell-menu-button {
  margin-top: -5px;
}

/* .ag-center-cols-clipper {
  min-height: unset !important;
} */

.ag-ltr .rg-invalid.ag-cell-range-single-cell.ag-cell-range-handle {
  outline-style: solid !important;
  outline-width: 3px !important;
  outline-color: #F05252 !important;
  outline-offset: -2px !important;
}

.ag-ltr .rg-valid.ag-cell-range-single-cell.ag-cell-range-handle {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #0E9F6E !important;
  outline-offset: -2px !important;
}

.ag-cell.ag-cell-not-inline-editing.rg-valid {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #0E9F6E !important;
  outline-offset: -2px !important;
}

.ag-cell.ag-cell-not-inline-editing.rg-invalid {
  outline-style: solid !important;
  outline-width: 3px !important;
  outline-color: #F05252 !important;
  outline-offset: -2px !important;
}

.dropdown-cell .ag-center-cols-clipper {
  min-height: calc(100vh - 400px) !important;
}

.ag-center-cols-clipper {
  min-height: unset !important;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  box-shadow: 0 0 0 2px #3060f08e;
}

.p-monthpicker-month {
  width: 30%;
  margin: 2px;
}

.p-datepicker .p-datepicker-header {
  border-bottom: 1px solid #d3d4d654;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  box-shadow: 0 0 0 2px #3060f08e;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  font-size: 14px;
}

.p-yearpicker-year {
  font-size: 14px;
}

.ag-table-style .ag-cell.border {
  border: 1px solid #d3d4d6a8 !important;
  height: 36px;
  margin-top: 7px;
  line-height: 36px;
  border-radius: 6px;
}

.ag-row-hover.ag-row-selected::before {
  background: #ebf0ff !important;
}

.p-and-l-tree .p-treetable-scrollable-header-box {
  margin-right: 0 !important;
}

.p-and-l-tree .p-treetable .p-treetable-scrollable-header,
.p-and-l-tree.p-treetable .p-treetable-scrollable-footer {
  background: transparent;
}

.p-radiobutton {
  position: relative;
}



.move-bottom-top-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.move-bottom-top-btn button {
  width: 40px;
  height: 40px;
  line-height: 30px;
}

.footer-indicator-btn:hover .footer-indicator {
  opacity: 1;
}

.footer-indicator {
  opacity: 0;
  position: absolute;
  top: -35px;
  right: 0px;
  font-size: 12px;
  color: #fff;
  background: #23252D;
  border-radius: 5px;
  padding: 5px;
  width: max-content;
  text-align: center;
}

.footer-indicator-btn:hover .footer-indicator {
  opacity: 1;
}

.secondary-color-btn {
  background: #C8C9CB;
}

.letter-color-picker-input input {
  max-width: 120px;
  height: 32px;
}

.letter-color-picker-input .input-group-text {
  height: 32px;
  padding: 5px;
  background: #fff;
}

.edit-note-content-tooltip {
  position: absolute;
  background-color: #6c757d;
  line-height: 1;
  padding: 0px;
  color: #fff;
  top: 29px;
  left: 89px;
  padding-left: 8px;
  padding-right: 2px;
  border-radius: 5px;
}

.edit-note-content-tooltip:after,
.edit-note-content-tooltip:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.edit-note-content-tooltip:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #6c757d;
  border-width: 5px;
  left: 50%;
  margin-left: -116px;
}

.edit-note-content-tooltip:before {
  border-color: rgba(113, 158, 206, 0);
  border-bottom-color: #719ECE;
  border-width: 5px;
  left: 50%;
  margin-left: -116px;
}

.refresh-inbox:before {
  display: inline-block;
  transform: rotate(-90deg);
}

.inbox-active-org-btns button {
  margin-bottom: 6px;
  padding: 12px;
}

.inbox-active-org-btns .btn:hover {
  background: #F7F8FA;
}

.inbox-active-org-btns .btn.active {
  background: #ebf0ff;
}

.v-top {
  vertical-align: top;
}

.inbox-right-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
  clip-path: inset(0px -15px 0px 0px);
}

.inbox-scroll-content {
  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}

.whatsapp-refresh-float {
  font-size: 7px;
  background: #fff;
  position: absolute;
  left: 20px;
  top: 8px;
}

.email-refresh-float {
  font-size: 7px;
  background: #fff;
  position: absolute;
  left: 22px;
  bottom: 7px;
}

.file-status-float-icn {
  position: absolute;
  right: 30%;
  bottom: -17%;
}

.right-click-focus .card:hover {
  background: #F7F9FC;
  cursor: pointer;
}

.right-click-focus .card.active {
  background: #F7F9FC;
  cursor: pointer;
}

.context-custom-class .p-menuitem-link {
  font-size: 12px;
  color: #23252D;
  padding: 12px;
  border-radius: 8px;
}

.context-custom-class ul {
  padding: 8px;
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
  background: #EBF0FF;
}

.context-custom-class .p-submenu-list {
  padding: 8px;
}

.p-contextmenu .p-menuitem-link:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
  outline: none;
}

.p-8-12 {
  padding: 8px 12px;
}

.p-8-padding {
  padding: 8px;
}

.bottom-right-mask {
  top: -24px;
}

.border-d-1px {
  border: 1px solid #d3d4d6;
}

.right-50-dlg {
  margin-right: 50px !important;
}

.edit-ps-btn {
  position: absolute;
  right: 60px;
  top: 15px;
}

.ag-table-style .ag-cell.border-tp {
  border: 1px solid transparent !important;
  height: 40px;
  margin-top: 4.25px;
  line-height: 40px;
  border-radius: 6px;
  padding-left: 0 !important;
}

.ag-table-style .ag-cell.border-tp .cal-with-icn input {
  width: 100%;
  max-width: inherit;
  border: 1px solid #d3d4d6a8;
}

.ag-table-style .ag-cell.border-tp .cal-with-icn input.p-inputtext:enabled:focus {
  box-shadow: none !important;

}

.ag-table-style .ag-cell.border-tp .cal-with-icn span.p-calendar {
  background-color: transparent !important;
  width: -webkit-fill-available;
}

.ag-ltr .ag-cell-range-single-cell,
.ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle .cal-with-icn input {
  border: 1px solid transparent !important;
}

/* .ag-table-style .ag-cell.border-tp.p-l-input {
  padding-left: 18px !important;
} */

.w-195px {
  width: 195px !important;
}

.w-145px {
  width: 145px !important;
}

.w-395px {
  width: 395px !important;
}

.custom-cross {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
}



.letter-preview-dlg .p-dialog-header .pi-window-maximize:before {
  font-family: uicons-bold-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f51D";
}

.letter-preview-dlg .p-dialog-header .pi-window-minimize:before {
  font-family: uicons-regular-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f436";
}

.letter-preview-dlg.p-dialog-maximized .p-dialog-content {
  border-radius: 0 !important;
}

.fb-color {
  color: #1877F2;
}

.x-color {
  color: #0f1419;
}

.insta-color {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.youtube-color {
  color: #FF0000;
}

.linkedin-color {
  color: #0077b5;
}

.letter-preview-dlg.p-dialog-maximized .download-btn-letter {
  bottom: 25px;
  right: 25px;
  position: fixed;

}

.figma-sdw-2 {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.10);
}

.p-l-r-16-12 {
  padding: 16px 12px 12px 16px;
}

.m-24-r {
  margin-right: 24px;

}

.elipsis-below-200 {
  width: 180px;
}

.first-tbody-highlight tbody tr td:first-child {
  background-color: #f7f8fa;
}

.vw-22 {
  max-width: 20vw;
}

.new-folder-form-control {
  height: 20px;
  border-radius: 3px;
  border-color: #D3D4D6;
}

.left-space-select .select-row-revese .MuiInputBase-fullWidth {
  padding-left: 0 !important;
}

.org-settings-scroll-eway {
  height: calc(100vh - 230px);
  overflow-y: auto;
  overflow-x: hidden;
}

.float-eway-btn {
  position: absolute;
  right: 100px;
}

.bulk-entry-float-btn {
  position: absolute;
  right: 95px;
  top: 20px;
}


.highlight-line {
  /* border: #000 1px solid !important; */
  background: #F7F8FA;
  /* box-shadow: 0 0 0 2px #3060f08e !important; */
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -3px;
}

.cross-eye-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}

.finsights-data-table.col-span-table.last-single-th .p-datatable .p-datatable-thead tr:first-child th:last-child {
  border-right: 0 !important;
  border-left: 1px solid #d3d4d6a8;
}

.exclamation-float {
  position: absolute;
  right: 3px;
  top: -8px;
}

.tax-rate-focus thead tr th:focus-visible {
  background-color: #EBF0FF !important;
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -1px;
  border-radius: 3px;
}



.inside-custom-dt-pick .cal-with-icn input:focus-visible {
  outline-style: none !important;
}

.inside-custom-dt-pick .cal-with-icn input {
  border-color: transparent !important;
}

/* 
.ag-body-clipper.ag-layout-normal ::-webkit-scrollbar {
  width: 0;
} */

.cal-no-bg .p-calendar {
  border-radius: 8px;
}


.hr-solid-letter {
  border-top: 2px solid #23252D;
  position: absolute;
  top: 50%;
  width: 100%;
  cursor: pointer;
}


.hr-dotted-letter {
  border-top: 2px dotted #23252D;
  position: absolute;
  top: 50%;
  width: 100%;
}

.hr-dashed-letter {
  border-top: 2px dashed #23252D;
  position: absolute;
  top: 50%;
  width: 100%;
}

.vr-solid-letter {
  border-left: 2px solid #23252D;
  position: absolute;
  left: 50%;
  height: 38px;
}

.vr-dotted-letter {
  border-left: 2px dotted #23252D;
  position: absolute;
  left: 50%;
  height: 38px;
}

.vr-dashed-letter {
  border-left: 2px dashed #23252D;
  position: absolute;
  left: 50%;
  height: 38px;
}

.ptb-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ptb-10-px {
  padding-top: 10px;
  padding-bottom: 10px;
}

.hr-card-hover-bg .card:hover {
  background: #F7F8FA;
}

.hr-card-hover-bg .card:focus-visible,
.hr-card-hover-bg .card:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
  outline: none;
}

.bulk-entry-border-tb thead tr th {
  border-right: 1px solid #d3d4d6;
}

.bulk-entry-border-tb tbody tr td {
  border-right: 1px solid #d3d4d6;
}

.bulk-entry-border-tb thead tr th:last-child {
  border-right: 0px solid #d3d4d6;
}

.bulk-entry-border-tb tbody tr td {
  border-right: 1px solid #d3d4d6;
}

.bulk-entry-border-tb tbody tr td:last-child {
  border-right: 0px solid #d3d4d6;
}

.inputs-less-height .form-control {
  height: 32px;
}

.no-border-table-radius thead tr:first-child th:first-child {
  border-radius: 0;
}

.no-border-table-radius thead tr:first-child th:last-child {
  border-radius: 0;
}

.bulk-last-td-row-line tbody tr:last-child td {
  border-bottom: 1px solid #d3d4d6;
}

.multi-items hr:last-child {
  border-top: 0 !important;
}

/* .bulk-entry-padding-table thead tr th {
  padding: 16px;
}

.bulk-entry-padding-table tbody tr td {
  padding: 16px;
} */

.multi-items-btns button {
  height: 32px;
}

.padding-left-12 {
  padding-left: 12px;
}

.multi-inside-span {
  display: inline-flex;
  flex-flow: row nowrap;
  max-width: 100%;
}

.multi-inside-span .ledger-title {
  flex: 1;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.multi-inside-span .comments-span {
  white-space: nowrap;
}

.all-comments-btn {
  padding: 4px 12px;
  border: 1px solid #515561;
  background-color: #E2E3E4 !important;
  line-height: 20px;
}

.all-high-btn {
  padding: 4px 12px;
  border: 1px solid #F05252;
  color: #F05252;
  line-height: 20px;
}

.all-medium-btn {
  padding: 4px 12px;
  border: 1px solid #C27803;
  color: #C27803;
  line-height: 20px;
}

.all-low-btn {
  padding: 4px 12px;
  border: 1px solid #0E9F6E;
  color: #0E9F6E;
  line-height: 20px;
}

.btn-outline-warning {
  border-color: #C27803 !important;
  color: #C27803;
}

.comments-user-letter {
  width: 40px;
  height: 40px;
  background: #D2DEFF;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
}

.att-file-div {
  border: 1px solid #d3d4d6;
  padding: 6px 8px;
  border-radius: 25px;
}

.delete-alert-ps {
  position: absolute;
  top: -23px;
  width: 100%;
  z-index: 1;
}

.comments-dlg .p-dialog-header {
  border-bottom: 0;
}

.comments-dlg .p-dialog-content {
  padding-top: 0;
  padding-bottom: 0;

}

.comments-dlg .p-dialog-footer {
  border-top: 0;
  box-shadow: none;

}

.text-resize-line {
  resize: none;
}

.comments-unread {
  position: absolute;
  top: 3px;
  right: 10px;
}

.plane-abs-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.b-l-d-line {
  border-left: 1px solid #d3d4d6;
}

.bell-tilt {
  transform: rotate(315deg);
  display: inline-block;
}

.category-buttons button {
  padding: 10px 20px;
  margin-right: 24px;
}

.category-buttons span {
  background: #F3F4F6;
  padding: 4px 8px;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 16px;
}

.view-full-details-btns {
  position: absolute;
  top: 15px;
  right: 80px;
}

.inc-inp span input {
  text-align: center;
}

.border-w {
  border: 5px solid #fff;
  border-radius: 3px;
}

.btn-group.outline-group button:nth-child(1) {
  border-right: 0;
}

.btn-group.outline-group button:nth-child(2) {
  border-left: 0;
}

.l-h-23 {
  line-height: 23px;
}

.left-side-footer-save .footer-div-save {
  margin-left: -24px;
}

/* .left-right-select-input .select-inp .select-row-revese .form-control:focus-within {

  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -1px;
  box-shadow: none !important;
}

.left-right-select-input .select-inp .select-row-revese .MuiInputBase-fullWidth:focus {

  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -1px;
  box-shadow: none !important;
} */
.left-right-select-input.contact-code-inp .select-inp .select-row-revese .MuiInputBase-fullWidth {
  height: 34.125px;
}

.wrapper-or {
  position: relative;
  height: 120px;
  text-align: center;
}

.wrapper-or:after {
  content: " ";
  position: absolute;
  left: 50%;
  height: 100%;
  border-left: 1px solid #d3d4d6;
}

.wrapper-or .text {
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 5px;
  background-color: white;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.padding-10-16 {
  padding: 6px 16px;
  padding-right: 5px;
}

.gen-icn-angle {
  background: #d2deff00;
  width: 30px;
  height: 30px;
  right: 5px;
  border-radius: 0;
  top: 5px;
  line-height: 30px;
  text-align: center;
  border-left: 1px solid #d3d4d6;
}

.generation-buttons button {
  /* border-radius: 25px; */
  border-radius: 8px;
}

.cross-back {
  position: absolute;
  right: 0;
  top: 0;
}

.audit-icon-rounded {
  width: 50px;
  height: 50px;
  background: #D2DEFF;
  border-radius: 50px;
  line-height: 50px;
  text-align: center;
}

.card-footer-e8 {
  border-color: #e8eaeb;
}

.outline-down-caret-rem::after {
  display: none;
}

.dropdown-menu {
  border: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-menu li .dropdown-item {
  font-size: 14px;
}

.rem-caret-down::after {
  border: 0;
}

.gb-inp-250 input {
  width: 250px;
  height: 35px;
}

/* .outline-down-caret-rem {
  line-height: 21px;
} */

.audit-category-buttons button {
  padding: 6px 12px;
  margin-right: 12px;
}

.audit-category-buttons span {
  background: #F3F4F6;
  padding: 4px 8px;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 16px;
}


.global-loader {
  background: #ffffffb5;
  /* height: 100vh; */
  position: absolute;
  width: 100%;
  z-index: 1;
  padding-top: 25%;
}

.group-angle-icn {
  position: absolute;
  top: 15px;
  right: 5px;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -2px;
  border-radius: 3px;
  box-shadow: none;
}

.roles-accordion .p-accordion-header .p-accordion-header-link {
  padding: 16px;
  font-size: 14px;
  font-weight: 900;
  color: #23252d !important;
  background: #F7F9FC !important;
  border-color: #D3D4D6 !important;
}

.roles-accordion .p-accordion-toggle-icon.pi-chevron-down:before {
  font-family: uicons-bold-rounded !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f30b";
}

.roles-accordion .p-accordion-toggle-icon.pi-chevron-right:before {
  font-family: uicons-bold-rounded !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f30e";
}

.roles-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 4px !important;
}

.roles-accordion .p-accordion-content {
  padding: 0;
}

.roles-accordion .finsights-data-table-tree .p-treetable-thead>tr>th:first-child {
  padding-left: 36px;
}

.roles-accordion .finsights-data-table-tree .p-treetable-tbody>tr>td:first-child {
  padding-left: 32px;
}

.roles-accordion .finsights-data-table-tree.no-toggle-icn .p-treetable-tbody>tr>td:first-child {
  padding-left: 36px;
}

.roles-accordion .finsights-data-table-tree .p-treetable-tbody tr.direct-parent-toggle td .p-treetable-toggler {
  display: none;
}

.roles-accordion .finsights-data-table-tree .p-treetable-tbody>tr.direct-parent-toggle>td:first-child {
  padding-left: 36px;
}

/* .roles-accordion .finsights-data-table-tree tr.text-color-3 td {
  color: #6F7380;
} */
.roles-accordion .p-accordion-tab {
  margin-bottom: 0;
}

.roles-accordion .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}

.accordion-series .p-accordion .p-accordion-tab:nth-child(1) .p-accordion-header .p-accordion-header-link {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.accordion-series .p-accordion .p-accordion-tab:last-child .p-accordion-header .p-accordion-header-link {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.roles-accordion .p-accordion-header .p-accordion-header-link {
  margin-top: -1px;
}

.roles-accordion .p-accordion-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.group-32-sel .select-row-revese .MuiInputBase-fullWidth {
  height: 32px;
}

.add-det-size-scroll {
  max-height: calc(100vh - 333px);
  overflow: auto;
}

.view-scroll-bar-updt .add-det-size-scroll {
  max-height: calc(100vh - 260px);
  overflow: auto;
}

.w-260 {
  width: 240px;
}

.lock-icon-bg {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: #EBF0FF;
  line-height: 65px;
}

.ptb-40-20 {
  padding: 40px 24px;
}

.table-footer-radius-rem tfoot tr td:first-child {
  border-bottom-left-radius: 0px !important;

}

.table-footer-radius-rem tfoot tr td:last-child {
  border-bottom-right-radius: 0px !important;

}


.hr-d-color-tp {
  border-top: 1px solid #d3d4d6a6;
  opacity: 1;
}

.bg-warning-figma span {
  color: #C27803;
}

.text-warn {
  color: #C27803;
}

.dt-cal-year .p-datepicker-year {
  padding: 2px 10px !important;
  border: 1px solid #d3d4d6;
  border-radius: 6px;
  color: #23252D;
}

.dt-cal-year .p-datepicker-year:focus-visible {
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -2px;
  border-radius: 6px;
  box-shadow: none;
}

.padding-4-12px {
  padding: 4px 12px;
}

.all-high-btn-light {
  background-color: #f0525238;
}

.all-medium-btn-light {
  background-color: #C2780338;
}

.all-low-btn-light {
  background-color: #0E9F6E38;
}

.all-comments-btn-light {
  background-color: #E9EBF8;
}

.home-buttons-span .pill-span,
.shortcut-span {
  background: #F3F4F6;
  padding: 4px 8px;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  font-size: 12px;
}

.shortcut-span,
.home-buttons-span .pill-span {
  display: inline-block;
  line-height: 1;
  margin-top: -10px;
}

.shortcut-span-double {
  background: #F3F4F6;
  padding: 4px 12px;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  font-size: 12px;
}


.shortcut-span-double.active {
  border: 2px solid #305ff0 !important;
}

.shortcut-span-double {
  display: inline-block;
  line-height: 1;
  margin-top: -10px;
}

.mse-6 {
  margin-left: 6px;
  margin-right: 6px;
}


.p-tooltip {
  line-height: 0px;
}

.finsights-data-table.no-pagination-lines tbody tr:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.mention-box-full-width .p-mention {
  width: 100%;
}

.mention-box-full-width .p-mention .p-inputtextarea {
  width: 100%;
}

.p-mention-panel .p-mention-items {
  padding: 10px;
}

.mention-panel-dp {
  max-width: 310px;
  min-width: 310px !important;
}

.mention-panel-dp img {
  border-radius: 50%;
  margin-right: 5px;
}

.p-mention-panel .p-mention-items .p-mention-item.p-highlight {
  background: #EBF0FF;
  color: #305FF0;
}

.p-mention-panel .p-mention-items .p-mention-item {
  border-radius: 4px;
  font-size: 14px;
}

.p-mention-panel .p-mention-items .p-mention-item:hover {
  background: #F7F8FA;
}

.frozen-dt-table th.p-frozen-column:before,
.frozen-dt-table th.p-frozen-column:after {
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  width: 15px;
}

.frozen-dt-table th.p-frozen-column:before {
  box-shadow: 5px 0px 15px -15px inset;
  left: -15px;
}

.frozen-dt-table th.p-frozen-column:after {
  box-shadow: 5px 0px 15px -15px inset;
  right: -15px;
}

.frozen-dt-table td.p-frozen-column:before,
.frozen-dt-table td.p-frozen-column:after {
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  width: 15px;
}


.frozen-dt-table .p-datatable-tbody tr .p-frozen-column:before {
  box-shadow: 5px 0px 15px -15px inset;
  left: -15px;
}

.frozen-dt-table .p-datatable-tbody tr .p-frozen-column:after {
  box-shadow: 5px 0px 15px -15px inset;
  right: -15px;
}

.frozen-dt-table .p-frozen-column {
  background: #f9fafb;
}

.compare-table-design .p-datatable-tbody tr td:nth-child(2),
.compare-table-design .p-datatable-tbody tr td:nth-child(3),
.compare-table-design .p-datatable-tbody tr td:nth-child(4) {
  background: #f9fafb;
}

.two-compare-table-design .p-datatable-tbody tr td:nth-child(2),
.two-compare-table-design .p-datatable-tbody tr td:nth-child(3) {
  background: #f9fafb;
}

.one-table-design .p-datatable-tbody tr td:nth-child(2) {
  background: #f9fafb;
}

.four-table-design .p-datatable-tbody tr td:nth-child(2),
.four-table-design .p-datatable-tbody tr td:nth-child(3),
.four-table-design .p-datatable-tbody tr td:nth-child(4),
.four-table-design .p-datatable-tbody tr td:nth-child(5) {
  background: #f9fafb;
}

.five-table-design .p-datatable-tbody tr td:nth-child(2),
.five-table-design .p-datatable-tbody tr td:nth-child(3),
.five-table-design .p-datatable-tbody tr td:nth-child(4),
.five-table-design .p-datatable-tbody tr td:nth-child(5),
.five-table-design .p-datatable-tbody tr td:nth-child(6) {
  background: #f9fafb;
}

.six-table-design .p-datatable-tbody tr td:nth-child(2),
.six-table-design .p-datatable-tbody tr td:nth-child(3),
.six-table-design .p-datatable-tbody tr td:nth-child(4),
.six-table-design .p-datatable-tbody tr td:nth-child(5),
.six-table-design .p-datatable-tbody tr td:nth-child(6),
.six-table-design .p-datatable-tbody tr td:nth-child(7) {

  background: #f9fafb;
}

.finsights-data-table-tree .p-treetable-tbody tr.parent-item td:first-child {
  font-weight: 600;
}

.finsights-data-table-tree .p-treetable-tbody tr.children-item td:first-child {
  color: #6f7380;
}

.tree-report-highlight .p-treetable .p-treetable-tbody>tr:focus {
  background: #ebf0ff;
  background-color: #ebf0ff !important;
  outline-style: solid;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
  outline-offset: -2px;
  border-radius: 0px;
  box-shadow: 5px 0px #305FF0 inset;
}

.plus-btn-shortcut .short {
  background: #F3F4F6;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
}

.side-module-btns button,
.side-sub-module-btns button {
  padding: 8px 12px;
}

.side-module-btns button:focus-visible,
.side-module-btns button:focus,
.side-module-btns button:active {
  background: #F7F8FA !important;
  box-shadow: none;
  color: #305FF0 !important;
  border-color: transparent !important;
}

.side-module-btns button:hover {
  background: #F7F8FA;
  border-color: transparent !important;

}

.plus-btn-shortcut .short-angle {
  margin-left: 12px;
}

.left-side-menus .top-header-angle-span {
  display: none;
}

.side-module-btns button:hover .top-header-angle-span {
  display: block !important;
}

.side-module-btns button:focus .top-header-angle-span {
  display: block !important;
}

.left-side-menus .top-header-angle-span i {
  position: absolute;
  right: 5px;
  top: 15px;
}

.disabled-menu .plus-btn-shortcut .short {
  opacity: 0.4;
}

.side-sub-module-btns button.btn.btn-link:focus-visible {
  background: #F7F8FA;
}

.left-side-menus button.btn-light .top-header-angle-span {
  display: block !important;
}

.gstin-custom-select .select-value {
  padding-left: 0;
}

.card-hover-ins .card:hover {
  box-shadow: 0 0 0 2px #3060f08e !important;
  outline: none;
}

.p-10-16-px {
  padding: 10px 16px;
}

.bottom-color-th {
  border-bottom: 1px solid #d3d4d6;
}

.step-circle .number-circle {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #E2E3E4;
  border-radius: 50%;
  /* outline-style: solid;
  outline-width: 3px;
  outline-color: #ffffff;
  outline-offset: -5px; */
}

.completed .step-circle .number-circle {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #0E9F6E;
  border-radius: 50%;

}

.stepper-btns-status {
  position: relative;

}

.stepper-btns-status li.active button .step-circle .number-circle {
  background: #305FF0;
  outline-style: solid;
  outline-width: 3px;
  outline-color: #ffffff;
  outline-offset: -5px;
  color: #fff;
}

.m-r-8 {
  margin-right: 8px;
}

.alert-on-top-content-missing {

  position: absolute;
  top: 80px;
  z-index: 2;
  width: fit-content;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;

}

/* stepper */



.c-stepper {
  display: flex;
}

.c-stepper__item {
  display: flex;
  align-items: center;
}

.c-stepper__item button {
  padding: 0px 12px;

}

.c-stepper__item:first-child button {
  padding-left: 0;

}

/* .c-stepper__item:before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #E2E3E4;
  border-radius: 50%;
} */

.c-stepper__item:not(:last-child) {
  flex: 1;
}

.c-stepper__item:not(:last-child):after {
  content: "";
  position: relative;
  z-index: 1;
  height: 3px;
  background-color: #D3D4D6;
  flex: 1;
  margin-right: 0.5rem;
}

.c-stepper__item.completed:not(:last-child):after {
  background-color: #0E9F6E;
}

.c-stepper__item.active:not(:last-child):after {
  background-color: #305FF0;
}

.c-stepper__title {
  font-weight: bold;
  font-size: clamp(1rem, 4vw, 1.25rem);
}

.long-and-truncated p {
  max-width: 270px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.td-th-first-hight tbody tr td:first-child {
  background-color: #F7F9FC !important;
}

.table-having-border thead tr th {
  border-right: 1px solid #d3d4d6;
}

.table-having-border thead tr th:last-child {
  border-right: 0;
}

.table-having-border tbody tr td {
  border-right: 1px solid #d3d4d6;
}

.table-having-border tbody tr td:last-child {
  border-right: 0;
}

.sign-dp-list ul li {
  border-bottom: 0;
  border-radius: 8px !important;
  margin-bottom: 8px;
}

.sign-dp-list {
  max-height: 250px;
  overflow: auto;
}

.sign-dp-list ul li.active {
  background-color: #ebf0ff;
}

.sign-dp-list ul li:hover {
  background-color: #ebf0ff;
}

.sign-dp-list ul li.active p:first-child {
  color: #305FF0;
}

.sign-dp-list ul li p:first-child {
  color: #23252D;
}

.gstr-3b-filed-dp .select-value {
  border: 1px solid #d3d4d6;
  border-radius: 6px;
  padding-left: 12px;
  padding-right: 12px;
}

.gstr-3b-filed-dp .select-value {
  min-width: 150px
}

.gstr-3b-filed-dp .select-value .d-flex.align-items-center {
  justify-content: space-between;
}

.gstr-3b-filed-dp .select-value span.text-primary {
  color: #23252D !important;
}

.gstr-3b-filed-dp .select-value i.text-primary {
  color: #23252D !important;
}

.finsights-data-table-tree tr.no-parent-item-record td .p-treetable-toggler {
  display: none;
}

/* .two-compare-table-design .p-treetable-tbody tr td:nth-child(1),
.two-compare-table-design .p-treetable-tbody tr td:nth-child(1) {
  background: #f9fafb;
} */
.header-company-elipsis {
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.finsights-data-table-tree.tree-col-rounded.two-compare-table-design .p-treetable-thead tr th:last-child {
  border-top-right-radius: 0;
}

.finsights-data-table-tree.tree-col-rounded.two-compare-table-design .p-treetable-thead tr th:first-child {
  border-top-left-radius: 0;
}

.finsights-data-table-tree.tree-col-rounded.two-compare-table-design .p-treetable-scrollable-view .p-treetable-thead tr th:last-child {
  border-top-right-radius: 8px;
}

.finsights-data-table-tree.tree-col-rounded.two-compare-table-design .p-treetable-scrollable-header-table .p-treetable-thead tr th:nth-child(2) {
  border-top-right-radius: 0;

}

.frozen-dt-table .p-datatable-tbody tr .p-frozen-column:after {
  box-shadow: 5px 0px 15px -15px inset;
  right: -15px;
}

.vch-num-150-inp {
  width: 150px;
}

.mask-dt-120-inp {
  width: 120px;
}

.key-shortcut-info {
  position: absolute;
  right: 26px;
  top: 15px;
}

.collapse-content-sidebar .key-shortcut-info {
  display: none;
}

.collapse-content-sidebar .collapse-add-middle {
  justify-content: center !important;
}

.right-modal-content-dlg.no-footer-header-inp.keyshortcut-dlg {
  margin-left: 0px;
  margin-right: 0 !important;
}

.right-modal-content-dlg.keyshortcut-dlg .p-dialog-header {
  border-top-left-radius: 16px;
  border-bottom: 0;
}

.no-footer-header-inp.keyshortcut-dlg .p-dialog-content {
  height: 300px;
}

.no-footer-header-inp.keyshortcut-dlg.sales-db-cr-pr .p-dialog-content {
  height: 350px;
}

.no-footer-header-inp.keyshortcut-dlg.keyshort-top-0 .p-dialog-content {
  padding-top: 0;
}

.no-footer-header-inp.keyshortcut-dlg {
  border-top-left-radius: 16px;
  box-shadow: 0px -4px 30px 14px rgba(0, 0, 0, 0.08) !important;
}

.key-short-dispaly {
  background: #F3F4F6;
  padding: 2px 6px;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 16px;
}

.org-cards-layout .card {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
}

.org-cards-layout .card:hover {
  box-shadow: 0 0 0 2px #3060f08e !important;
  outline: none;
}

.org-cards-layout .card.active {
  background: #F7F8FA;
}

.p-16-20 {
  padding: 16px 20px;
}

.org-list-img-viw img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.view-btns-hide {
  opacity: 0;
}

.org-cards-layout .card.active .view-btns-hide {
  opacity: 1;
}

.org-card-body-cards-list {
  height: calc(100vh - 171px);
  overflow-y: auto;
  overflow-x: hidden;
}

.new-span {
  background: #F3893D;
  font-size: 12px;
  border-radius: 25px;
  padding-left: 4px;
  padding-right: 4px;
  position: absolute;
  right: 6px;
  top: 6px;
  color: #fff;
  line-height: 17px;
}

.time-span {
  padding: 4px 8px;
  background: #f7f8fa;
  border: 1px solid #d3d4d6;
  border-radius: 25px;
  position: absolute;
  top: -1px;
  line-height: 1;
  margin-left: 5px;
}

.base-currency-bg {
  padding: 8px 16px;
  border-radius: 8px;
  background: #f7f8fa;
}


.time-span-rel {
  padding: 4px 8px;
  background: #f7f8fa;
  border: 1px solid #d3d4d6;
  border-radius: 25px;
  line-height: 1;
  margin-left: 5px;
}

.new-template-card {
  border: 1px dashed #305FF0;
}

.h-332px-card {
  height: 332px;
}

.border-f7f8 {
  border: 1px solid #F7F8FA;
}

.o-hidden {
  overflow: hidden;
}

.p-and-l-tree .p-treetable-scrollable-view:first-child tr th:first-child {
  border-top-right-radius: 0 !important;
}

.p-and-l-tree .p-treetable-scrollable-view:nth-child(2) tr th:last-child {
  border-top-right-radius: 8px !important;
}

.bal-sheet-hor-table .all-theads-none .row:first-child .col-6:first-child .p-treetable-tbody tr:first-child {
  border-top: 0 !important;
}

.bal-sheet-hor-table .all-theads-none .row:first-child .col-6:nth-child(2) .p-treetable-tbody tr:first-child {
  border-top: 0 !important;
}

.negative-sign {
  background: #FDF2F2;
  border: 1px solid #F05252;
  border-radius: 2px;
  padding: 2px 4px;
  color: #F05252;
}

.positive-sign {
  background: #F3FAF7;
  border: 1px solid #0E9F6E;
  border-radius: 2px;
  padding: 2px 4px;
  color: #0E9F6E;
}

.negative-sign i {
  transform: rotate(45deg);
  display: inline-block;
  margin-right: 5px;
}

.positive-sign i {
  transform: rotate(-45deg);
  display: inline-block;
  margin-left: 5px;
}

.p-dialog.no-footer-header-inp.dlg-dashboard .p-dialog-header {
  padding-bottom: 24px !important;
}

.dashboard-footer-radius {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.dashboard-sheets li:first-child {
  border-bottom-left-radius: 15px !important;
}

.dashboard-sheets li:first-child button {
  border-bottom-left-radius: 15px !important;
}

.add-report-float {
  position: absolute;
  right: 24px;
  top: 14px;
}

.two-level-dlg .p-dialog-header .print-float-btn {
  top: 17px;
}

.first-level-dlg .p-dialog-header .print-float-btn {
  top: 22px;
}

.right-modal-content-dlg.two-level-dlg {
  margin-top: -48px;
}

/* .two-level-dlg .p-dialog-header {
  padding-top: 21.5px;
} */

.ag-rich-select-list {
  height: auto !important;
  max-height: 250px !important;
  padding-bottom: 10px;
  padding-top: 10px;
  overflow: auto;
}

.ag-cell div i.fi-ss-angle-small-down {
  margin-top: 4px;
}

.ag-rich-select-row-selected {
  background-color: #EBF0FF !important;
}

.ag-rich-select-row-selected:hover {
  background-color: #F7F8FA !important;
}

.ag-rich-select-row {
  display: initial !important;
}

.ledgers-th-left .p-datatable-thead tr th:nth-child(4) {
  padding-left: 42px;
}

.groups-th-left .p-datatable-thead tr th:nth-child(3) {
  padding-left: 42px;
}

/* .dlg-header-equal {
  margin-top: -45px !important;
} */

.p-60-72 {
  padding: 60px 72px;
}

.p-dialog-right .p-dialog {
  margin: initial;
}

.p-dialog .p-dialog-title button.btn-link.text-color-1 {
  line-height: 0;
}

.p-dialog .p-dialog-title button.btn-link.text-color-1 i.fi-bs-angle-left {
  line-height: 1;
  vertical-align: middle;
}

.b-r-16 {
  border-radius: 16px;
}

.login-sdw {
  box-shadow: 10px 0px 40px 41px rgba(197, 211, 255, 0.38);
}

.login-inp-45 {
  height: 45px;
}


.login-pg-inp-form .form-control:focus-within,
.login-pg-inp-form .form-control:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
  border-color: transparent;
}

.password-icon-ps i {
  position: absolute;
  right: 5px;
  top: 7px;
}

.password-icon-ps-reg .eye-btn {
  position: absolute;
  right: 5px;
  top: 7px;
}

.p-32 {
  padding: 32px 40px;
}

.img-shape {
  position: absolute;
  width: 45%;
  height: 660px;
  left: 28%;
  top: -23px;
}

.signup-img-shape {
  position: absolute;
  width: 44%;
  height: 716px;
  left: 29%;
  top: -17px;
}

.otp-img-shape {
  position: absolute;
  width: 40%;
  height: 640px;
  left: 31%;
  top: -9px;
}

.forgot-password-shape {
  position: absolute;
  width: 45%;
  height: 660px;
  left: 28%;
  top: -110px;
}

.login-c-app-body {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(0px + 100vh);
  overflow: auto;
}

.visual-btns button:hover {
  border-color: #d3d4d6;
  color: #23252D;
  background: #F7F8FA;
}

.visual-btns button.active {
  background: #EBF0FF;
}

.change-email-elipsis {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.p-12-16 {
  padding: 8px 16px;
}

.pb-2-imp {
  padding-bottom: 1px !important;
}

.acc-hover-btns-td-focus:focus,
.acc-hover-btns-td-focus:focus {
  /* box-shadow: inset 0 0 0 2px #3060f08e !important; */
  /* background-color: #EBF0FF !important; */
  outline-style: solid;
  outline-width: 3px;
  outline-color: #3060f08e;
  outline-offset: -2px;
  /* box-shadow: 0 0 0 2px #3060f08e !important;
  outline: 0; */
  border-radius: 3px;

}

.no-acc-table-rounded .items-new-table tr th:first-child {
  border-top-left-radius: 0 !important;
}

.no-acc-table-rounded .items-new-table tr th:last-child {
  border-top-right-radius: 0 !important;
}

.b-b-l-r-8-radius-imp {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.gstin-custom-ps {
  position: absolute;
  right: 30px;
  top: 16px;
}

.four-inp-cal .p-calendar .p-inputtext {
  width: initial;
}

.four-inp-cal .cal-with-icn input {
  max-width: 125px;
}

.right-corner-sel .custom-select .dropdown {
  right: 0 !important;
  left: initial !important;
}

.settings-on-table {
  position: absolute;
  right: -5px;
  bottom: 3px;
  background: #fff;
  border-radius: 50%;
}

.shortcut-show-elipsis {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.p-only-4 {
  padding: 4px;
}

.compare-freeze-col th:first-child,
.compare-freeze-col td:first-child {
  position: sticky;
  left: 0px;
  z-index: 1;

}

.compare-freeze-col tbody tr td:first-child,
.compare-freeze-col thead tr th:first-child {
  box-shadow: inset -6px 0px 3px -6px rgb(0 0 0 / 8%);
}

.finsights-data-table-tree.tree-table-p-l-th .p-treetable-tbody>tr>td.p-treetable-emptymessage {
  border-bottom: 1px solid #d3d4d6 !important;
}

.all-theads-none .row:last-child .finsights-data-table-tree.tree-table-p-l-th .p-treetable-tbody>tr:last-child>td.p-treetable-emptymessage {
  border-bottom: 0px solid #d3d4d6 !important;
}

.grs-profit-bg-1 .grs-pr-text {
  font-weight: 600 !important;
}

.single-outline button {
  border-right: 1px solid #d3d4d6 !important;
}

.p-radiobutton .p-radiobutton-box.p-disabled {
  background: #A2B9FF;
  pointer-events: none;
}

.p-radiobutton .p-radiobutton-box.p-disabled .p-radiobutton-icon {
  background: #305ff0;
}

.ag-virtual-list-item .ag-rich-select-row {
  overflow: hidden;
  text-overflow: ellipsis;
}

.org-gst-address-alert {
  position: absolute;
  top: 40px;
  width: 100%;
  z-index: 1;
  left: 40%;
}

.w-35-percent {
  width: 35%;
}

.grs-profit-bg-1-nobg td {
  font-weight: 600 !important;
}

.finsights-data-table-tree .p-treetable-tbody tr.grs-profit-bg-1-nobg td {
  font-weight: 600 !important;
}

.finsights-data-table-tree .p-treetable-tbody tr.grs-profit-bg-1-nobg td:nth-child(2) {
  font-weight: 600 !important;
}

.default-gstin-show {
  padding: 4px 10px;
  background: #f7f8fa;
  border: 1px solid #305FF0;
  border-radius: 15px;
  font-size: 12px;
}

.default-gstin-show-label {
  padding: 8px 10px;
  background: #f7f8fa;
  border: 1px solid #305FF0;
  border-radius: 8px;
  font-size: 12px;
}

.org-settings-gst-card-body {
  height: calc(100vh - 248px);
}

.org-settings-gst-card-body-no-footer {
  height: calc(100vh - 180px);
}

.m-neg-24 {
  margin-left: -24px;
}

.p-8-24.no-filters-th .p-datatable .p-datatable-thead tr th {
  padding: 10px 24px;
  text-transform: capitalize;
}

.org-card-dt-table .p-datatable .p-datatable-thead {
  display: none;
}

.org-card-dt-table .p-datatable .p-datatable-tbody tr td {
  border: 0;
  padding: 2px;
  border-radius: 8px;

}

.org-card-dt-table .p-datatable .p-datatable-tbody tr {
  margin-bottom: 10px;
}

.org-card-dt-table .p-datatable .p-datatable-tbody tr td .card {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05) !important;
}

.org-card-dt-table .p-datatable .p-datatable-tbody tr td .card.margin-bottom-8 {
  margin-bottom: 0 !important;
}

.org-card-dt-table .p-datatable .p-datatable-tbody tr.p-highlight {
  /* box-shadow: 0 0 0 2px #3060f08e !important; */
  outline: none;
  box-shadow: none;
  background: transparent !important;
}

.org-card-dt-table .p-datatable .p-datatable-tbody tr.p-highlight td .card {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.emptyrow-1 {
  background-color: #f6f6f6 !important;
}

/* .emptyrow-1::before{
  content: "⚠️";
  position: relative;
} */


.org-card-body-role-create {
  /* max-height: 34vw; */
  height: calc(100vh - 248px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-dt-table .p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:focus {
  outline: none;
}

.gstr1vs3b-table tbody tr td.bg-primary-50 {
  background-color: #EBF0FF !important;
}

.gstr1vs3b-table thead tr:first-child th {
  border-bottom: 1px solid #d3d4d6;
  border-right: 1px solid #d3d4d6;
}

.gstr1vs3b-table thead tr:first-child th:last-child {

  border-right: 0;
}

.gstr1vs3b-table thead tr:first-child th:first-child {
  border-right: 1px solid #d3d4d6;
}


.gstr1vs3b-table thead tr th.total-right-br {
  border-right: 1px solid #d3d4d6;
}

.gstr1vs3b-table tbody tr td:first-child {
  border-right: 1px solid #d3d4d6;
}

.gstr1vs3b-table tbody tr td:last-child {
  border-right: 0px solid #d3d4d6;
}

.gstr1vs3b-table tbody tr th:last-child {
  border-right: 0px solid #d3d4d6;
}

/* .gstr1vs3b-table th:first-child,
.gstr1vs3b-table td:first-child {
  position: sticky;
  left: 0px;
  z-index: 999;

}

.gstr1vs3b-table tbody tr td:first-child,
.gstr1vs3b-table thead tr th:first-child {
  box-shadow: inset -6px 0px 3px -6px rgb(0 0 0 / 8%);
}

.gstr1vs3b-table thead tr:first-child th:first-child {
  z-index: 9999;
} */

.section-first-cell tr td:first-child {
  border-right: 1px solid #d3d4d6;
}

/* .section-first-cell tr td:last-child {
  background-color: #EBF0FF;
} */

.balance-sheet-rpt .all-theads-none .col-6 tr.grs-profit-bg {
  border-top: 0 !important;
  border-bottom: 0 !important;
  background-color: #EBF0FF8c !important;
}

.balance-sheet-rpt .all-theads-none .col-6 tr.grs-profit-bg td span {
  color: #305FF0 !important;
}

.gstr1vs3b-tree .p-treetable .p-treetable-thead tr th {
  border-right: 1px solid #d3d4d6;
}

.gstr1vs3b-tree .p-treetable .p-treetable-thead tr th:last-child {
  border-right: 0 !important;
}

.gstr1vs3b-tree .p-treetable-scrollable-header-box {
  margin-right: 0 !important;
}

.inside-first-acc-table tr td:first-child,
.inside-first-acc-table tr td:nth-child(2) {
  border-right: 1px solid #d3d4d6;
}

.b-r-d-line-imp {
  border-right: 1px solid #d3d4d6 !important;
}

.inside-first-acc-table tr:first-child td,
.inside-total-tax-sub tr:first-child td {
  border-top: 0 !important;
}

.balance-sheet-rpt .bal-sheet-hor-table .p-treetable .p-treetable-tbody>tr.grs-profit-bg {
  background-color: #ebf0ff !important;
}

.balance-sheet-rpt .bal-sheet-hor-table .p-treetable .p-treetable-tbody>tr.grs-profit-bg td .grs-pr-text {
  color: #305FF0;
  font-weight: 500;
}

.totals-table-b-b-0 table tr:last-child td:last-child {
  border-bottom-right-radius: 0 !important;
}

.totals-table-b-b-0 table tr:last-child td:first-child {
  border-bottom-left-radius: 0 !important;
}

.max-150-inp {
  max-width: 150px;
  padding-left: 14px;
}

.p-8px-9px {
  padding-top: 8px;
  padding-bottom: 9px;
}

.sources-funds-tr td button.p-treetable-toggler {
  display: none;
}

.sources-funds-tr td span.grs-pr-text {
  font-weight: 600;
}

.columnar-report {
  position: absolute;
  margin-left: -24px;
  width: 100%;
  bottom: 0;
}

.inside-span-element-elipsis {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  line-height: 1;
  margin-bottom: -4px;
}

.mb-5-rem {
  margin-bottom: 5rem;
}

.gst-1vs-3b-freeze tr:first-child th:first-child,
.gst-1vs-3b-freeze td:first-child {
  position: sticky;
  left: 0px;
  z-index: 1;

}

.gst-1vs-3b-freeze tr td {
  vertical-align: middle;
}

.gst-1vs-3b-freeze tbody tr td:first-child,
.gst-1vs-3b-freeze thead tr:first-child th:first-child {
  box-shadow: inset -6px 0px 3px -6px rgb(0 0 0 / 8%);
}

.tree-frozen-th-cell .p-treetable-thead tr:first-child th:first-child {
  border-right: 1px solid #d3d4d6 !important;
}

.tree-frozen-th-cell .p-treetable-frozen-view .p-treetable-scrollable-body {
  border-right: 1px solid #d3d4d6 !important;
}

.first-freeze-td-border tbody tr:first-child td:first-child {
  border-top: 0 !important;
}

.cess-border-right {
  border-right: 1px solid #d3d4d6 !important;
}

th.cess-border-right:last-child {
  border-right: 0 !important;
}

td.cess-border-right:last-child {
  border-right: 0 !important;
}

.mt-64 {
  margin-top: 64px;
}

.f-32 {
  font-size: 32px;
}

.p-32-px {
  padding: 32px;
}

.p-12-16-btns {
  padding: 12px 16px;
}

.mt-84 {
  margin-top: 84px;
}

.neg-header-first {

  margin-top: -24%;
}

.neg-header-second {
  margin-top: -17%;
}

.neg-header-third {
  margin-top: -22%;
}

.org-sync-card-body {
  height: calc(100vh - -175px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-card-body-imp {
  /* max-height: 34vw; */
  height: calc(100vh - 36px);
  overflow-y: auto;
  overflow-x: hidden;
}

.black-filter {
  filter: grayscale(100%);
  opacity: 0.5;
}

.video-dlg-radius .p-dialog-content {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.login-congtainer-area {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 100vh;
}

.wrap-login100 {
  width: 490px;
  overflow: hidden;
}

.wrap-fp100 {
  width: 490px;
  overflow: hidden;
}

.login-vcard-body {
  height: 100vh;
  overflow-y: auto;
}



.login-ps-img {
  position: absolute;
  width: 550px;
}

.sign-up-ps-img {
  position: absolute;
  width: 600px;
}

.verify-ps-img {
  position: absolute;
  width: 550px;
}

.spinner-border {
  width: 20px;
  height: 20px;
}

.toolTip-width {
  font-size: 12px;
  /* Adjust the font size as needed */
  max-width: 300px;
  /* Set the maximum width */
  padding: 4px 8px;
  /* Adjust padding as needed */
  word-wrap: break-word;
  /* Allow breaking long words */
  white-space: normal;
  /* Allow text to wrap to next line */
  overflow-wrap: break-word;
  /* Handle long words properly */
}

.right-modal-content-dlg.no-footer-header-inp.calc-dlg {
  margin-right: 42px !important;
  margin-bottom: 100px;
  border-radius: 16px;
}

.no-footer-header-inp.keyshortcut-dlg.calc-dlg .p-dialog-content {
  height: 405px;
  border-bottom-left-radius: 16px !important;
}

.no-footer-header-inp.keyshortcut-dlg.calc-dlg .p-dialog-header {
  padding-bottom: 16px;
}

.history-scroll {
  max-height: 185px;
  min-height: 185px;
  overflow-y: auto;
  overflow-x: hidden;
}

.invalid-cal-input {
  border: 1px solid #f0525270;
  background-color: #f052521f !important;
}

.ag-row {
  border-bottom: 1px solid #d3d4d6 !important;
  color: #515561 !important;
}

.new-react-grid-table .ag-root-wrapper {
  border: 1px solid #d3d4d6 !important;
}

.new-react-grid-table .ag-header-cell,
.new-react-grid-table .ag-header-group-cell {
  background-color: #F7F8FA !important;
}

.new-react-grid-table .height-min-input {
  height: 40px;
  margin-top: 5px;
  border-radius: 8px;
  line-height: 40px !important;
}

.new-react-grid-table .ag-header,
.new-react-grid-table .ag-advanced-filter-header {
  border-bottom: 1px solid #d3d4d6 !important;
}

.new-react-grid-table .ag-header-cell .ag-header-cell-text {
  font-weight: 500;
  font-size: 12px;
  color: #515561;
}

.ag-theme-quartz * {
  font-family: 'Avenir', sans-serif;
}

.new-react-grid-table .ag-header-cell-resize::after {
  background-color: #d3d4d6 !important;
  width: 1px !important;
}

.new-react-grid-table .ag-cell-inline-editing {
  border-color: #305FF0 !important;
  border-radius: 8px;
  box-shadow: none !important;
}

.new-react-grid-table .ag-theme-quartz .ag-ltr input[class^=ag-][type=text] {
  border-radius: 8px !important;
  border-color: transparent !important;
}


.new-react-grid-table .ag-picker-field-wrapper {
  border-color: transparent !important;
}

.ag-picker-field-wrapper:focus {
  box-shadow: none !important;
}

.ag-picker-field-wrapper.ag-picker-has-focus,
.ag-picker-field-wrapper:focus-within {
  box-shadow: none !important;
}

.ag-ltr .ag-rich-select-row {
  padding-left: 12px !important;
  padding-top: 5px !important;
}

.ag-rtl .ag-rich-select-row {
  padding-right: 12px !important;
}

.ag-rich-select-row-selected {
  background-color: #F7F8FA !important;
}

.ag-rich-select-row:hover,
.ag-rich-select-row-highlighted {
  background-color: #EBF0FF !important;
}

.ag-autocomplete-virtual-list-item:hover {
  background-color: #EBF0FF !important;
}

.new-react-grid-table .ag-ltr .ag-cell {
  line-height: 50px;
}

.gst-1vs-3b-freeze tr.hover-btns-td-focus:focus td {
  background-color: #EBF0FF !important;

}

.gst-1vs-3b-freeze tr:focus {
  outline-offset: -0px;
  outline-width: 0;
  background-color: #EBF0FF !important;
  box-shadow: none !important;
}

.section-first-cell.gst-1vs-3b-freeze tr:focus td:last-child {
  background-color: #ebf0ff !important;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1rem;
}

.keyshor-btn {
  position: absolute;
  top: -5px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px 0px rgba(37, 72, 181, 0.10);
  background: #EBF0FF;
}

.float-video-btn {
  position: absolute;
  right: 5px;
}

.rec-view-top-btns {
  position: absolute;
  right: 84px;
  top: 15px;
}

.org-text-names {

  width: 32px;
  line-height: 32px;
  border-radius: 50%;
  text-align: center;
  color: #0D0D0D;
  border: 1px solid #d3d4d6;

}

.org-text-names.first-color {
  background: #A855F7;
}

.org-text-names.second-color {
  background: #FFA84A;
}

.org-text-names.third-color {
  background: #5D85FF;
}

.org-text-names.fourth-color {
  background: #0E9F6E;
}

.org-text-names.fifth-color {
  background: #CB3D3D;
}

.org-text-names.sixth-color {
  background: #1D388C;
}

.current-org-abs {
  position: absolute;
  bottom: 16px;
  background: #F3FAF7;
  border: 1px solid #0E9F6E;
  border-radius: 18px;
  right: 20px;
  color: #0E9F6E;
  font-size: 12px;
  padding: 4px 10px;
}

.trash-shadow {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.10);
}

.trash-del-abs {
  position: absolute;
  background: #fff;
  right: 50px;
  opacity: 0;
}

.org-card-dt-table .p-datatable .p-datatable-tbody tr.p-highlight td .card .trash-del-abs {
  opacity: 1;
}

.org-card-dt-table .p-datatable .p-datatable-tbody tr:hover td .card .trash-del-abs {
  opacity: 1;
}

.error-org-input {
  background-color: #FDF2F2 !important;
  border: 1px solid #f05252;
  color: #f05252;
}

.note-del-card {
  background-color: #FFF8E1;
}

.org-alert-on-top {
  position: absolute;
  z-index: 2;
  width: 100%;

}

.ledger-level-elipsis {
  max-width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.finsights-data-table.p-6-tbody .p-datatable-tbody tr td {
  padding: 2px 24px;
}

.expansion-table tr.p-datatable-row-expansion>td {
  padding: 0 !important;
}

.subtable .p-datatable-thead {
  display: none;
}

.subtable .p-datatable-tbody tr td:first-child {
  /* display: none; */
  visibility: hidden;
}

/* .expansion-table .p-datatable-thead tr th:first-child {
  display: none;
}

.expansion-table .p-datatable-tbody tr td:first-child {
  display: none;
} */

.expansion-table .p-datatable-tbody tr td button span.pi.pi-chevron-right:before {
  font-family: uicons-bold-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f13b";
}

.expansion-table .p-datatable-tbody tr td button span {
  font-size: 12px;
}

.percentage-div-input {
  border: 1px solid #d3d4d6;
  margin-left: -1px;
  background: #f7f8fa;
  height: 36px;
  line-height: 36px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

}

.right-side-miss-border .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select-inp.dashboard-dp-inputs .p-dropdown {
  height: 36px;
  background: #fff;
  border-radius: 8px;
  color: #23252d;
}

.select-inp.dashboard-dp-inputs .p-dropdown .p-inputtext {
  line-height: 12px;
}

.select-inp.dashboard-dp-inputs .p-dropdown .p-dropdown-trigger {
  height: 36px;
}

.right-dt-table-scrollar {
  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
}

.customise-section-scroll {
  height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
}

.dlg-remove-fetch-label label.v-hidden {
  display: none;
}


.gst-2a-vs-books .p-datatable-tbody tr td:first-child,
.gst-2a-vs-books .p-datatable-thead tr:first-child th:first-child {
  box-shadow: inset -6px 0px 3px -6px rgb(0 0 0 / 8%) !important;
}

.op-bal-row {
  background-color: #F3FAF7 !important;
}

.total-row {
  background-color: #F7F9FC !important;
}

.cl-bal-row {
  background-color: #EBF0FF !important;
}

.filters-applied-class {
  background: #FFE4B9;
  border-radius: 6px;
  padding: 2px 6px;
  color: #C27803;
  font-size: 12px;
}

.font-size-40 {
  font-size: 40px;

}

.b-r-48 {
  border-radius: 48px;
}

.p-56 {
  padding: 56px;
}

.price-shadow {
  box-shadow: 0px 0px 26.9px 3px rgba(132, 132, 132, 0.26);
}

.p-2-8-px {
  padding: 2px 8px;
}

.center-scale {
  transform: scale(1.1);
  z-index: 1;
}

.padding-bt-48 {
  padding-bottom: 48px;
}

.faq-padding {
  padding: 32px 40px 40px 40px;
}

.faq-bg {
  background: #F7F9FC;
}

.b-r-32 {
  border-radius: 32px;
}

.m-t-64 {
  margin-top: 64px;
}

.psr-80 {
  padding-left: 60px;
  padding-right: 60px;
}

.text-eb {
  color: #305ff078 !important;
}

.sketch-float {
  position: absolute;
  top: -52px;
  right: 30%;
}

.sketch-float span {
  position: absolute;
  width: max-content;
}

.section-active {
  background: #f1f4ff;
  padding: 20px;
  border-radius: 8px;
  padding-bottom: 0;
}

.gst-note {
  background: #F7F8FA;
  border-radius: 8px;
  padding: 12px;
  /* position: absolute;
  bottom: 10px; */
  width: -webkit-fill-available;
  margin-right: 32px;
}

.verify-otp-note {
  background: #F7F8FA;
  border-radius: 8px;
  padding: 12px;
  width: -webkit-fill-available;
}

.rel-content .p-dialog-content {
  position: relative;
}

.gst-otp-alert {

  position: absolute;
  top: 12px;
  z-index: 2;
  width: 300px;
  left: 238px;
  right: 0;

}

.mt-5rem {
  margin-top: 5rem;
}

.center-rounded-dlg .p-dialog-content {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.finsights-data-table-tree.p-16-tree .p-treetable-tbody>tr>td {
  padding: 16px !important;
}

.tree-head-bold tr.f-600-heading td {
  font-weight: 600;
}

.pills-badge span.white-badge {
  display: inline-flex;
}

.white-badge {
  background: #fff;
  border: 1px solid #d3d4d6;
  font-size: 14px;
  border-radius: 16px;
  padding: 4px 16px;
  margin-right: 12px;
  color: #6F7380;
  margin-bottom: 12px;
}

.ag-header-cell-resize::after {
  background-color: transparent !important;
}

.ag-pinned-left-header {
  border-right: 0px solid #d3d4d6 !important;
  box-shadow: inset -6px 0px 3px -6px rgb(0 0 0 / 8%);
}

.ag-paging-panel {
  color: #515561 !important;
}

.org-card-body-org-success {
  /* max-height: 34vw; */
  height: calc(100vh - 110px);
  overflow-y: auto;
  overflow-x: hidden;
}

.org-success-dlg .p-dialog-header {
  position: absolute;
  right: 0;
  z-index: 1;
  display: none;
}

.org-success-dlg .p-dialog-content {
  border-radius: 16px !important;
}

.skip-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.m-t-69 {
  margin-top: 69px;
}

.m-t-37 {
  margin-top: 37px;
}

.m-t-86 {
  margin-top: 86px;
}

.m-t-106 {
  margin-top: 106px;
}

.m-t-54 {
  margin-top: 54px;
}

.m-t-42 {
  margin-top: 42px;
}

.half-checked span.pi-check:before {
  content: "\e90f" !important;
}

.gstr-9-full-width-btns button {
  width: 100%;
}

.gstr-9-table-highlight tr td:first-child {
  background: #F7F8FA;
}

.gstr-9-table-highlight tr td:nth-child(2) {
  background: #F7F8FA;
}

.colspan-td-th-table thead tr th {
  border-right: 1px solid #d3d4d6;
}

.colspan-td-th-table thead tr th:last-child {
  border-right: 0;
}

.colspan-td-th-table tbody tr td {
  border-right: 1px solid #d3d4d6;
}

.colspan-td-th-table tbody tr td:last-child {
  border-right: 0;
}

.bottom-color-th {
  border-bottom: 1px solid #d3d4d6 !important;
}

.custom-inside-input .custom-ag-text-inp {
  margin-top: 0px;
}

.custom-inside-input .custom-ag-text-inp input {
  background-color: transparent;
  border-color: transparent;
}

.rates-card-style {
  background: #EBF0FF;
}

.gst-analysis-btn-group .btn:nth-child(2) {
  border-right: 0;
}

.gst-analysis-btn-group .btn:nth-child(3) {
  border-left: 0;
}

.amendment-disabled {
  color: #dadbdd;
  pointer-events: none;
}

.float-inputs-right {
  position: absolute;
  right: 24px;
  top: 15px;
}

.abs-sel {
  position: absolute;
  width: -webkit-fill-available;
  top: 8px;
  margin-right: 14px;
}

.reports-float-btn {
  position: absolute;
  top: 24px;
  right: 95px;
}

.org-card-body-template {
  height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;
}

.letter-heads-btns-active .btn:hover {
  background: #EBF0FF;
}

.preview-color-picker .p-colorpicker-preview {
  border-radius: 3px !important;
}

.ag-popup-editor .ag-rich-select-value {
  height: 34px !important;
  border: 1px solid #d3d4d6 !important;
  border-radius: 8px;
}

.ag-icon-filter::before {
  font-family: uicons-regular-rounded !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\f5a1" !important;
  font-size: 12px;
}

.ag-header-cell-comp-wrapper span.ag-icon.ag-icon-filter {
  opacity: 0;
}

.ag-header-cell-comp-wrapper:hover span.ag-icon.ag-icon-filter {
  opacity: 1;
}

.navbar-expand .navbar-nav .dropdown-menu {
  z-index: 9999;
}

.ag-layout-auto-height {
  min-height: 350px;
}

.tutorails-vertical-btns button.active {
  background: #EBF0FF;
  color: #305ff0;
}

.tutorails-vertical-btns button:hover {
  background: #EBF0FF;
}

.org-sync-align {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  height: calc(85vh - 90px)
}

.landing-cards .card {

  transition: all 0.2s ease;
  cursor: pointer;
}

.landing-cards .card:hover {

  transform: scale(1.01);
}

.landing-cards .card button.btn-outline-light {
  background: #F7F8FA !important;
}

.ag-picker-field-wrapper {
  border-color: #d3d4d6 !important;
}

.letter-overlay-panel {
  box-shadow: 4px 4px 21px 2px rgba(0, 0, 0, 0.07) !important;
  border-radius: 8px !important;
  top: 5px !important;
  left: 25% !important;
}

.letter-overlay-panel:after {
  border-top-color: transparent !important;
}

.letter-overlay-panel.p-overlaypanel-flipped:before {
  border-color: transparent !important;
}

.sync-card-header-float .card-header {

  position: absolute;
  width: 100%;
}

.sync-card-header-float .card-body {
  margin-top: 84px;
}

.step-color-bg {
  background: #EBF0FF;
  border-radius: 25px;
  padding: 8px 16px;
}

.related-articles {
  list-style: none;
  padding-left: 0;
}

.related-articles li {
  margin-bottom: 12px;
}

/* Style the list */
.guide-breadcrumb.breadcrumb {
  padding: 10px 0px;
  list-style: none;
  margin-bottom: 0;
}

/* Display list items side by side */
.guide-breadcrumb.breadcrumb li {
  display: inline;
  font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
.guide-breadcrumb.breadcrumb li+li:before {
  padding: 8px;
  color: #23252d;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
.guide-breadcrumb.breadcrumb li a {
  color: #305ff0;
  text-decoration: none;
}

/* Add a color on mouse-over */
.guide-breadcrumb.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

.collapse-content-sidebar .create-collapse-content-hidden .home-buttons-span {
  display: none;
}



.exchange-btn-width {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.items-new-table tbody.p-datatable-tbody tr:first-child td {
  border-top: 1px solid #d3d4d6;
}

.items-new-table tbody.p-datatable-tbody tr:last-child td {
  border-bottom: 0px solid #d3d4d6;
}

.left-column.col-4 .card-body .btn.btn-outline-secondary.border-c {
  display: none;
}

.left-column.col-4 .card-body .btn.btn-outline-secondary.table-vch-card {
  display: none;
}

.role-badge {
  background: #EBF0FF;
  border: 1px solid #305FF0;
  padding: 4px 16px;
  border-radius: 18px;
}


.my-profile-pic img {
  max-width: 100%;
  height: 56px;
  width: 56px;
  max-width: 56px;
  min-width: 56px;
  border: 0px solid #d3d4d6;
  border-radius: 50%;
  object-fit: cover;
}

.my-profile-pic i {
  display: block;
  max-width: 100% !important;
  height: auto !important;
  width: 56px !important;
  min-width: 56px;
  border: 0px solid #e8eaeb;
  border-radius: 50%;
  max-height: 29px;
  min-height: 55px;
  line-height: 58px;
  text-align: center;
  background: #e8eaeb;
}

.btn-file-profile {
  position: relative;
  overflow: hidden;
}

.btn-file-profile input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  width: 56px;
  height: 56px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block;
}

.avatar-image .profile-btn-abs {
  display: none;
}

.avatar-image:hover .profile-btn-abs {
  display: block !important;
  position: absolute;
  top: 0px;
}

.profile-btn-abs i {

  background: #00000045;
  color: #fff !important;
  z-index: 1;
}

.profile-btn-hover:hover i {
  background: #000;
  opacity: 0.5;
  color: #fff !important;
}

.profile-edit-section {
  display: none;
}

.contact-hover-card:hover .profile-edit-section {
  display: block;
}

.contact-hover-card:hover .profile-check-section {
  display: none;
}

.verify-rem-header .p-dialog-header {
  border: 0;
}

.verify-rem-header .p-dialog-content {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.eye-ps-btn {
  position: absolute;
  right: 5px;
  top: 0;
}

.plan-icons {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background: #EBF0FF;
  padding: 4px;
  display: block;
  text-align: center;
}

.profile-text-names {

  width: 56px;
  line-height: 56px;
  border-radius: 50%;
  text-align: center;
  color: #0D0D0D;
  border: 1px solid #d3d4d6;
  background: #A2B9FF;
}

.top-header-text-names {

  width: 36px;
  line-height: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  border: 1px solid #305FF0;
  background: #305FF0;
  text-transform: uppercase;
}

.top-header-text-names-lg {

  width: 50px;
  line-height: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  border: 1px solid #305FF0;
  background: #305FF0;
  text-transform: uppercase;
}

.td-inside-select-focus:focus-within,
.td-inside-select-focus:focus-visible,
.td-inside-select-focus:focus {
  outline-style: solid;
  outline-width: 2.5px;
  outline-color: #3060f08e;
  outline-offset: -1px;
  border-radius: 4px;

}

.user-profile-dlg-width {
  width: 350px !important;
}

.search-icon-right {
  position: absolute;
  right: 10px;
  color: #6f7380;
}

.dlg-fade-type {
  max-height: 100%;
}

.dlg-fade-type .p-dialog-content {
  height: 100vh;
}

.dlg-fade-type .p-dialog-header {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.dlg-fade-type .p-dialog-header {
  padding-top: 23px;
  padding-bottom: 23px;
}

.dlg-fade-type .p-dialog-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dlg-fade-type .p-dialog-footer {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.h-45px {
  height: 45px;
}

.p-mention {
  width: 100%;
}

.p-mention-input {
  border: 1px solid #d3d4d6;
  border-radius: 8px;
  width: 100%;
}


.custom-p-dropdown .p-dropdown {
  height: 45px;
  background: #fff;
  border-radius: 8px;
  color: #23252d;
  width: 100%;
}

.custom-p-dropdown .p-dropdown .p-inputtext {
  line-height: 26px;
  font-size: 14px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.custom-p-dropdown .p-dropdown .p-dropdown-trigger {
  height: 43px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.auto-com-inp .p-autocomplete-multiple-container {
  border: 1px solid #d3d4d6;
  border-radius: 8px;
  height: 45px;
  padding-left: 12px;
}

.MuiTiptap-RichTextField-outlined:hover {
  border-color: #d3d4d6 !important;
}
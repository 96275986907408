/* src/styles/chat.css */

/* Chat Icon Button Styling */
.chat-icon-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  /* Ensures the button stays above other elements */
}

.chat-icon-button .p-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.chat-icon-button .p-button:hover {
  background-color: #3b82f6;
  /* Change to your desired hover color */
  transform: scale(1.1);
}

.chat-icon-button .p-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 130, 246, 0.5);
}

/* Chat Body Styling */
.chat-body {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  background-color: #f7f8fa;
}

/* Chat Input Styling */
.chat-input {
  padding: 15px;
  border-top: 1px solid #ddd;
  background-color: white;
  /* display: flex; */
  align-items: center;
}

.chat-input .p-inputtext {
  flex: 1;
  margin-right: 10px;
}

/* Chat Input Styling */
.chat-input {
  padding: 15px;
  border-top: 1px solid #ddd;
  background-color: white;
  display: flex;
  align-items: center;
}

.chat-input .chat-textarea {
  flex: 1;
  resize: none;
  padding: 10px;
  border: 1px solid #d3d4d6;
  border-radius: 8px;
  font-size: 1rem;
  font-family: inherit;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: border-color 0.3s;
  overflow: hidden;
  max-height: 150px;
}

.chat-input .chat-textarea:focus {
  border-color: #3b82f6;
  outline: none;
}

.chat-input .p-button {
  margin-left: -1px;
  height: 43px;
  min-width: 65px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

}

.chat-input .p-button .pi-send {
  font-size: 1.2rem;
}

/* Chat.css */

/* Styling for the dialog title */
.chat-dialog-title {
  padding: 12px 16px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ddd;
}

.chat-dialog-title h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

/* Style for each chat message */
.chat-message {
  position: relative;
  padding: 8px 16px;
  margin: 10px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.chat-message .chat-timestamp {
  font-size: 0.85rem;
  color: #999;
  align-self: flex-end;
  margin-top: 4px;
}

/* Styling for user and bot messages */
.chat-message.user {
  background-color: #ebf0ff;
  align-self: flex-end;
}

.chat-message.bot {
  background-color: #f0f0f0;
  align-self: flex-start;
}

/* Improved timestamp formatting */
.chat-timestamp {
  font-size: 0.75rem;
  color: #888;
  margin-top: 5px;
  text-align: right;
  font-style: italic;
}

/* Styling for user queries (questions) */
.chat-message.question {
  background-color: #eaeaff;
  /* Subtle lavender background */
  align-self: flex-start;
  border-left: 4px solid #b3b3ff;
  /* Soft purple border for highlighting */
}

/* Styling for answers */
.chat-message.answer {
  background-color: #e0f7fa;
  /* Light blue background for answers */
  align-self: flex-end;
  border-right: 4px solid #009688;
  /* Highlighting answers */
}

/* Styling for chat timestamps */
.chat-timestamp {
  font-size: 0.75rem;
  color: #666;
  margin-top: 5px;
  text-align: right;
  font-style: italic;
}